<template>
    <div class="gradient-x-overlay-sm-primary position-relative overflow-hidden">
        <div v-show="!showResults" class="container content-space-t-3 content-space-t-lg-4 content-space-b-1">
            <div class="w-lg-65 text-center mx-lg-auto">
                <prismic-rich-text 
                    class="mb-5"
                    :field="slice.primary.header" 
                    :html-serializer="htmlSerializer" 
                />
            </div>
        </div>

        <div class="container content-space-t-1 content-space-b-2">
            <div v-show="!showResults" class="w-lg-65 text-center mx-lg-auto">
                <div class="card mb-5">
                    <CardHeader :current-step="currentStep" 
                                :total-steps="totalSteps" 
                                @updateStep="updateStep" 
                    />

                    <StepLoanType v-if="currentStep === 1" 
                                  :updated-amount="amount"
                                  :updated-loan-use="loanUse"
                                  @submitValues="updateValues" 
                    />
                    <StepEconomy v-else-if="currentStep === 2" 
                                 :updated-monthly-income="monthlyIncome"
                                 :updated-has-payment-remarks="hasPaymentRemarks"
                                 @submitValues="updateValues" 
                    />
                    <StepUserInfo v-else-if="currentStep === 3" 
                                  :updated-email="email"
                                  :updated-phone-number="phoneNumber"
                                  :disable-submit="disableSubmit" 
                                  :submit-error="submitError"
                                  @submitValues="updateValues" 
                                  @disable="disable" 
                                  @enable="enable"
                                  @clearSubmitError="clearSubmitError"
                    />
                </div>
            </div>
            <div v-show="showResults">
                <results 
                    :has-payment-remarks="hasPaymentRemarks"
                    :loan-amount="amount"
                />
            </div>
        </div>
        
        <figure class="position-absolute top-0 end-0 zi-n1 mt-n10 me-n10" style="width: 32rem;">
            <svg viewBox="0 0 451 902" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M451 820C247.2 820 82 654.8 82 451C82 247.2 247.2 82 451 82" stroke="white" stroke-width="164" stroke-miterlimit="10" />
            </svg>
        </figure>
        <figure class="position-absolute bottom-0 start-0 zi-n1 mb-n10 me-n10" style="width: 21rem;">
            <svg viewBox="0 0 451 902" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.125" d="M0 82C203.8 82 369 247.2 369 451C369 654.8 203.8 820 0 820" stroke="url(#paint1_linear)" stroke-width="164" stroke-miterlimit="10" />
                <defs>
                    <linearGradient id="paint1_linear" x1="323.205" y1="785.242" x2="-97.6164" y2="56.3589" gradientUnits="userSpaceOnUse">
                        <stop offset="0" stop-color="white" stop-opacity="0" />
                        <stop offset="1" stop-color="#377dff" />
                    </linearGradient>
                </defs>
            </svg>
        </figure>
    </div>
</template>

<script>
import CardHeader from '@/components/LoanWizard/CardHeader.vue';
import StepLoanType from '@/components/LoanWizard/StepLoanType.vue';
import StepEconomy from '@/components/LoanWizard/StepEconomy.vue';
import StepUserInfo from '@/components/LoanWizard/StepUserInfo.vue';
import Results from '@/components/LoanWizard/Results.vue';
export default {
    components: {
        CardHeader,
        StepLoanType,
        StepEconomy,
        StepUserInfo,
        Results
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            currentStep: 1,
            totalSteps: 3,
            amount: null,
            loanUse: null,
            monthlyIncome: null,
            hasPaymentRemarks: false,
            email: '',
            phoneNumber: '',
            disableSubmit: false,
            submitError: null,
            showResults: false

        };
    },
    computed: {
        payload() {
            return {
                guide_id: this.$store.state.settings.newsletter_form_broker_id,
                user: {
                    email: this.email,
                    phone_number: this.formatPhoneNumber(this.phoneNumber)
                },
                questionnaire: {
                    loan_reason: this.loanUse,
                    loan_amount: this.amount,
                    monthly_income: this.monthlyIncome,
                    payment_remarks: this.hasPaymentRemarks
                },
                tracking: this.$tracking.getTrackingObject()
            };
        }
    },
    beforeMount() {
        if (this.$route.query.amount && this.$route.query.loanUse) {
            this.amount = Number(this.$route.query.amount);
            this.loanUse = this.$route.query.loanUse;
            this.currentStep = 2;
        }
    },
    methods: {
        formatPhoneNumber(phoneNumber) {
            if (this.$isNorway()) {
                if (phoneNumber.startsWith('47')) {
                    return phoneNumber;
                }

                if (phoneNumber.startsWith('0047')) {
                    return phoneNumber.replace('0047', '47');
                }

                if (phoneNumber.startsWith('+47')) {
                    return phoneNumber.replace('+47', '47');
                }

                return `47${phoneNumber}`;
            }

            return phoneNumber;
        },
        htmlSerializer(type, element, text, children) {
            if (type === 'heading1') {
                return `<h1 class="display-4 mb-3">${children.join('')}</h1>`;
            }
            else if (type === 'paragraph') {
                return `<p class="lead">${children.join('')}</p>`;
            }
            return null;
        },

        updateStep(previousStep) {
            this.currentStep = previousStep;
        },
        disable() {
            this.disableSubmit = true;
        },
        enable() {
            this.disableSubmit = false;
        },
        clearSubmitError() {
            this.submitError = null;
        },
        updateValues(values) {
            Object
                .keys(values)
                .forEach(key => {
                    this[key] = values[key];
                });

            if (this.currentStep === this.totalSteps) {
                this.submit();
            }
            else {
                this.currentStep++;
            }
        },
        submit() {
            this.$axios.post('https://api.broker.compary.com/guides', this.payload)
                .catch((error) => {
                    this.submitError = error.response.data.error.join('\r\n');
                })
                .then(() => {
                    this.disableSubmit = false;
                    this.showResults = true;
                    this.$event.loanWizardSubmitted();
                    window.scrollTo({
                        top: 0,
                        behavior: 'instant'
                    });
                });
        },
    }
};
</script>