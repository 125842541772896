<template>
    <div class="row justify-content-center gy-5">
        <div :class="{'col-lg-4': twoColumns}">
            <div v-html="headerHtml" />
            <div class="rich-text" v-html="teaserHtml" />
        </div>
        <div :class="{'col-lg-8': twoColumns}">
            <div class="card p-4">
                <div class="w-100 w-lg-65 mx-auto">
                    <div class="h2 text-primary text-center">
                        {{ $translate('loan_wizard_loan_type', 'Vad vill du ha för lån?') }}
                    </div>
                    <div class="mb-5">
                        <label for="amount" class="form-label">{{ $translate('loan_wizard_choose_loan_amount', 'Hur mycket vill du låna?') }}</label>
                        <input id="amount" v-model="amount" type="number" class="form-control" placeholder="50000">
                        <Error v-if="errors.amount" :error-message="$translate('loan_wizard_loan_amount_info', 'Beloppet måste vara mellan 1 000 och 600 000 kr.')" />
                        <span class="small">{{ $translate('loan_wizard_enter_loan_amount', 'Ange ett belopp mellan 1 000 och 600 000 kr') }}</span>
                    </div>
                    
                    <div class="mb-5">
                        <label for="paymentremark" class="form-label">{{ $translate('loan_wizard_loan_question', 'Vad behöver du lånet till?') }}</label>
                        <select id="paymentremark" v-model="loanUse" class="form-select">
                            <option value="null" disabled selected>{{ $translate('loan_wizard_choose_an_alternative', 'Välj ett alternativ') }}</option>
                            <option value="debt_consolidation">{{ $translate('loan_wizard_debt_consolidation', 'Samla lån') }}</option>
                            <option value="shopping">{{ $translate('loan_wizard_shopping', 'Shopping') }}</option>
                            <option value="car_purchase">{{ $translate('loan_wizard_car_purchase', 'Bilköp') }}</option>
                            <option value="renovation">{{ $translate('loan_wizard_renovation', 'Renovering') }}</option>
                            <option value="travel">{{ $translate('loan_wizard_travel', 'Resa') }}</option>
                            <option value="other">{{ $translate('loan_wizard_other', 'Annat') }}</option>
                        </select>
                        <Error v-if="errors.loanUse" :error-message="$translate('loan_wizard_choose_an_alternative', 'Välj ett alternativ')" />
                    </div>

                    <a 
                        class="btn btn-sm btn-primary fw-bold d-block" 
                        role="button" 
                        @click="submitValues()"
                    >
                        {{ $translate('loan_wizard_intro_cta_text', 'Nästa steg') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Error from '@/components/LoanWizard/Error.vue';
export default {
    components: {
        Error
    },
    props: {
        header: {
            type: Array,
            required: false,
            default: null
        },
        teaser: {
            type: Array,
            required: false,
            default: null
        },
        twoColumns: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            amount: null,
            loanUse: null,
            errors: {
                amount: false,
                loanUse: false,
            }
        };
    },
    computed: {
        headerHtml() {
            if (this.$validateText(this.header)) {
                return this.$prismic.asHtml(this.header);
            }
            return `<h2> ${this.$translate('loan_wizard_intro_h2', 'Hitta det perfekta lånet med vårt lånetest')}</h2>`;
        },
        teaserHtml() {
            if (this.$validateText(this.teaser)) {
                return this.$prismic.asHtml(this.teaser);
            }
            return this.$translate('loan_wizard_intro_teaser', 'Få Skräddarsydda låneförslag utifrån dina krav och önskemål i Låneguiden för att enkelt kunna låna pengar smart.');
        }
    },
    methods: {
        submitValues() {
            this.errors.amount = this.amount < 1000 || this.amount > 600000;
            this.errors.loanUse = this.loanUse === null;

            if (!this.errors.amount && !this.errors.loanUse) {
                this.$router.push(
                    { 
                        path: this.$prismic.linkResolver(this.$store.state.settings.loan_wizard_page), 
                        query: { amount: this.amount, loanUse: this.loanUse } 
                    }
                );
            } 
        }
    }
};
</script>