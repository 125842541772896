<template>
    <div class="container content-space-1">
        <div class="row justify-content-center">
            <div class="col col-lg-9 d-flex">
                <i class="bi bi-quote display-3 align-self-start" style="transform: rotate(180deg)" />
                <blockquote class="blockquote content-space-md-2 content-space-1 text-center">
                    <p :class="quoteClass">{{ slice.primary.quote }}</p>
                    <p class="text-center text-muted fst-italic" :class="fontSizeAuthor"><i v-if="slice.primary.author_name" class="bi bi-dash" />{{ slice.primary.author_name }}</p>
                </blockquote>
                <i class="bi bi-quote align-self-end pb-md-3 display-3" />
            </div>
        </div>
    </div>    
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        quoteClass() { 
           
            if (this.slice.primary.quote.length < 60) {
                return 'h1';
            } 
            else if (this.slice.primary.quote.length > 250) {
                
                return 'h3';
            } 
            return 'h2';
        },
        fontSizeAuthor() {
            if (this.slice.primary.quote.length > 250) {
                return 'fs-4';
            } 
            return 'fs-3';
        }

    },
};
</script>