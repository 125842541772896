<template>
    <div class="card mb-5">
        <div class="card-header border-bottom">
            <h3 class="card-title">{{ $translate('opening_hours', 'Öppettider') }}</h3>
        </div>
        <div class="card-body">
            <table-row :title="$translate('monday', 'Måndag')" :value="model.openingHours.monday || $translate('closed', 'Stängt')" />
            <table-row :title="$translate('tuesday', 'Tisdag')" :value="model.openingHours.tuesday || $translate('closed', 'Stängt')" />
            <table-row :title="$translate('wednesday', 'Onsdag')" :value="model.openingHours.wednesday || $translate('closed', 'Stängt')" />
            <table-row :title="$translate('thursday', 'Torsdag')" :value="model.openingHours.thursday || $translate('closed', 'Stängt')" />
            <table-row :title="$translate('friday', 'Fredag')" :value="model.openingHours.friday || $translate('closed', 'Stängt')" />
            <table-row :title="$translate('saturday', 'Lördag')" :value="model.openingHours.saturday || $translate('closed', 'Stängt')" />
            <table-row :title="$translate('sunday', 'Söndag')" :value="model.openingHours.sunday || $translate('closed', 'Stängt')" />
        </div>
    </div>
</template>
<script>
import TableRow from '@/components/cards/TableRow.vue';
export default {
    components: {
        TableRow
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    }  
};
</script>