<template>
    <big-list-layout 
        :slice="sliceData"
        :get-pros="dataType === 'pros' ? getPros : undefined"
        :table-data="dataType === 'table-data' ? tableData : undefined"
    />
</template>
<script>
import BigListLayout from '@/components/BigList/Layout.vue';
export default {
    components: {
        BigListLayout
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            dataType: this.slice.primary.data_type
        };
    },
    computed: {
        sliceData() {
            this.slice.items
                .filter(item => item.card.id)
                .forEach(item => {
                    const card = this.$store.state.cards[item.card.id];
                    const model = new this.$models.CreditCard(card);

                    item.document = card,
                    item.image = card.data.card_image,
                    item.model = model;
                
                    return item;
                });
            return this.slice;
        }, 
    },
    methods: {
        getPros(item) {
            if (this.$validateText(item.pros)) {
                return item.pros.map(pro => pro.text);
            } 
            return item.document.data.pros.map(pro => pro.pro);
        },
        tableData(item) {
            return [
                {
                    title: this.$translate('yearly_cost', 'Årsavgift'),
                    value: item.model.yearlyCostString,
                },
                {
                    title: this.$translate('interest', 'Ränta'),
                    value: item.model.interestString,
                },
                {
                    title: this.$translate('preamble_effective_interest', 'Effektiv ränta'),
                    value: item.model.effectiveInterestString,
                },
                {
                    title: this.$translate('max_credit', 'Max kredit'),
                    value: item.model.getMaxCreditString(),
                },
                {
                    title: this.$translate('interest_free_days', 'Räntefria dagar'),
                    value: item.model.interestFreeDays,
                    disabled: !this.$isSweden() || !this.$isNorway()
                },
            ].filter(item => !item.disabled);
        },
    },
};
</script>