<template>
    <div class="content-space-md-1 content-space-0">
        <div class="container">
            <div class="w-lg-75 mx-lg-auto">
                <div class="row text-center col-md-divider col-divider-rotated">
                    <div v-for="(item, index) in slice.items" :key="index" class="col-sm-4 pt-5 pb-5 pt-md-0 pt-md-0">
                        <p class="display-6 text-dark"> {{ $prismic.asText(item.highlighted_stat) }} </p>
                        <p class="mb-0">{{ item.highlighted_stat_title }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
};
</script>