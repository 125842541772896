<template>
    <div class="container content-space-1">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <div 
                    v-if="$validateText(slice.primary.text)"
                    class="rich-text mb-5"
                    v-html="$prismic.asHtml(slice.primary.text)"
                />
                <div class="d-flex flex-column gap-3">
                    <nuxt-link 
                        v-for="(linkDocument, index) in linkDocuments" 
                        :key="index"
                        :to="$prismic.linkResolver(linkDocument)"
                        class="col-12 border-bottom pb-3"
                        :class="{'border-bottom-0': index + 1 === linkDocuments.length}"
                    >
                        <h3 class="mb-3">{{ linkDocument.data.title }}</h3>
                        <p 
                            v-if="$validateText($getDocumentPreamble(linkDocument))" 
                            class="text-body mb-2 webkit-box line-clamp-5 line-clamp-lg-4"
                        >
                            {{ $getDocumentPreamble(linkDocument) }}
                        </p>
                        <div class="d-flex align-items-center gap-2 fw-bold">
                            <span>
                                {{ $translate('card_read_more', 'Läs mer') }}
                            </span>
                            <i class="bi bi-chevron-right" />
                        </div>
                    </nuxt-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { replace } from '@swegaming-ab/nuxtjs-helpers';
export default {
    props: {
        slice: {
            type: Object,
            required: true
        },
        document: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            linkDocuments: []
        };
    },
    async fetch() {
        const predicates = [
            this.$prismic.predicates.any('document.type', ['page', 'post']),
        ];

        if (this.selectedDocumentsIds.length) {
            predicates.push(this.$prismic.predicates.in('document.id', this.selectedDocumentsIds));
        }
        else if (this.slice.primary.prismic_tag) {
            predicates.push(
                this.$prismic.predicates.at('document.tags', [this.slice.primary.prismic_tag]),
                this.$prismic.predicates.not('document.id', this.document.id),
            );
        }
        const response = await this.$prismic.api.query( 
            predicates, 
            {
                lang: process.env.PRISMIC_LOCALE,
                orderings: '[document.last_publication_date desc]',
                pageSize: 3
            }
        );
        this.linkDocuments = replace(response.results);
    },
    fetchKey() {
        return `link-section-${this.slice.id}`;
    },
    computed: {
        selectedDocumentsIds() {
            return this.slice.items
                .filter(item => item.document.id)
                .map(item => item.document.id);
        }
    }
};
</script>