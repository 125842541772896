<template>
    <div class="card-header py-1 ps-3 pe-2 bg-dark d-flex justify-content-between align-items-center flex-wrap">
        <nuxt-link :to="$prismic.linkResolver(lenderOrCard)" class="text-white fs-6 text-decoration-underline me-auto">{{ lenderOrCard.data.title }}</nuxt-link> 
        <span v-if="lenderOrCard.data.cta_badge" class="badge bg-primary ms-2">
            {{ lenderOrCard.data.cta_badge }}
        </span>
        <toggle-compare-lender v-if="compareEnabled" :lender-id="lenderOrCard.id" />
    </div>
</template>
<script>
import ToggleCompareLender from '@/components/ToggleCompareLender.vue';
export default {
    components: {
        ToggleCompareLender
    },
    props: {
        lenderOrCard: {
            type: Object,
            required: true
        }
    },
    computed: {
        compareEnabled() {
            return this.lenderOrCard.type === 'lender' && this.$store.state.settings.compare_lenders_page?.id;
        }
    }
};
</script>