<template>
    <nuxt-link 
        :to="$prismic.linkResolver(guide)"
        class="d-flex flex-column gap-1 flex-md-row text-dark text-primary-hover"
    >
        <prismic-image
            v-if="guide.data.image.url"
            :img="guide.data.image.thumbnail || guide.data.image"
            w="365"
            class="rounded me-5 max-width-100"
        />
        <fallback-image 
            v-else
            class="rounded me-5 max-width-100"
            :alt="guide.data.title"
            width="365"
            loading="lazy"
        />
        <div class="py-2 d-flex flex-column justify-content-between">
            <h3 class="card-title text-reset"> {{ guide.data.title }} </h3>
            <p v-if="$validateText($getDocumentPreamble(guide))" class="text-body webkit-box line-clamp-4 line-clamp-lg-3 mb-1">
                {{ $getDocumentPreamble(guide) }}
            </p>
            <span class="text-muted small">
                <i class="bi bi-calendar" />
                {{ $format.date(guide.last_publication_date, 'DATE_FULL') }}
            </span>
        </div>
    </nuxt-link>
</template>

<script>
import FallbackImage from '@/components/FallbackImage.vue';
export default {
    components: { FallbackImage },
    props: {
        guide: {
            type: Object,
            required: true
        }
    }
};
</script>
