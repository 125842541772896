<template>
    <div class="container content-space-1 d-flex justify-content-center">
        <div class="w-100 w-lg-75">
            <div class="d-flex align-items-end border-bottom pb-2">
                <prismic-image  
                    :img="author.avatar"
                    h="90"
                    w="90"
                />
                <div>
                    <h1 class="mb-0 h3 text-capitalize">{{ $prismic.asText(author.name) }}</h1>
                    <p class="mb-2 muted text-primary"> 
                        {{ author.work_title }}
                    </p>
                </div>
            </div>
            <div class="text-primary fs-2 my-3">
                <a v-if="author.email" :href="`mailto:${author.email}`"><i class="bi bi-envelope-fill pe-1" /></a>
                <a v-if="author.linkedin_url" :href="author.linkedin_url"><i class="bi bi-linkedin" /></a>
            </div>
            
            <div 
                v-if="$validateText(author.bio_long)" 
                class="rich-text mb-4" 
                v-html="$prismic.asHtml(author.bio_long)" 
            />

            <p v-if="author.quote" class="d-flex align-items-start fst-italic border-top border-bottom py-4">
                <i class="bi bi-quote display-5 text-primary me-2" />
                {{ author.quote }}
            </p>

            <ul v-if="experiencesExists" class="step py-4">
                <li v-for="(authorExperience, index) in experiences" :key="index" class="step-item">
                    <div class="step-content-wrapper">
                        <span class="step-icon step-icon-soft-dark">
                            <prismic-image  
                                v-if="authorExperience.logo.url"
                                :img="authorExperience.logo"
                                h="35"
                                w="35"
                            />
                            <i v-else class="bi bi-briefcase-fill fs-3" />
                        </span>
                        <div class="step-content">
                            <span class="h5 d-block text-primary mt-2 mb-0">{{ authorExperience.company_or_location }}</span>
                            <span class="text-muted small">{{ authorExperience.period }}</span>
                            <div class="my-4 ">
                                <h4 class="step-title mb-1">{{ authorExperience.title }}</h4>
                                <p>{{ authorExperience.description }}</p>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <contact-form />
        </div>
    </div>
</template>
<script>
import ContactForm from '@/components/ContactForm.vue';
export default {
    components: { 
        ContactForm 
    },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        author() {
            return this.document.data;
        },
        experiences() {
            return this.author.experiences.filter(experiences => experiences.title); 
        },
        experiencesExists() {
            return this.experiences.length > 0;
        },
    },
};
</script>