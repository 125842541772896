<template>
    <div :id="$formatId(slice.id)" class="container content-space-b-1">
        <h2 v-if="h2.length > 0" class="mb-5">{{ h2 }}</h2>
        <div class="row">
            <corporate-lender-card 
                v-for="(lender, index) in topList" 
                :key="lender.id" 
                :lender-id="lender.id" 
                :position="index + 1"
            />
        </div>
        <div v-show="showAll" class="row">
            <corporate-lender-card 
                v-for="(lender, index) in notShownList" 
                :key="lender.id" 
                :lender-id="lender.id"
                :position="topList.length + index + 1"
            />
        </div>
        <CommercialDisclaimer />
        <div 
            v-show="!showAll && notShownList.length > 0" 
            class="col-lg-12 text-sm text-dark"
        >
            <div class="text-center mt-4 mb-4">
                <a class="btn btn-md btn-secondary fw-bold" @click="showAll = true;">
                    {{ $translate('show_all_corporate_loans', 'Visa alla företagslån') }} <i class="bi bi-arrow-down-circle-fill ms-2" />
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import CorporateLenderCard from '@/components/cards/CorporateLenderCard.vue';
import CommercialDisclaimer from '@/components/CommercialDisclaimer.vue';
export default {
    components: {
        CorporateLenderCard,
        CommercialDisclaimer
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            lendersToShow: 9,
            showAll: false
        };
    },
    computed: {
        h2() {
            return this.$prismic.asText(this.slice.primary.h2)
                .replace('%count%', this.lenders.length);
        },
        lenders() {
            return Object.values(this.$store.state.corporateLenders);
        },
        topList() {
            return this.lenders.slice(0, this.lendersToShow);
        },
        notShownList() {
            return this.lenders.slice(this.lendersToShow);
        }
    }
};
</script>