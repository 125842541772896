<template>
    <div :id="$formatId(slice.id)" class="container content-space-b-1">
        <h2 v-if="h2.length > 0" class="mb-5">{{ h2 }}</h2>
        <div class="row">
            <credit-card 
                v-for="(card, index) in cards" 
                :key="card.id" 
                :card-id="card.id" 
                :position="index + 1"
            />
        </div>
    </div>
</template>
<script>
import { map } from 'lodash';
import CreditCard from '@/components/cards/CreditCard.vue';
export default {
    components: {
        CreditCard
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            cardsToShow: 12
        };
    },
    computed: {
        h2() {
            return this.$prismic.asText(this.slice.primary.h2)
                .replace('%count%', this.cards.length);
        },
        cards() {
            if (this.slice.primary.list.id) {
                return map(this.$store.state
                    .lists[this.slice.primary.list.id]
                    .data.items,
                'item').filter(item => item.id);
            }

            return map(this.slice.items, 'item').filter(item => item.id);
        },
        topList() {
            return this.cards.slice(0, this.cardsToShow);
        },
        notShownList() {
            return this.cards.slice(this.cardsToShow);
        }
    }
};
</script>