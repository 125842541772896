<template>
    <div class="col-lg-4 bg-dark text-light card p-5 ms-lg-3 mt-5 mt-lg-0">
        <table class="table table-dark text-light">
            <tbody>
                <tr>
                    <td colspan="2" class="text-light fw-bold">{{ $translate('calculator_results_before_collection', 'Innan samling') }}</td>
                </tr>
                <tr>
                    <td class="text-light">{{ $translate('calculator_loan_amount', 'Lånebelopp') }}</td>
                    <td class="text-end text-light">{{ $format.currency(amountSum) }}</td>
                </tr>
                <tr>
                    <td class="text-light">{{ $translate('calculator_monthly_cost', 'Månadskostnad') }}</td>
                    <td class="text-end text-light">{{ $format.currency(previousMonthlyCostSum) }}</td>
                </tr>
                <tr>
                    <td class="text-light">{{ $translate('calculator_results_interest_cost', 'Räntekostnad') }}</td>
                    <td class="text-end text-light">{{ Math.round(interestCost * 100) / 100 }}%</td>
                </tr>
                <tr>
                    <td colspan="2" class="text-light fw-bold pt-5">{{ $translate('calculator_results_after_collection', 'Efter samling') }}</td>
                </tr>
                <tr>
                    <td class="text-light">{{ $translate('calculator_loan_amount', 'Lånebelopp') }}</td>
                    <td class="text-end text-light">{{ $format.currency(amountSum) }}</td>
                </tr>
                <tr>
                    <td class="text-light">{{ $translate('calculator_monthly_cost', 'Månadskostnad') }}</td>
                    <td class="text-end text-light">{{ $format.currency(newMonthlyCostSum) }}</td>
                </tr>
                <tr>
                    <td class="text-light">{{ $translate('calculator_results_monthly_savings', 'Du sparar i månaden') }}</td>
                    <td class="text-end text-light">{{ $format.currency(monthlySavings) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        newDesiredInterestRate: {
            type: Number,
            required: true
        },
        amountSum: {
            type: Number,
            required: true
        },
        previousMonthlyCostSum: {
            type: Number,
            required: true
        }
    },
    computed: {
        newMonthlyCostSum() {
            return this.amountSum * (this.newDesiredInterestRate / 100) / 12;
        },
        monthlySavings() {
            return this.previousMonthlyCostSum - this.newMonthlyCostSum;
        },
        interestCost() {
            if (this.previousMonthlyCostSum && this.amountSum) {
                return ((this.previousMonthlyCostSum * 12) / this.amountSum) * 100;
            }
            return 0;
        }
    }
};
</script>