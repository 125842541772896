<template>
    <div class="col-12 col-md-6 col-lg-4 mb-5">
        <div class="card hover-translate-y-n10 hover-shadow-lg border">
            <card-header :lender-or-card="lender" />
            <card-image :image="lender.data.logo" width="150" />
            
            <div class="card-body py-4 bg-light fs-6">
                <table-row :title="$translate('loan_amount_lowest', 'Lånebelopp, lägsta')" :value="model.minAmountString" />
                <table-row :title="$translate('loan_amount_highest', 'Lånebelopp, högsta')" :value="model.maxAmountString" />
                <table-row :title="$translate('loan_duration', 'Löptid')" :value="model.getDurationString($translate('month_short', 'mån'), $translate('year_short', 'år'))" />
                <table-row :title="$translate('company_age_requirement', 'Krav, ålder')" :value="model.companyRequirementsString.minAge" />
                <table-row :title="$translate('company_revenue_requirement', 'Krav, omsättning')" :value="model.companyRequirementsString.minRevenue" />
                <table-row :title="$translate('company_personal_bail', 'Personlig borgen')" :value="model.requiresPersonalBail ? $translate('yes', 'Ja') : $translate('no', 'Nej')" />
                <table-row :title="$translate('company_requires_safety', 'Kräver säkerhet')" :value="model.requiresCollateral ? $translate('yes', 'Ja') : $translate('no', 'Nej')" />
            </div>

            <card-links :lender-or-card="lender" :position="position" />
        </div>
    </div>
</template>
<script>
import CardHeader from './CardHeader.vue';
import CardImage from './CardImage.vue';
import TableRow from './TableRow.vue';
import CardLinks from './CardLinks.vue';
export default {
    components: {
        CardHeader,
        CardImage,
        TableRow,
        CardLinks
    },
    props: {
        lenderId: {
            type: String,
            required: true
        },
        position: {
            type: Number,
            required: false,
            default: null
        }
    },
    computed: {
        lender() {
            return this.$store.state.corporateLenders[this.lenderId];
        },
        model() {
            return new this.$models.CorporateLoan(this.lender);
        }
    }
};
</script>