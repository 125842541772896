<template>
    <div class="container content-space-t-1">
        <div class="w-lg-75 text-center mx-lg-auto">
            <div class="mb-5 mb-md-10">
                <h1 class="display-4">{{ $prismic.asText(slice.primary.h1) }}</h1>
                <p class="lead">{{ slice.primary.p }}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    }
};
</script>