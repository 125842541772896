<template>
    <div :id="$formatId(slice.id)" class="container content-space-b-1">
        <h2 v-if="h2.length > 0" class="mb-5">{{ h2 }}</h2>
        <div class="row">
            <corporate-lender-card 
                v-for="(lender, index) in lenders" 
                :key="lender.id" 
                :lender-id="lender.id" 
                :position="index + 1"
            />
        </div>
    </div>
</template>
<script>
import { map } from 'lodash';
import CorporateLenderCard from '@/components/cards/CorporateLenderCard.vue';
export default {
    components: {
        CorporateLenderCard
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        h2() {
            return this.$prismic.asText(this.slice.primary.h2)
                .replace('%count%', this.lenders.length);
        },
        lenders() {
            if (this.slice.primary.list.id) {
                return map(this.$store.state
                    .lists[this.slice.primary.list.id]
                    .data.items,
                'item').filter(item => item.id);
            }

            return map(this.slice.items, 'item').filter(item => item.id);
        }
    }
};
</script>