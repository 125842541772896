<template>
    <div v-if="items.length" class="container content-space-1">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <div class="border-bottom border-2 content-space-b-1">
                    <div class="mb-4">
                        <h2 v-if="$validateText(slice.primary.h2)">
                            {{ slice.primary.h2 }}
                        </h2>
                        <div 
                            v-if="$validateText(slice.primary.teaser)"
                            class="rich-text text-dark" 
                            v-html="$prismic.asHtml(slice.primary.teaser)"
                        />
                    </div>
                    <top-list 
                        v-if="slice.primary.show_toplist"
                        :items="items"
                    /> 
                </div>

                <big-list-item 
                    v-for="(item, index) in items" 
                    :key="index"
                    :item="item"
                    :index="index"
                    class="d-flex flex-column gap-4 content-space-t-1"
                />
            </div>
        </div>
    </div>
</template>

<script>
import TopList from '@/components/TopList.vue';
import BigListItem from '@/components/BigListItem.vue';
export default {
    components: {
        TopList,
        BigListItem
    },
    props: {
        slice: { 
            type: Object,
            required: true
        },
        getPros: {
            type: Function,
            required: false,
            default: () => null
        },
        tableData: {
            type: Function,
            required: false,
            default: () => null
        }
    },
    computed: {
        items() {
            return this.slice.items.map(item => {
                return {...item, ...{
                    h3: this.$validateText(item.h3) ? item.h3 : item.document.data.title,
                    pros: this.getPros(item),
                    tableData: this.tableData(item)
                }};
            });      
        },
    }
};
</script>