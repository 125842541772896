<template>
    <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-9">
        <span v-if="introHeader" :class="titleColor" class="text-cap">{{ introHeader }}</span>
        <h2>{{ h2 }}</h2>
    </div>
</template>
<script>
export default {
    props: {
        h2: {
            type: String,
            required: true
        },
        introHeader: {
            type: String,
            required: false,
            default: null
        },
        titleColor: {
            type: String, 
            required: false,
            default: 'text-primary'
        },
    },
};
</script>