<template>
    <!-- NOTE Very similar to /components/pages/card/Banner.vue -->
    <div class="container page-header">
        <div class="d-sm-flex content-space-t-1 content-space-t-lg-2 align-items-lg-center px-3 pb-3">
            <div class="flex-shrink-0 mb-2 mb-sm-0 me-0 me-md-4 shadow-sm p-3 text-center mb-4 mb-md-0">
                <prismic-image :img="document.data.logo" w="150" class="" />
            </div>

            <div class="flex-grow-1">
                <div class="row">
                    <div class="col-md mb-3 mb-md-0  d-flex align-items-center">
                        <h1 class="h2 mb-1">
                            {{ document.data.title }}
                            <img v-if="document.data.redirect_enabled" class="avatar avatar-xs" src="@/assets/svg/illustrations/top-vendor.svg">
                        </h1>

                        <!-- <div class="d-flex gap-1">
                            <span class="fw-semi-bold text-dark ms-1">4.5</span>
                            <span class="ms-1">5 användarbetyg</span>
                        </div> -->
                    </div>

                    <div class="col-md-auto align-self-md-end">
                        <div class="d-grid gap-2">
                            <div v-if="model.isArchived">{{ $translate('no_longer_active', '{title} är inte aktiva på marknaden längre.').replace('{title}', document.data.title) }}</div>
                            <div v-else-if="document.data.redirect_enabled" class="d-flex flex-column justify-content-center align-items-center">
                                <out-link :document="document" class="btn btn-primary fw-bold" placement="banner" />
                                <span v-if="$isNorway()" class="fs-6">Annonselenke</span>
                            </div>
                            <div v-else>{{ $translate('no_active_cooperation', 'Vi har inget aktivt samarbete med {title}.').replace('{title}', document.data.title) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        },
        model: {
            type: Object,
            required: true
        }
    }  
};
</script>