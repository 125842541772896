<template>
    <newsletter-form :background-color="backgroundColor" />
</template>
<script>
import NewsletterForm from '@/components/NewsletterForm.vue'; 
export default {
    components: {
        NewsletterForm
    },
    props: {
        slice: {
            type: Object,
            required: true
        },
    },
    computed: {
        backgroundColor() {
            return this.slice.primary.has_background ? 'bg-soft-primary' : '';
        },
    },
};
</script>
