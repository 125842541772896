<template>
    <ul class="list-unstyled mb-0 d-flex flex-column gap-2 gap-md-1"> 
        <li v-for="link in links"
            :key="link.id"
        >
            <a 
                v-scroll-to="{el: `#${$formatId(link.id)}`, offset: -100}" 
                :href="`#${$formatId(link.id)}`"
                class="text-dark"
            > 
                <span class="text-primary-hover">{{ link.primary.text }}</span>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        slices: {
            type: Array,
            required: true
        }
    },
    computed: {
        links() {
            return this.slices
                .filter(slice => slice.slice_type === 'table_of_contents_link');
        }
    }
};
</script>