<template>
    <div class="table-responsive shadow rounded mt-4 mobile-scroll-shadow">
        <table v-if="tableTitles.length" class="table table-striped mb-0 align-middle">
            <thead>
                <tr>
                    <th v-if="showLogos" class="min-wpx-150 border-bottom box-sizing-unset px-2 px-lg-3">
                        {{ $translate('lender', 'Långivare') }}
                    </th>
                    <th 
                        v-for="(title, index) in tableTitles" 
                        :key="index"
                        class="min-wpx-150 border-bottom box-sizing-unset px-2 px-lg-3"   
                    >
                        {{ title }}
                    </th>
                    <th v-if="showOutLinks" class="min-wpx-150 border-bottom box-sizing-unset px-2 px-lg-3 wpx-10">
                        {{ $translate('application_label', 'Ansök') }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(data, index) in tableData" :key="index">
                    <td v-if="showLogos" class="text-dark box-sizing-unset px-2 px-lg-3">
                        <prismic-image 
                            v-if="lenders[index]"
                            :img="lenders[index].data.logo"
                            w="100"
                        />
                    </td>
                    <td 
                        v-for="(cell, cellIndex) in data" 
                        :key="cellIndex"
                        class="text-dark box-sizing-unset px-2 px-lg-3"
                    >
                        <div 
                            class="rich-text" 
                            v-html="cell" 
                        />
                    </td>
                    <td v-if="showOutLinks" class="text-dark box-sizing-unset px-2 px-lg-3">
                        <out-link 
                            v-if="lenders[index]" 
                            :document="lenders[index]" 
                            class="btn btn-sm btn-primary fw-bold w-100 position-relative zi-3" 
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import CustomizableTable from '@/mixins/CustomizableTable.js';
export default {
    mixins: [
        CustomizableTable
    ],
};
</script>