export default {
    props: {
        tableTitles: {
            type: Array,
            required: true
        },
        tableData: {
            type: Array,
            required: true
        },
        lenders: {
            type: Array,
            required: false,
            default: () => []
        },
        showLogos: {
            type: Boolean,
            required: false,
            default: false
        },
        showOutLinks: {
            type: Boolean,
            required: false,
            default: false
        },
    },
};