<template>
    <div class="container mb-5">
        <div class="row justify-content-center mb-5 px-lg-3 px-1">
            <div 
                class="col mx-2 col-lg-9 text-dark p-5 rounded content rich-text" 
                :class="bgClass" 
                v-html="$prismic.asHtml(slice.primary.content)" 
            />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        bgClass() {
            return {
                'primary': 'bg-soft-primary',
                'secondary': 'bg-soft-secondary',
                'dark': 'bg-soft-dark',
                'light': 'bg-light',
                'success': 'bg-soft-success',
                'danger': 'bg-soft-danger',
                'warning': 'bg-soft-warning',
                'info': 'bg-soft-info'
            }[this.slice.primary.style] || 'bg-soft-primary';
        }
    }
};
</script>