<template>
    <div class="container py-3 content-space-b-1 border-bottom">
        <div v-if="showAlternatives" class="row">
            <credit-card 
                v-for="(card, index) in alternatives" 
                :key="card.id" 
                :card-id="card.id"
                :position="index + 1" 
            />
        </div>
        <div v-else class="row d-flex align-items-center">
            <div class="col-12 col-lg-6">
                <p class="lead">{{ $prismic.asText(document.data.preamble) }}</p>
                <ul class="lead">
                    <li v-for="(iterator, index) in document.data.pros" :key="`pro${index}`">{{ iterator.pro }}</li>
                </ul>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <table-row :title="$translate('yearly_cost', 'Årsavgift')" :value="model.yearlyCostString" />
                        <table-row :title="$translate('max_credit', 'Maxkredit')" :value="model.getMaxCreditString()" />
                        <table-row :title="$translate('interest', 'Ränta')" :value="model.interestString" />
                        <table-row :title="$translate('preamble_effective_interest', 'Effektiv ränta')" :value="model.effectiveInterestString" />
                        <table-row :title="$translate('interest_free_days', 'Räntefria dagar')" :value="model.getInterestFreeDaysString()" />
                        <table-row :title="$translate('min_age', 'Åldersgräns')" :value="model.minAge + ' ' + $translate('years', 'år')" />
                        <table-row :title="$translate('preamble_currency_exchange_fee', 'Valutapåslag')" :value="model.getCurrencyExchangeFeeString()" />
                        <table-row :title="$translate('preamble_withdrawal_fee', 'Uttagsavgift')" :value="model.getWithdrawalFeeString()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CreditCard from '@/components/cards/CreditCard.vue';
import TableRow from '@/components/cards/TableRow.vue';
export default {
    components: {
        CreditCard,
        TableRow
    },
    props: {
        document: {
            type: Object,
            required: true
        },
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        showAlternatives() {
            return (! this.document.data.redirect_enabled) || this.model.isArchived;
        },
        alternatives() {
            return Object.values(this.$store.state.cards)
                .filter(card => card.data.redirect_enabled)
                .slice(0, 3);
        }
    }
};
</script>