
<script>
import Slice from './Slice.vue';
import LenderList from '@/components/templates/LenderList.vue';
import InsertLenders from '@/mixins/InsertLenders.js'; 
import LenderFilters from '@/mixins/LenderFilters.js';

export default {
    extends: LenderList,
    mixins: [
        Slice,
        InsertLenders,
        LenderFilters,
    ],
    computed: {
        h2() {
            return this.$prismic.asText(this.slice.primary.h2);
        },
        count() {
            return this.slice.primary.count;
        },
        tags() {
            return this.slice.items.map(item => item.tag.trim());
        },
        filtered() {
            if (this.tags.length === 0) {
                return [];
            }

            return Object
                .values(this.$store.state.lenders)
                .filter(this.filter);
        },
        ordered() {
            return this.$getOrderedList(this.filtered, this.$store.state.lenders, this.$models.Loan);
        },
        list() {
            return this.insertLenders(this.ordered);
        }
    },
    methods: {
        filter(lender) {

            const model = new this.$models.Loan(lender);

            if (! this.filterRedirects(lender, model)) {
                return false;
            }

            const found = Object.values(this.tags)
                .filter(tag => lender.tags.includes(tag))
                .length; 

            if (this.slice.primary.tag_selector === 'or') {
                return found > 0;
            }

            return found === this.tags.length;
        }
    }
};
</script>