<template>
    <div class="col-lg-4 ms-lg-3 mt-5 mt-lg-0">
        <div class="overview bg-dark text-light card p-5 sticky-top">
            <select id="frequency" v-model="overviewButtonFrequency" class="w-100 form-select">
                <option value="monthly">{{ $translate('calculator_monthly', 'Månatlig') }}</option>
                <option value="yearly">{{ $translate('calculator_yearly', 'Årlig') }}</option>
            </select>
            <br>
            <table class="table table-dark text-light">
                <tbody>
                    <tr>
                        <td class="fw-bold text-light">{{ $translate('calculator_income', 'Inkomst') }}</td>
                        <td class="text-end text-light">{{ $format.currency((overviewButtonFrequency === 'monthly') ? $calculateMonthlyIncomeOrExpenses(incomes) : $calculateYearlyIncomeOrExpenses(incomes)) }}</td>
                    </tr>
                    <tr v-for="(keyAndTranslation, index) in expenseKeysAndTranslations" :key="index">
                        <td class="fw-bold text-light">{{ $translate(keyAndTranslation.key, keyAndTranslation.translation) }}</td>
                        <td class="text-end text-light">{{ $format.currency((overviewButtonFrequency === 'monthly') ? $calculateMonthlyIncomeOrExpenses(expenses[index]) : $calculateYearlyIncomeOrExpenses(expenses[index])) }}</td>
                    </tr>
                    <tr>
                        <td class="fw-bold text-light">{{ $translate('calculator_sum', 'Summa') }}</td>
                        <td class="text-end text-light">{{ $format.currency((overviewButtonFrequency === 'monthly') ? monthlyAvailableAmount : yearlyAvailableAmount) }}</td>
                    </tr>
                </tbody>
            </table>
            <br>
            <button class="btn btn-info align-self-center" @click="diagramIsVisible = true">{{ $translate('calculator_show_chart_button', 'Visa diagram') }}</button>
            <div id="modal" class="modal" :class="{ 'show': diagramIsVisible }" @click="checkClicked($event)">
                <div class="modal-dialog pt-6 pt-md-0">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $translate('calculator_your_expenses', 'Dina utgifter') }}</h5>
                            <button type="button" class="btn-close" @click="diagramIsVisible = false" />
                        </div>
                        <div class="modal-body text-body pt-2 pt-md-5 pb-2 pb-md-5">
                            <client-only>
                                <vc-donut :thickness="30" has-legend :sections="sections" />
                            </client-only>
                        </div>
                        <div class="modal-footer pt-2 pt-md-4 pb-2 pb-md-4">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="diagramIsVisible = false">{{ $translate('calculator_hide_chart_button', 'Stäng') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import Donut from 'vue-css-donut-chart';
import 'vue-css-donut-chart/dist/vcdonut.css';
Vue.use(Donut);

export default {
    props: {
        incomes: {
            type: Array,
            required: true
        },
        livingExpenses: {
            type: Array,
            required: true
        },
        transportExpenses: {
            type: Array,
            required: true
        },
        insuranceExpenses: {
            type: Array,
            required: true
        },
        childrenExpenses: {
            type: Array,
            required: true
        },
        necessitiesExpenses: {
            type: Array,
            required: true
        },
        otherExpenses: {
            type: Array,
            required: true
        },
        expenseKeysAndTranslations: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            overviewButtonFrequency: 'monthly',
            expenses: [
                this.livingExpenses, 
                this.transportExpenses, 
                this.insuranceExpenses, 
                this.childrenExpenses, 
                this.necessitiesExpenses,
                this.otherExpenses
            ],
            diagramIsVisible: false,
            sections: [
                {label: this.$translate('calculator_residence', 'Boende'), value: 0, color: '#D13252'},
                {label: this.$translate('calculator_transport', 'Transport'), value: 0, color: '#FC7362'},
                {label: this.$translate('calculator_insurance', 'Försäkring'), value: 0, color: '#F8ECA5'},
                {label: this.$translate('calculator_children', 'Barn'), value: 0, color: '#88D9CB'},
                {label: this.$translate('calculator_necessities', 'Förnödenheter'), value: 0, color: '#6CBAD1'},
                {label: this.$translate('calculator_entertainment_and_other', 'Nöjen och övrigt'), value: 0, color: '#AA7AD6'},
            ]
        };
    },
    computed: {
        totalMonthlyExpenses() {
            let sum = 0;
            for (let i = 0; i < this.expenses.length; i++) {
                sum += this.$calculateMonthlyIncomeOrExpenses(this.expenses[i]);
            }
            return sum;
        },
        totalYearlyExpenses() {
            let sum = 0;
            for (let i = 0; i < this.expenses.length; i++) {
                sum += this.$calculateYearlyIncomeOrExpenses(this.expenses[i]);
            }
            return sum;
        },
        monthlyAvailableAmount() {
            return this.$calculateMonthlyIncomeOrExpenses(this.incomes) - this.totalMonthlyExpenses;
        },
        yearlyAvailableAmount() {
            return this.$calculateYearlyIncomeOrExpenses(this.incomes) - this.totalYearlyExpenses;
        },
    },
    watch: {
        diagramIsVisible() {
            if (this.diagramIsVisible) {
                document.body.style.overflow = 'hidden';
                return;
            }
            document.body.style.overflow = 'visible';
        },
        totalYearlyExpenses() {
            if (this.totalYearlyExpenses !== 0) {
                for (let i = 0; i < this.expenses.length; i++) {
                    this.sections[i].value = (this.$calculateYearlyIncomeOrExpenses(this.expenses[i]) / this.totalYearlyExpenses) * 100;
                }
            } 
            else {
                for (let section of this.sections) {
                    section.value = 0;
                }
            }
        }
    },
    methods: {
        checkClicked(event) {
            if (event.target.id === 'modal') {
                this.diagramIsVisible = false;
            }
        }
    }
};
</script> 
<style lang="scss" scoped>
    .overview {
        top: 90px;
        z-index: 0;
    }

    .modal {
        display: none; background-color:rgba(0, 0, 0, 1);
        
        &.show {
            display: block; background-color:rgba(0, 0, 0, 0.5);
        }

        .modal-dialog {
            top: 50%; 
            transform: translate(0, -50%);
        }
    }
</style>