<template>
    <div>
        <slices
            :slices="document.data.body"
            :document="document"
        />
        <authority-box 
            :author="document.data.author" 
            :last-publication-date="document.last_publication_date"
            :sources="document.data.sources"
        />
        <newsletter-form />
        <site-wide-links 
            :footer-id="document.data.footer_id" 
        />
    </div>
</template>

<script>
import Slices from '@/components/slices/Slices.vue';
import AuthorityBox from '@/components/AuthorityBox.vue';
import NewsletterForm from '@/components/NewsletterForm.vue';

export default {
    components: { 
        AuthorityBox,
        Slices,
        NewsletterForm
    },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
};
</script>
