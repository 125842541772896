<template>
    <div v-if="author.id" class="container content-space-b-1">
        <div class="row justify-content-center">
            <div class="col mx-3 col-lg-8 card">
                <div class="card-header px-0 py-2 p-lg-4 border-bottom">
                    <div class="d-flex align-items-center">
                        <prismic-image :img="author.data.avatar" w="60" h="60" />
                        <div class="d-flex flex-column justify-content-center pt-2 ps-2">
                            <nuxt-link :to="$prismic.linkResolver(author)">
                                <h4 class="card-title">{{ $prismic.asText(author.data.name) }}</h4>
                            </nuxt-link>
                            <span>
                                {{ 
                                    $translate('last_updated', 'Senast uppdaterad {date}')
                                        .replace('{date}', $format.date(lastPublicationDate)) 
                                }}    
                            </span>
                        </div>
                    </div>
                </div>
                <div class="card-body px-0 py-2 p-lg-4">
                    <p class="m-0">{{ $prismic.asText(author.data.bio) }}</p>

                    <template v-if="hasSources">
                        <h5 class="mt-5 card-title">
                            {{ $translate('sources', 'Källor') }}
                        </h5>
                        <ul class="list-unstyled">
                            <li 
                                v-for="source in filteredSources" 
                                :key="source"
                            >
                                <a :href="source" target="_blank">
                                    {{ source }}
                                </a>
                            </li>
                        </ul>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        author: {
            type: Object,
            required: true
        },
        lastPublicationDate: {
            type: String,
            required: true
        },
        sources: {
            type: Array,
            required: true
        }
    },
    computed: {
        filteredSources() {
            return this.sources
                .map(iterator => iterator.source)
                .filter(source => source !== null && source.length > 0)
                .map(source => source.trim());
        },
        hasSources() {
            return this.filteredSources.length > 0;
        }
    }
};
</script>