<template>
    <div class="container content-space-1">
        <h2 class="align-self-start">{{ $prismic.asText(slice.primary.h2) }}</h2>
        <div class="row">
            <div class="card col">
                <div class="card-body">
                    <budget-accordion v-for="(incomeOrExpense, index) in incomesAndExpenses" :key="index" 
                                      :to-iterate="incomeOrExpense"
                                      :current-index="index"
                                      :key-and-translation="keysAndTranslations[index]"
                                      @changed="updateIncomeOrExpense" 
                    />
                </div>
            </div>
            <budget-overview :incomes="incomes"
                             :living-expenses="livingExpenses"
                             :transport-expenses="transportExpenses"
                             :insurance-expenses="insuranceExpenses"
                             :children-expenses="childrenExpenses"
                             :necessities-expenses="necessitiesExpenses"
                             :other-expenses="otherExpenses"
                             :expense-keys-and-translations="expenseKeysAndTranslations"
            />
        </div>
    </div>
</template>
<script>
import BudgetAccordion from '@/components/calculator/BudgetAccordion.vue';
import BudgetOverview from '@/components/calculator/BudgetOverview.vue';

export default {
    components: {
        BudgetAccordion,
        BudgetOverview
    },
    layout: 'site',
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            incomes: [
                {title: this.$translate('calculator_salary_after_tax', 'Lön (efter skatt)'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_allowance', 'Bidrag'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_other', 'Övrigt'), amount: null, frequency: 'monthly'}
            ],
            livingExpenses: [
                {title: this.$translate('calculator_rent', 'Hyra'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_mortgage_loans', 'Bolån'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_heating', 'Uppvärmning'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_water', 'Vatten'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_electricity', 'Elektricitet'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_real_estate_tax', 'Fastighetsskatt'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_other', 'Övrigt'), amount: null, frequency: 'monthly'}
            ],
            transportExpenses: [
                {title: this.$translate('calculator_leasing_agreement_car_loans', 'Leasingavtal/billån'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_fuel', 'Bränsle'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_repairs_maintenance', 'Reparationer/underhåll'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_public_transport', 'Kollektivtrafik'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_taxi', 'Taxi'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_other', 'Övrigt'), amount: null, frequency: 'monthly'}
            ],
            insuranceExpenses: [
                {title: this.$translate('calculator_home_insurance', 'Hemförsäkring'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_accident_insurance', 'Olycksförsäkring'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_health_insurance', 'Hälsoförsäkring'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_car_insurance', 'Bilförsäkring'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_other', 'Övrigt'), amount: null, frequency: 'monthly'}
            ],
            childrenExpenses: [
                {title: this.$translate('calculator_preschool_and_education', 'Förskola och utbildning'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_spare_time', 'Fritid'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_pocket_money', 'Fickpengar'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_children_savings', 'Barnsparande'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_other', 'Övrigt'), amount: null, frequency: 'monthly'}
            ],
            necessitiesExpenses: [
                {title: this.$translate('calculator_food', 'Mat'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_glasses_and_contact_lenses', 'Glasögon och kontaktlinser'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_dentist', 'Tandläkare'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_medicine', 'Medicin'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_other', 'Övrigt'), amount: null, frequency: 'monthly'}
            ],
            otherExpenses: [
                {title: this.$translate('calculator_sports_and_spare_time', 'Sport och fritid'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_tv_streaming_services', 'TV/streamingtjänster'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_phone_subscription_internet', 'Telefonabonnemang/internet'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_vacation', 'Semester'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_alcohol', 'Alkohol'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_tobacco', 'Tobak'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_restaurant_and_nightlife', 'Restaurang och uteliv'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_other_loan_costs', 'Övriga lånekostnader'), amount: null, frequency: 'monthly'},
                {title: this.$translate('calculator_other', 'Övrigt'), amount: null, frequency: 'monthly'}
            ],
            keysAndTranslations: [
                {key: 'calculator_income', translation: 'Inkomst'},
                {key: 'calculator_residence', translation: 'Boende'},
                {key: 'calculator_transport', translation: 'Transport'},
                {key: 'calculator_insurance', translation: 'Försäkring'},
                {key: 'calculator_children', translation: 'Barn'},
                {key: 'calculator_necessities', translation: 'Förnödenheter'},
                {key: 'calculator_entertainment_and_other', translation: 'Nöjen och övrigt'},
                {key: 'calculator_sum', translation: 'Sum'}
            ],
            expenseKeysAndTranslations: [
                {key: 'calculator_residence', translation: 'Boende'},
                {key: 'calculator_transport', translation: 'Transport'},
                {key: 'calculator_insurance', translation: 'Försäkring'},
                {key: 'calculator_children', translation: 'Barn'},
                {key: 'calculator_necessities', translation: 'Förnödenheter'},
                {key: 'calculator_entertainment_and_other', translation: 'Nöjen och övrigt'}
            ] 
        };
    },
    computed: {
        incomesAndExpenses: function() {
            return [
                this.incomes,
                this.livingExpenses,
                this.transportExpenses,
                this.insuranceExpenses,
                this.childrenExpenses,
                this.necessitiesExpenses,
                this.otherExpenses
            ];
        }
    },
    methods: {
        updateIncomeOrExpense(incomeOrExpense) {
            this.incomeOrExpense = incomeOrExpense;
        }
    }
};
</script>