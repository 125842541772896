export default {
    data() {
        const filter = {
            slider: {
                loanAmount: {
                    value: 0,
                    options: {
                        maxAmount: 600000,
                        increment: '1000',
                    },
                    filterFunction: (model, loanAmount) => model.maxAmount >= loanAmount && model.minAmount <= loanAmount
                }
            },
            checkBoxes: {
                title: this.$translate('filter_button_text', 'Filtrera'),
                categories: {}
            }
        };
        if (this.slice.slice_type !== 'lender_list_all') {
            filter.checkBoxes.categories = {
                useOriginalList: {
                    title: this.getCheckboxUseOriginalListText(),
                    active: true,
                }
            };
        }
        this.setFilterDataFromLocale(filter);
        return {
            userFilter: filter
        };
    },
    computed: {
        activeFilters() {
            return Object.values(this.userFilter.checkBoxes.categories).filter(category => category.active);
        },
        activeFilterFunctions() {
            let filters = this.activeFilters;

            if (this.userFilter.slider.loanAmount.value > 0) {
                filters = [...filters, this.userFilter.slider.loanAmount]; 
            }
            return filters
                .filter(category => category.filterFunction !== undefined)
                .map(filter => filter.filterFunction);
        },
        useOriginalList() {
            return this.userFilter.checkBoxes.categories.useOriginalList?.active || 
                this.slice.slice_type === 'lender_list_all';
        },
        allPartnerLenders() {
            const partnerLenders = Object.values(this.$store.state.lenders)
                .filter(lender => lender.data.redirect_enabled);

            return this.$getOrderedList(partnerLenders, this.$store.state.lenders, this.$models.Loan);
        },
        listToFilter() {
            if (this.useOriginalList) {
                return this.list.map(lender => {
                    if (! lender.data) {
                        return this.$store.state.lenders[lender.id];
                    }
                    return lender;
                });
            }
            return this.allPartnerLenders;
        },
        filteredList() {
            let list = this.listToFilter; 
    
            list = list.filter(lender => {
                const model = new this.$models.Loan(lender);

                return ! this.activeFilterFunctions.some(filterFunction => {
                    return filterFunction(model, this.userFilter.slider.loanAmount.value) === false;
                });
            });

            return list;
        },
    },
    methods: {
        getCheckboxUseOriginalListText() {
            if (this.$validateText(this.slice.primary.filter_use_original_list_text)) {
                return this.slice.primary.filter_use_original_list_text;
            }
            return this.$translate('filter_use_original_list', 'Rekommenderade');
        },
        setFilterDataFromLocale(filter) {
            if (this.$isSweden()) {
                return this.setFilterDataSweden(filter);
            }
            if (this.$isNorway()) {
                return this.setFilterDataNorway(filter);
            }
            if (this.$isSpain()) {
                return this.setFilterDataSpain(filter);
            }
            if (this.$isDenmark()) {
                return this.setFilterDataDenmark(filter);
            }
        },
        setFilterDataSpain(filter) {
            const categories = {
                acceptsASNEF: {
                    title: this.$translate('filter_accepts_asnef', 'With ASNEF'),
                    active: false,
                    filterFunction: (model) => model.acceptsASNEF
                },
                acceptsNoSalary: {
                    title: this.$translate('filter_accepts_no_salary', 'Utan inkomstkrav'),
                    active: false,
                    filterFunction: (model) => model.acceptsNoSalary
                },
                minAgeUnder21: {
                    title: this.$translate('filter_min_age_under_21', 'Åldersgräns under 21'),
                    active: false,
                    filterFunction: (model) => model.minAge <= 21
                },
                isBroker: {
                    title: this.$translate('filter_is_broker', 'Broker'),
                    active: false,
                    filterFunction: (model) => model.isBroker
                },
                isLender: {
                    title: this.$translate('filter_is_lender', 'Lånegivare'),
                    active: false,
                    filterFunction: (model) => !model.isBroker
                }
            };

            filter.checkBoxes.categories = {...filter.checkBoxes.categories, ...categories};

            filter.slider.loanAmount.options.maxAmount = 60000;
            filter.slider.loanAmount.options.increment = '50';
        },
        setFilterDataSweden(filter) {
            const categories = {
                withoutUc: {
                    title: this.$translate('filter_without_uc', 'Utan UC'),
                    active: false,
                    filterFunction: (model) => !model.takesUC
                },
                creditSafe: {
                    title: this.$translate('filter_creditsafe', 'Creditsafe'),
                    active: false,
                    filterFunction: (model) => model.creditCheck === 'creditsafe'
                },
                bisnode: {
                    title: this.$translate('filter_bisnode', 'Bisnode'),
                    active: false,
                    filterFunction: (model) => model.creditCheck === 'bisnode'
                },
                acceptsPaymentRemarks: {
                    title: this.$translate('filter_accepts_payment_remarks', 'Accepterar betalningsanmärkningar'),
                    active: false,
                    filterFunction: (model) => model.acceptsPaymentRemarks
                },
                hasDirectPayment: {
                    title: this.$translate('filter_direct_pay_out', 'Direktutbetalning'),
                    active: false,
                    filterFunction: (model) => model.hasDirectPayments
                },
                withoutInterest: {
                    title: this.$translate('filter_without_interest', 'Räntefritt'),
                    active: false,
                    filterFunction: (model) => model.hasFirstTimeOffer
                },
            };
            filter.checkBoxes.categories = {...filter.checkBoxes.categories, ...categories};
        },
        setFilterDataNorway(filter) {
            const categories = {
                acceptsPaymentRemarks: {
                    title: this.$translate('filter_accepts_payment_remarks', 'Accepterar betalningsanmärkningar'),
                    active: false,
                    filterFunction: (model) => model.acceptsPaymentRemarks
                },
                minAgeUnder21: {
                    title: this.$translate('filter_min_age_under_21', 'Åldersgräns under 21'),
                    active: false,
                    filterFunction: (model) => model.minAge <= 21
                },
            };

            filter.checkBoxes.categories = {...filter.checkBoxes.categories, ...categories};
        },
        setFilterDataDenmark(filter) {
            const categories = {
                minAgeUnder18: {
                    title: this.$translate('filter_min_age_under_18', 'Åldersgräns under 18'),
                    active: false,
                    filterFunction: (model) => model.minAge <= 18
                },
                hasDirectPayment: {
                    title: this.$translate('filter_direct_pay_out', 'Direktutbetalning'),
                    active: false,
                    filterFunction: (model) => model.hasDirectPayments
                },
                withoutInterest: {
                    title: this.$translate('filter_without_interest', 'Räntefritt'),
                    active: false,
                    filterFunction: (model) => model.hasFirstTimeOffer
                },
            };

            filter.checkBoxes.categories = {...filter.checkBoxes.categories, ...categories};
        },
    },
};