<template>
    <div class="position-relative content-space-1">
        <div class="container p-0 p-md-3 zi-99">
            <div class="p-4 pt-lg-10 ps-md-7 bg-white shadow-lg"> 
                <div class="position-relative overflow-hidden">
                    <div class="col-md-6 content-space-md-1">
                        <h2 class="fs-1">
                            <i class="bi bi-dash-lg text-primary fs-2" />
                            {{ $prismic.asText(slice.primary.h2) }}
                        </h2>
                        <p class="mb-7 h2 rich-text" v-html="$prismic.asHtml(slice.primary.text)" />
                        <nuxt-link 
                            class="text-primary fs-4 fw-bold" 
                            :to="$prismic.linkResolver(slice.primary.link)"
                        >
                            {{ slice.primary.button_text }} 
                            <i class="bi-chevron-right small ms-1" />
                        </nuxt-link>
                    </div>
                    <div class="d-none d-md-block">
                        <div class="position-absolute top-0 start-50">
                            <figure class="device-browser-frame rotated-3d-left">
                                <prismic-image class="device-browser-img" :img="slice.primary.img" />
                            </figure>
                        </div>
                        <div class="position-absolute top-0 start-50 mt-10 ms-5">
                            <figure class="device-browser-frame rotated-3d-left">
                                <prismic-image class="device-browser-img" :img="slice.primary.img" />
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="slice.primary.show_blue_stripe" class="position-absolute d-none d-sm-block start-0 bottom-0 bg-primary w-100 h-25" style="z-index: -99" />
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }        
    },
};
</script>
