<template>
    <div v-if="isValidLender" class="container content-space-1">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <div class="d-flex flex-column">
                    <div 
                        v-if="$validateText(slice.primary.header)"
                        class="d-flex flex-column align-items-center gap-3 mb-2 mb-md-3"
                    >
                        <nuxt-link :to="$prismic.linkResolver(lender)">
                            <prismic-image
                                :img="lender.data.logo"
                                :alt="lender.data.title"
                                w="110"
                                class="wpx-100 wpx-md-unset"
                            />
                        </nuxt-link>
                        <div
                            class="rich-text text-center" 
                            v-html="$prismic.asHtml(slice.primary.header)
                                .replace('{title}', lender.data.title)"    
                        />
                    </div>
                    <div class="d-flex flex-column text-center mb-4 mb-md-5">
                        <div>
                            <i 
                                v-for="(starIndex) in 5" 
                                :key="starIndex"
                                class="bi"
                                :class="getStarClass(starIndex, model.trustpilotData.score)"
                            />
                        </div>
                        <span class="fw-bold lh-1">
                            Trustpilot ({{ model.trustpilotData.score }})
                        </span>
                    </div>
                    <div 
                        v-if="$validateText(slice.primary.teaser)"   
                        class="rich-text mb-4 text-dark" 
                        v-html="teaser"    
                    />
                    <div 
                        v-if="$validateText(slice.primary.review_text)"
                        class="rich-text"
                        :class="fadeReview ? 'text-fade max-hpx-110 overflow-hidden position-relative cursor-pointer text-body' : 'text-dark'"
                        @click="fadeReview = false"
                        v-html="$prismic.asHtml(slice.primary.review_text)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            fadeReview: true
        };
    },
    computed: {
        lender() {
            return this.$store.state.lenders[this.slice.primary.lender?.id];
        },
        model() {
            return new this.$models.Loan(this.lender, this.$store);
        },
        isValidLender() {
            return this.lender && this.model.trustpilotData;
        },
        teaser() {
            return this.$prismic.asHtml(this.slice.primary.teaser)
                .replaceAll('{title}', this.lender.data.title)
                .replaceAll('{avgScore}', this.model.trustpilotData.score)
                .replaceAll('{count}', this.model.trustpilotData.ratingCount);
        }
    },
    methods: {
        getStarClass(starIndex, score) {
            if (starIndex <= Math.floor(score)) {
                return 'bi-star-fill text-success';
            }

            if (starIndex <= Math.round(score)) {
                return 'bi-star-half text-success';
            }

            return 'bi-star';
        }
    }
};
</script>