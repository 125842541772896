<template>
    <div class="container content-space-t-1 d-flex justify-content-center">
        <div class="w-100 w-lg-75">
            <div class="card mb-5">
                <div class="card-header border-bottom">
                    <h6 class="card-title">{{ $translate('navigate_on_page', 'Navigera på sidan') }}</h6>
                </div>
                <div class="card-body">
                    <ul class="list-unstyled mb-0 fs-4">
                        <li v-if="$isSpain()"><a v-scroll-to="'#quickfacts'" href="#quickfacts">¿Cuánto dinero presta {{ document.data.title }}?</a></li>
                        <li><a v-scroll-to="'#pros'" href="#pros">{{ $translate('pros_and_cons', 'För- och nackdelar') }}</a></li>
                        <li><a v-scroll-to="'#requirements'" href="#requirements">{{ $translate('loan_requirements', 'Krav på lånet') }}</a></li>
                        <li><a v-scroll-to="'#review'" href="#review">{{ $translate('review', 'Recension') }}</a></li>
                        <li v-if="$isSweden()"><a v-scroll-to="'#example'" href="#example">{{ $translate('calculation_example', 'Räkneexempel') }}</a></li>
                        <li><a v-scroll-to="'#openinghours'" href="#openinghours">{{ $translate('opening_hours', 'Öppettider') }}</a></li>
                        <li><a v-scroll-to="'#company'" href="#company">{{ $translate('company_facts', 'Bolagsfakta') }}</a></li>
                        <li><a v-scroll-to="'#user-reviews'" href="#user-reviews">{{ $translate('user_ratings', 'Användarrecensioner') }}</a></li>
                    </ul>
                </div>
            </div>

            <!-- NOTE: hard coded test for Spain (NOT GOOD!!! But we wanted to try something fast) 
                    TODO: make this scalable, and more markets. -->
            <div v-if="$isSpain()" id="quickfacts" class="border border-2 border-primary bg-light p-5 rounded mb-4 rich-text">
                <h3>¿Cuánto dinero presta {{ document.data.title }}?</h3>
                <p>{{ document.data.title }} presta entre {{ model.amountString }}.</p>
            </div>

            <div id="pros" class="info-box bg-light p-5 rounded">
                <h2>{{ $translate('pros', 'Fördelar') }}</h2>
                <ul>
                    <li v-for="(iterator, index) in document.data.pros" :key="`pro${index}`">{{ iterator.pro }}</li>
                </ul>
                <h2>{{ $translate('cons', 'Nackdelar') }}</h2>
                <ul>
                    <li v-for="(iterator, index) in document.data.cons" :key="`con${index}`">{{ iterator.con }}</li>
                </ul>
            </div>
            
            <div id="requirements" class="info-box bg-secondary text-light p-5 rounded">
                <h2 class="text-white">{{ $translate('loan_requirement', 'Krav för att få ett lån hos {title}').replace('{title}', document.data.title) }}</h2>
                <ul>
                    <li v-for="(iterator, index) in document.data.requirements" :key="`req${index}`">{{ iterator.requirement }}</li>
                </ul>
                <div v-if="document.data.redirect_enabled" class="d-flex justify-content-start align-items-center">
                    <out-link :document="document" class="btn btn-light text-dark fw-bold" placement="requirements" />
                    <span v-if="$isNorway()" class="ms-5 fs-6">Annonselenke</span>
                </div>
            </div>

            <div 
                id="review" 
                class="rich-text mb-4" 
                v-html="$prismic.asHtml(document.data.content)" 
            />

            <interest-example v-if="$isSweden() && document.type === 'lender'" id="example" :model="model" :document="document" />

            <div v-if="document.data.redirect_enabled" class="bg-dark my-5 px-5 rounded" style="background-image: url(@/assets/svg/components/wave-pattern-light.svg);">
                <div class="container content-space-1">
                    <div class="row justify-content-lg-between align-items-lg-center">
                        <div class="col-md mb-5 mb-md-0">
                            <span class="h3 text-white mb-0">{{ $translate('start_your_application', 'Kom igång med din ansökan hos {title}').replace('{title}', document.data.title) }}.</span>
                        </div>

                        <div v-if="document.data.redirect_enabled" class="col-md-auto">
                            <div class="d-grid d-flex flex-column">
                                <out-link :document="document" class="btn btn-light btn-transition fw-bold" placement="cta" />
                                <span v-if="$isNorway()" class="text-light fs-6 text-center">Annonselenke</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="showComparision" class="row my-5">
                <div class="col card">
                    <div class="card-header ">
                        <h3 class="card-title">{{ $translate('comparision', 'Jämförelse') }}</h3>
                    </div>
                    <div class="table-responsive">
                        <lenders-comparision-table
                            :ids="[document.id, ...lenderIdsToCompare]"
                            :table-type="document.data.comparision_type"
                            class="card-body"
                        />
                    </div>
                </div>
            </div>

            <opening-hours id="openinghours" :model="model" />
            <company-facts id="company" :model="model" />

            <div v-if="model.complianceExample" class="info-box bg-light p-5 rounded">
                <h5>{{ $translate('example_interest', 'Exempelränta') }}</h5>
                <p class="mb-0">{{ model.complianceExample }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import OpeningHours from '@/components/models/OpeningHours.vue';
import CompanyFacts from '@/components/models/CompanyFacts.vue';
import InterestExample from '@/components/pages/lender/InterestExample.vue';
import LendersComparisionTable from '@/components/LendersComparisionTable.vue';
export default {
    components: {
        OpeningHours,
        CompanyFacts,
        InterestExample,
        LendersComparisionTable
    },
    props: {
        document: {
            type: Object,
            required: true
        },
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        showComparision() {
            return this.lenderIdsToCompare.length > 0;
        },
        lenderIdsToCompare() {

            if (! this.document.data.comparision) {
                return [];
            }

            return this.document.data.comparision
                .filter(iterator => iterator.lender.id)
                .map(iterator => iterator.lender.id);
        }
    }
};
</script>