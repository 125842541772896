<template>
    <div class="card-body py-2 d-flex align-items-center justify-content-center" style="min-height: 120px">
        <prismic-image :img="image" :w="width" />
    </div>
</template> 
<script>
export default {
    props: {
        image: {
            type: Object,
            required: true
        },
        width: {
            type: String,
            required: true
        }
    }
};
</script>