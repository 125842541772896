<template>
    <div class="d-flex align-items-baseline flex-wrap gap-2 justify-content-between text-dark py-1 border-bottom">
        <div class="font-weight-bold text-start">{{ title }}</div>
        <div class="flex-grow-1 text-end fw-bold">
            <span v-html="value" />
            <i v-if="tooltip" v-tooltip="tooltip" class="bi bi-info-circle-fill ms-1 text-info" />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        value: {
            type: [Number, String],
            required: true
        },
        tooltip: {
            type: String,
            required: false,
            default: null
        }
    },
};
</script>