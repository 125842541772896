<template>
    <div class="container content-space-1">
        <div 
            v-if="$validateText(slice.primary.header)"
            class="align-self-start"
            v-html="$prismic.asHtml(slice.primary.header)"
        />
        <div class="row">
            <input-form 
                :is-fixed-amount="isFixedAmount"
                :amount="amount" 
                :duration="duration" 
                :interest-rate="interestRate" 
                :arrangement-fee="arrangementFee" 
                :invoice-fee="invoiceFee" 
                :min-amount="minAmount"
                :max-amount="maxAmount"
                :min-duration="minDuration"
                :max-duration="maxDuration"
                :min-interest="minInterest"
                :max-interest="maxInterest"
                @changed="updateValues" 
            />
            <overview 
                :amount="amount" 
                :duration="duration" 
                :interest-rate="interestRate" 
                :setup-fee="arrangementFee" 
                :invoice-fee="invoiceFee" 
            />
        </div>
        <div class="row mt-5">
            <details-table 
                :amount="amount" 
                :duration="duration" 
                :interest-rate="interestRate" 
                :arrangement-fee="arrangementFee" 
                :invoice-fee="invoiceFee" 
            />
        </div>
    </div>
</template>
<script>
import InputForm from '@/components/calculator/InputForm.vue';
import Overview from '@/components/calculator/Overview.vue';
import DetailsTable from '@/components/calculator/DetailsTable.vue';
export default {
    components: {
        InputForm,
        Overview,
        DetailsTable
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            title: 'Calculator',
            isFixedAmount: this.slice.primary.is_fixed_amount,
            amount: this.slice.primary.initial_amount || 10000,
            duration: this.slice.primary.initial_duration || 10,
            interestRate: this.slice.primary.initial_interest || 12,
            arrangementFee: 0,
            invoiceFee: 0,
            minAmount: this.slice.primary.min_amount || (this.$isSpain() ? 50 : 1000),
            maxAmount: this.slice.primary.max_amount || (this.$isSpain() ? 60000 : 600000),
            minDuration: this.slice.primary.min_duration || 1,
            maxDuration: this.slice.primary.max_duration || 180,
            minInterest: this.slice.primary.min_interest || 0,
            maxInterest: this.slice.primary.max_interest || 50
        };
    },
    methods: {
        updateValues(allValues) {
            this.amount = allValues.amount;
            this.duration = allValues.duration;
            this.interestRate = allValues.interestRate;
            this.arrangementFee = allValues.arrangementFee;
            this.invoiceFee = allValues.invoiceFee;
        }
    }
};
</script>