<template>
    <div class="position-relative bg-img-start" style="background-image: url(/card-11.svg);">
        <div class="container content-space-t-1 content-space-t-lg-2 content-space-b-2 content-space-b-lg-3 position-relative zi-2">
            <div class="row justify-content-lg-between align-items-md-center">
                <div class="col-md-6 col-lg-7 mb-10 mb-md-0">
                    <div class="mb-5">
                        <h1 class="display-4 mb-4">{{ $prismic.asText(slice.primary.h1) }}</h1>
                        <p class="lead">{{ $prismic.asText(slice.primary.p) }}</p>
                    </div>
                    <div class="d-flex flex-wrap gap-2 mb-8">
                        <nuxt-link class="btn btn-primary btn-transition me-2 fw-bold" :to="$prismic.linkResolver(slice.primary.link_item)">
                            {{ slice.primary.link_text }} <i class="bi-chevron-right small ms-2" />
                        </nuxt-link>

                        <nuxt-link 
                            v-if="slice.primary.secondary_link_item.id"
                            class="btn btn-outline-primary btn-transition" 
                            :to="$prismic.linkResolver(slice.primary.secondary_link_item)"
                        >
                            <i class="bi-info-circle me-1" /> {{ slice.primary.secondary_link_text }}
                        </nuxt-link>
                    </div>
                </div>

                <div class="col-md-6 col-lg-5 d-none d-md-block">
                    <div class="row justify-content-end">
                        <div class="col-3 mb-4">
                            <div class="d-block avatar avatar-lg rounded-circle shadow-sm p-3 mt-n3 ms-5 aos-init aos-animate" data-aos="fade-up">
                                <img class="avatar-img" src="/lendo.png" alt="Image Description">
                            </div>
                        </div>
                        <div class="col-4 mb-4">
                            <div class="d-block avatar avatar-xl rounded-circle shadow-sm p-4 mx-auto mt-5 aos-init aos-animate" data-aos="fade-up" data-aos-delay="50">
                                <img class="avatar-img" src="/sambla.png" alt="Image Description">
                            </div>
                        </div>
                        <div class="col-4 mb-4">
                            <div class="d-block avatar avatar-xl rounded-circle shadow-sm p-4 ms-auto aos-init aos-animate" data-aos="fade-up" data-aos-delay="150">
                                <img class="avatar-img" src="/advisa.png" alt="Image Description">
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-3 offset-1 my-4">
                            <div class="d-block avatar avatar-xl rounded-circle shadow-sm p-4 ms-auto aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
                                <img class="avatar-img" src="/zmarta.jpeg" alt="Image Description">
                            </div>
                        </div>
                        <div class="col-3 offset-2 my-4">
                            <div class="d-block avatar avatar-xl rounded-circle shadow-sm p-4 ms-auto aos-init aos-animate" data-aos="fade-up" data-aos-delay="250">
                                <img class="avatar-img" src="/axofinans.png" alt="Image Description">
                            </div>
                        </div>
                    </div>

                    <div class="row d-none d-md-flex">
                        <div class="col-6">
                            <div class="d-block avatar avatar-lg rounded-circle shadow-sm p-3 ms-auto aos-init aos-animate" data-aos="fade-up" data-aos-delay="300">
                                <img class="avatar-img" src="/loanstep.png" alt="Image Description">
                            </div>
                        </div>
                        <div class="col-6 mt-6">
                            <div class="d-block avatar avatar-xl rounded-circle shadow-sm p-4 ms-auto aos-init aos-animate" data-aos="fade-up" data-aos-delay="350">
                                <img class="avatar-img" src="/brixo.png" alt="Image Description">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape shape-bottom zi-1">
            <svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 500H3000V0L0 500Z" fill="#fff" />
            </svg>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
};
</script>