<template>
    <div class="container mb-5">
        <div class="row justify-content-center">
            <div class="col mx-3 col-lg-9 text-dark">
                <div class="card mb-5">
                    <div class="card-header border-bottom">
                        <h6 class="card-title">{{ $translate('navigation', 'Navigering') }}</h6>
                    </div>
                    <div class="card-body">
                        <ul class="list-unstyled mb-0 fs-4">
                            <li v-for="child in children" :key="child.id">
                                <nuxt-link :to="$prismic.linkResolver(child)">
                                    {{ child.title }}
                                </nuxt-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        children() {
            const documents = require('@/documents.json');
            let path = this.$route.path;
            path += path.endsWith('/') ? '' : '/';
            
            return Object.values(documents)
                .filter(
                    d => d.slug.includes(path) && 
                    d.slug !== path && 
                    d.prismicLocale === process.env.PRISMIC_LOCALE
                )
                .sort((a, b) => 
                    a.title.localeCompare(b.title)
                );
        }
    }
};
</script>