<template>
    <div class="container">
        <div 
            v-for="(item, index) in slice.items" 
            :key="index" 
            class="row justify-content-lg-between align-items-md-center content-space-1"
        >
            <div :class="(index % 2 == 0) ? 'order-md-2 order-1' : 'order-md-1 order-2'" class="col-md-6 col-lg-5">
                <div
                    v-if="$validateText(item.content_title)"
                    class="mb-4"
                    v-html="$prismic.asHtml(item.content_title)"
                />
                <div class="text-dark rich-text" v-html="$prismic.asHtml(item.content_text)" />
            </div>
            <div :class="(index % 1 == 0) ? 'order-md-1 order-2' : 'order-md-2 order-1'" class="col-md-6 d-flex justify-content-center mt-4 mt-md-0">
                <prismic-image 
                    class="bg-img-center rounded-2 max-width-100" 
                    :img="item.content_image" 
                    :w="imageSize(item.image_keep_original_size)" 
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    methods: {
        imageSize(keepOriginalSize) {
            return keepOriginalSize ? 'auto' : '550';
        }
    }
};
</script>