<template>
    <div class="container content-space-1">
        <div class="row justify-content-center">
            <div :class="{'col col-lg-9': isContentWidth}">
                <loan-wizard-intro-component 
                    :header="slice.primary.header" 
                    :teaser="slice.primary.teaser"
                    :two-columns="!isContentWidth"
                />
            </div>
        </div>
    </div>
</template>

<script>
import LoanWizardIntroComponent from '@/components/LoanWizardIntro.vue';
export default {
    components: {
        LoanWizardIntroComponent
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        isContentWidth() {
            return this.slice.primary.slice_width !== 'container';
        }
    }
};
</script>