<template>
    <div class="container content-space-1 content-space-md-b-2 ">
        <div class="row gx-3">
            <div class="col mb-3">
                <prismic-image :class="imageClass" h="240" style="object-fit: cover;" :img="slice.primary.top_left" />
            </div>
            <div class="col-3 d-none d-md-block mb-3">
                <prismic-image :class="imageClass" h="240" style="object-fit: cover;" :img="slice.primary.top_center" />
            </div>
            <div class="col mb-3">
                <prismic-image :class="imageClass" h="240" style="object-fit: cover;" :img="slice.primary.top_right" />
            </div>
        </div>
        <div class="row gx-3">
            <div class="col mb-3 mb-md-0">
                <prismic-image :class="imageClass" h="240" style="object-fit: cover;" :img="slice.primary.bottom_left" />
            </div>
            <div class="col d-none d-md-block mb-3 mb-md-0">
                <prismic-image :class="imageClass" h="240" style="object-fit: cover;" :img="slice.primary.bottom_center" />
            </div>
            <div class="col">
                <prismic-image :class="imageClass" h="240" style="object-fit: cover;" :img="slice.primary.bottom_right" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        imageClass() {
            return 'bg-img-start w-100';
        }
    }
};
</script>