<script>
import Slice from './Slice.vue';
import LenderList from '@/components/templates/LenderList.vue';
import InsertLenders from '@/mixins/InsertLenders.js'; 

export default {
    extends: LenderList,
    mixins: [
        Slice,
        InsertLenders,
    ],
    computed: {
        lenders() {

            if (this.slice.primary.list.id) {
                return this.$store.state.lists[this.slice.primary.list.id].data.items
                    .map(iterator => iterator.item)
                    .filter(item => item.id);
            }
            
            return Object
                .values(this.slice.items)
                .map(iterator => iterator.lender)
                .filter(lender => lender.id);
        },
        h2() {
            return this.$prismic.asText(this.slice.primary.h2);
        },
        ordered() {
            return this.$getOrderedList(this.lenders, this.$store.state.lenders, this.$models.Loan);
        },
        list() {

            if (this.slice.primary.sorting === 'manual') {
                return this.insertLenders(this.lenders);
            }

            return this.insertLenders(this.ordered);
        }
    }
};
</script>