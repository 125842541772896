<template>
    <div class="container no-gutter">
        <div class="row justify-content-center px-1 px-lg-0">
            <div class="col col-lg-9">
                <div 
                    class="p-4 rounded-4 border border-2 shadow position-relative text-dark content-alert mb-4" 
                    :class="borderAndIconColor"
                >
                    <i 
                        class="bi fs-2 position-absolute top-0 start-0 translate-middle" 
                        :class="[icon, borderAndIconColor]"
                    /> 
                    <div 
                        class="content rich-text" 
                        v-html="$prismic.asHtml(slice.primary.content)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        borderAndIconColor() {
            return {
                'primary': 'border-primary text-primary',
                'secondary': 'border-secondary text-secondary',
                'dark': 'border-dark text-dark',
                'light': 'border-light text-light',
                'success': 'border-success text-success',
                'danger': 'border-danger text-danger',
                'warning': 'border-warning text-warning',
                'info': 'border-info text-info'
            }[this.slice.primary.style] || 'border-primary text-primary';
        },
        icon() {
            return {
                'success': 'bi-check-circle-fill',
                'danger': 'bi-exclamation-circle-fill',
                'warning': 'bi-exclamation-circle-fill',
            }[this.slice.primary.style] || 'bi bi-info-circle-fill';
        }
    }
};
</script>
<style lang="scss" scoped>
    ::v-deep {
        .content h2, 
        .content h3, 
        .content h4, 
        .content h5, 
        .content h6 {
            color: inherit !important;
            margin-bottom: 0.75rem;
            margin-top: 0.5rem;
        }

        div.alert-primary div.content a,
        div.alert-danger div.content a,
        div.alert-info div.content a,
        div.alert-secondary div.content a {
            color: var(--bs-white);
            text-decoration: underline;
        }
        div.alert-primary div.content a:hover,
        div.alert-danger div.content a:hover,
        div.alert-info div.content a:hover,
        div.alert-secondary div.content a:hover {
            color: var(--bs-white);
            text-decoration: none;
        }

        div.alert-success div.content a,
        div.alert-light div.content a,
        div.alert-white div.content a,
        div.alert-dark div.content a {
            color: var(--bs-link-color);
        }
        div.alert-success div.content a:hover, 
        div.alert-light div.content a:hover,
        div.alert-white div.content a:hover,
        div.alert-dark div.content a:hover {
            color: var(--bs-link-hover-color);
        }
    }
</style>