<template>
    <div class="container content-space-t-2">
        <div class="row">
            <div 
                v-if="$validateText(slice.primary.content)" 
                class="col-lg-9 text-dark rich-text mb-4" 
                v-html="$prismic.asHtml(slice.primary.content)" 
            />
            <div v-if="children.length > 0" class="col-lg-3">
                <h5 class="fw-normal text-uppercase">{{ $translate('navigation', 'Navigering') }}</h5>
                <ul class="list-unstyled">
                    <li v-for="child in children" :key="child.id" class="my-2">
                        <nuxt-link :to="$prismic.linkResolver(child.id)">{{ child.title }}</nuxt-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        children() {
            const documents = require('@/documents.json');
            let path = this.$route.path;
            path += path.endsWith('/') ? '' : '/';
            
            const children = Object.values(documents)
                .filter(d => d.slug.includes(path) && 
                    d.slug !== path && 
                    d.prismicLocale === process.env.PRISMIC_LOCALE
                );

            return children;
        }
    }
};
</script>