<template>
    <div class="container content-space-t-1">
        <h2 
            v-if="$validateText(slice.primary.h2)"
            class="mb-5"
        >
            {{ slice.primary.h2 }}
        </h2>
        <guide-search 
            @searchQuery="updateSearchQuery"
        />
        <guide-categories 
            :guide-categories="guideCategories"
            :active-category="activeCategory"
            @categoryClicked="updateCategoryFilter"
        />
        <guide-item 
            v-for="guide in filteredGuides"
            :key="guide.id"
            :guide="guide"
            class="d-flex border-2 border-bottom border-soft-secondary pb-md-5 mb-md-5 mb-3 pb-3"
        />
        <div v-if="filteredGuides.length === 0">
            {{ $translate('guide_search_no_results', 'Din sökning hittade inga guider.') }}
        </div>
    </div>
</template>

<script>
import GuideItem from '@/components/guides/GuideItem.vue';
import GuideSearch from '@/components/guides/GuideSearch.vue';
import GuideCategories from '@/components/guides/GuideCategories.vue';
import { queryAll } from '@swegaming-ab/nuxtjs-helpers';
export default {
    components: { 
        GuideItem,
        GuideSearch,
        GuideCategories
    },
    props: {
        slice: {
            type: Object,
            required: true
        },
        document: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            allGuides: [],
            guideCategories: []
        };
    },
    async fetch() {
        const predicatesGuides = [
            this.$prismic.predicates.at('document.type', 'page'),
            this.$prismic.predicates.at('my.page.parent', this.document.id),
        ];
        const predicatesCategories = [
            this.$prismic.predicates.at('document.type', 'page_category'),
        ];
        this.allGuides = (await queryAll(
            this.$prismic.api, 
            predicatesGuides,
            { 
                orderings: '[document.last_publication_date desc]',
                lang: process.env.PRISMIC_LOCALE,
                fetchLinks: ['page_category.category']
            }
        ));
        this.guideCategories = (await queryAll(
            this.$prismic.api, 
            predicatesCategories,
            { lang: process.env.PRISMIC_LOCALE }
        ));

    },
    fetchKey() {
        return `show-guides-${this.slice.id}`;
    },
    computed: {
        searchQuery() {
            return this.$route.query.searchGuides ?? '';
        },
        activeCategory() {
            return this.$route.query.category ?? '';
        },
        filteredGuides() {
            let filteredGuides = this.allGuides;
            if (this.searchQuery) {
                filteredGuides = filteredGuides.filter(guide => {
                    return guide.data.title.toLowerCase().includes(this.searchQuery.toLowerCase());
                });
            }
            if (this.activeCategory) {
                filteredGuides = filteredGuides.filter(guide => {
                    return this.activeCategory === guide.data.category?.data?.category;
                });
            }
            return filteredGuides;
        }
    },
    methods: {
        updateSearchQuery(searchQuery) {
            let query = null;
            if (searchQuery) {
                query = { 
                    ...this.$route.query, 
                    'searchGuides': searchQuery
                };
            }
            else if (this.activeCategory) {
                query = {  
                    'category': this.activeCategory
                };
            }
            this.$router.replace({ query: query });
        },
        updateCategoryFilter(category) {
            let query = null;
            if (category.length) {
                query = { 
                    ...this.$route.query, 
                    'category': category
                };
            }
            else if (this.searchQuery) {
                query = {  
                    'searchGuides': this.searchQuery
                };
            }
            this.$router.replace({ query: query });
        }
    }
};
</script>
