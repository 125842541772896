<template>
    <div class="container mb-2">
        <div class="row justify-content-center">
            <table-of-contents-body
                class="col col-lg-9"
                :slices="slices" 
            /> 
        </div>
    </div>
</template>

<script>
import TableOfContentsBody from '@/components/TableOfContentsBody.vue';
export default {
    components: {
        TableOfContentsBody
    },
    props: {
        slices: {
            type: Array,
            required: true
        }
    },
};
</script>
