<template>
    <div class="rounded shadow">
        <div class="d-flex flex-wrap align-items-center justify-content-between p-3 gap-1">
            <nuxt-link :to="$prismic.linkResolver(lender)" class="d-block max-wpx-150">
                <PrismicImage
                    :img="lender.data.logo"
                    :alt="lender.data.title"
                    w="150"
                    class="w-100 object-fit-contain"
                />
            </nuxt-link>
            <out-link 
                :document="lender" 
                class="btn btn-light fw-bold" 
                :text="$translate('cta_to_application', 'Till ansökan')" 
            />
        </div>
        <div class="border-top border-bottom overflow-auto">
            <table class="table m-0 table-nowrap table-borderless table-striped text-center">
                <thead>
                    <tr class="fw-bold">
                        <th>{{ $translate('loan_amount', 'Belopp') }}</th>
                        <th>{{ $translate('ce_amortization_period', 'Amorteringstid') }}</th>
                        <th>{{ $translate('ce_monthly_payment', 'Månadsbetalning') }}</th>
                        <th>{{ $translate('cost_of_interest', 'Räntekostnad') }}</th>
                        <th v-if="!$isSpain()">{{ $translate('ce_fees', 'Avgifter') }}</th>
                        <th v-if="$isSweden() || $isSpain()">{{ $translate('ce_payback', 'Återbetala') }}</th>
                        <th v-if="$isSweden()">{{ $translate('ce_after_interest_deduction', 'Efter ränteavdrag') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr 
                        v-for="(period, index) in periodsData" 
                        :key="index"
                    >
                        <td class="text-dark">{{ $format.currency(amount, 0) }}</td>
                        <td class="text-dark">{{ period.amortization }}</td>
                        <td class="text-dark">{{ period.monthlyPayment }}</td>
                        <td class="text-dark">{{ period.interestCost }}</td>
                        <td v-if="!$isSpain()" class="text-dark">{{ period.fees }}</td>
                        <td v-if="$isSweden() || $isSpain()" class="text-dark">{{ period.payback }}</td>
                        <td v-if="$isSweden()" class="text-dark">{{ period.paybackAndInterestBack }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <p class="text-muted small text-center m-0 p-3">
            {{ tableCalculationExplanation }}
        </p>
    </div>
</template>

<script>
export default {
    props: {
        lender: {
            type: Object,
            required: true
        },
        amount: {
            type: Number,
            required: true,
        },
        periods: {
            type: Object,
            required: true
        },
        interestType: {
            type: String,
            required: true
        }
    },
    computed: {
        periodsData() {
            return {
                firstPeriodData: this.calculate(this.periods.firstPeriod),
                secondPeriodData: this.calculate(this.periods.secondPeriod),
                thirdPeriodData: this.calculate(this.periods.thirdPeriod),
            };
        },
        model() {
            return new this.$models.Loan(this.lender);
        },
        interest() {
            if (this.interestType === 'max_interest') {
                return this.$isSpain() ? this.model.maxEffectiveInterestPossible : this.model.maxInterestPossible;
            }
            return this.$isSpain() ? this.model.minEffectiveInterestPossible : this.model.minInterestPossible;
        },
        tableCalculationExplanation() {
            let explanation = '';
            if (this.interestType === 'max_interest') {
                explanation = this.$translate('ce_table_calculation_explanation_max_interest', 'I exemplet har vi utgått från högsta möjliga räntan hos {title} ({interest} %). Avikostnaden hos {title} är {adminFee} och uppläggningsavgiften är {setupFee}.')
                    .replace('{interest}', this.model.maxInterestPossible)
                    .replace('{effectiveInterest}', this.model.maxEffectiveInterestPossible);
            }
            else {
                explanation = this.$translate('ce_table_calculation_explanation', 'I exemplet har vi utgått från lägsta möjliga räntan hos {title} ({interest} %). Avikostnaden hos {title} är {adminFee} och uppläggningsavgiften är {setupFee}.')
                    .replace('{interest}', this.model.minInterestPossible)
                    .replace('{effectiveInterest}', this.model.minEffectiveInterestPossible);

            }            
            return explanation
                .replace(/{title}/g, this.lender.data.title)
                .replace('{adminFee}', this.model.administrationFeeString)
                .replace('{setupFee}', this.model.setupFeeString);
        }
    },
    methods: {
        calculate(months) {
            let fees = this.model.administrationFee * months + this.model.setupFee;

            if (this.$isSpain()) {
                fees = 0;
            }

            const interestCost = this.interestCost(this.amount, this.interest, months);
            const payback = this.amount + interestCost + fees;
            const paybackAndInterestBack = this.amount + interestCost * 0.7 + fees;
            const monthlyPayment = payback / months;

            return {
                amortization: this.formatMonths(months),
                fees: this.$format.currency(fees, 2),
                interestCost: this.$format.currency(interestCost, 2),
                payback: this.$format.currency(payback, 2),
                paybackAndInterestBack: this.$format.currency(paybackAndInterestBack, 2),
                monthlyPayment: this.$format.currency(monthlyPayment, 2)
            };
        },
        interestCost(amount, interestRate, duration) {
            const monthlyInterestRate = (interestRate/100) / 12;
            let currentCost = 0;
            for (let i = duration; i > 0; i--) {
                currentCost += amount * (i/duration) * monthlyInterestRate;
            }
            return currentCost;
        },
        formatMonths(months) {
            if (months >= 12) {
                return `${Number((months / 12).toFixed(1))} ${this.$translate('year_short', 'år')}`;
            } 
            return `${months} ${this.$translate('month_short', 'mån')}`; 
        }
    }
};
</script>