<template>
    <div>
        <h2 role="button" class="d-flex justify-content-between border-bottom pb-3 mb-3" @click="tableIsVisible = !tableIsVisible">
            {{ $translate(keyAndTranslation.key, keyAndTranslation.translation) }}
            <i v-if="tableIsVisible" class="bi bi-dash" />
            <i v-else class="bi bi-plus" />
        </h2>
        <div v-if="tableIsVisible" class="border-bottom mb-3 pb-4">
            <div v-for="(incomeOrExpense, index) in toIterate" :key="index" class="row ps-2 pe-2 mt-3 text-dark">
                <div class="col-12 col-md-6 d-flex align-items-center">
                    <p class="mb-0">{{ incomeOrExpense.title }}</p>
                </div>
                <div class="col d-flex align-items-center">
                    <input id="amount" v-model.number="incomeOrExpense.amount" type="number" placeholder="0" class="w-100 form-control text-end" @input="incomeOrExpense.amount = checkInput(incomeOrExpense.amount)">
                </div>
                <div class="col d-flex align-items-center">
                    <select id="frequency" v-model="incomeOrExpense.frequency" class="w-100 form-select">
                        <option value="monthly">{{ $translate('calculator_monthly', 'Månatlig') }}</option>
                        <option value="quarterly">{{ $translate('calculator_quarterly', 'Kvartalsvis') }}</option>
                        <option value="yearly">{{ $translate('calculator_yearly', 'Årlig') }}</option>
                    </select>
                </div>
            </div>
            <div class="row ps-2 pe-2 mt-6">
                <div class="col">
                    <p class="text-dark mb-0">{{ $translate('calculator_monthly', 'Månatlig') }}</p>
                </div>
                <div class="col">
                    <p v-if="currentIndex === 0" class="text-success text-end mb-0">{{ $format.currency($calculateMonthlyIncomeOrExpenses(toIterate)) }}</p>
                    <p v-else class="text-end mb-0">{{ $format.currency($calculateMonthlyIncomeOrExpenses(toIterate)) }}</p>
                </div>
            </div>
            <div class="row ps-2 pe-2 mt-3">
                <div class="col">
                    <p class="text-dark mb-0">{{ $translate('calculator_yearly', 'Årlig') }}</p>
                </div>
                <div class="col">
                    <p v-if="currentIndex === 0" class="text-success text-end mb-0">{{ $format.currency($calculateYearlyIncomeOrExpenses(toIterate)) }}</p>
                    <p v-else class="text-end mb-0">{{ $format.currency($calculateYearlyIncomeOrExpenses(toIterate)) }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        toIterate: {
            type: Array,
            required: true
        },
        currentIndex: {
            type: Number,
            required: true
        },
        keyAndTranslation: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            tableIsVisible: true
        };
    },
    computed: {
        values() {
            return {
                toIterate: this.newToIterate
            };
        }
    },
    watch: {
        values: {
            handler() {
                this.$emit('changed', this.newToIterate);
            },
            deep: true
        }
    },
    methods: {
        checkInput(input) {
            let number = Number(input);
            if (number > 9999999) {
                number = 9999999;
            }
            return number;
        }
    }
};
</script> 