<template>
    <!-- NOTE Very similar to /components/pages/card/Banner.vue -->
    <div class="container page-header">
        <div class="d-sm-flex content-space-t-1 content-space-t-lg-2 align-items-lg-center px-3 pb-3">
            <div class="flex-shrink-0 mb-2 mb-sm-0 me-4">
                <prismic-image :img="document.data.card_image" w="150" />
            </div>

            <div class="flex-grow-1">
                <div class="row">
                    <div class="col-md mb-md-0">
                        <h1 class="h2 mb-1">
                            {{ document.data.title }}
                            <img v-if="document.data.redirect_enabled" class="avatar avatar-xs" src="@/assets/svg/illustrations/top-vendor.svg">
                        </h1>

                        <!-- <div class="d-flex gap-1">
                            <span class="fw-semi-bold text-dark ms-1">4.5</span>
                            <span class="ms-1">5 användarbetyg</span>
                        </div> -->
                    </div>

                    <div class="col-md-auto align-self-md-end align-self-center">
                        <div class="d-grid d-sm-flex gap-2 ">
                            <div v-if="model.isArchived">{{ $translate('no_longer_active_similar_alternatives', '{title} är inte aktiva på marknaden längre. Här nedan finns några liknande alternativ.').replace('{title}', document.data.title) }}</div>
                            <out-link v-else-if="document.data.redirect_enabled" :document="document" class="btn btn-primary fw-bold" placement="banner" />
                            <div v-else>{{ $translate('no_active_cooperation', 'Vi har inget aktivt samarbete med {title}.').replace('{title}', document.data.title) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        },
        model: {
            type: Object,
            required: true
        }
    }
};
</script>