<template>
    <div v-if="lendersItems.length" class="container content-space-1">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <div 
                    v-if="$validateText(slice.primary.text)"
                    class="rich-text mb-5" 
                    v-html="$prismic.asHtml(slice.primary.text)"
                />
                <div class="table-responsive rounded shadow-sm">
                    <table class="table mb-0">
                        <thead>
                            <tr class="border-bottom border-soft-secondary">
                                <th>
                                    {{ $translate('lender', 'Långivare') }}
                                </th>
                                <th class="text-end">
                                    {{ $translate('trustpilot_score', 'TrustScore') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr 
                                v-for="(item, index) in lendersItems" 
                                :key="index"
                                :class="{'bg-light': index % 2 === 0}"    
                            >
                                <td class="align-middle">
                                    <div class="d-flex flex-column w-fit-content gap-1 align-items-center">
                                        <nuxt-link
                                            :to="$prismic.linkResolver(item.lender)"
                                        >
                                            <prismic-image 
                                                v-if="item.lender"
                                                :img="item.lender.data.logo"
                                                w="85"
                                            />
                                        </nuxt-link>
                                        <out-link
                                            :document="item.lender"
                                            :disable-icon="true"
                                            class="fs-7 btn btn-xs btn-primary py-1 fw-bold lh-1"
                                        />
                                    </div>
                                </td>
                                <td class="align-middle">
                                    <div class="d-flex flex-column align-items-end gap-1">
                                        <div class="d-flex align-items-center gap-1">
                                            <span class="fst-italic me-1 text-dark fw-bold">
                                                {{ item.trustpilotData.score }}
                                            </span>
                                            <i 
                                                v-for="(starIndex) in 5" 
                                                :key="starIndex"
                                                class="bi bi-star-fill px-1 fs-6 text-white"
                                                :class="getStarClass(starIndex, item.trustpilotData.score)"
                                            />
                                        </div>
                                        <span class="fs-6 fw-light">
                                            {{ 
                                                $translate('reviews_count', '{count} omdömen') 
                                                    .replace('{count}', item.trustpilotData.count)
                                            }}
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        lendersItems() {
            return this.slice.items
                .filter(item => item.lender?.id)
                .map(item => {
                    const lender = this.$store.state.lenders[item.lender?.id];
                    const model = new this.$models.Loan(lender, this.$store);
                    return {
                        lender: lender,
                        trustpilotData: {
                            score: model?.trustpilotData?.score,
                            count: model?.trustpilotData?.ratingCount 
                        }
                    };
                })
                .filter(item => item.trustpilotData.score);
        }
    },
    methods: {
        getStarClass(starIndex, score) {
            if (starIndex <= Math.floor(score)) {
                return 'trustpilot-bg-green';
            }

            if (starIndex <= Math.round(score)) {
                return 'trustpilot-bg-half-green';
            }

            return 'trustpilot-bg-gray';
        }
    }
};
</script>