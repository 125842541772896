<template>
    <div class="table-responsive shadow rounded mt-4 mobile-scroll-shadow">
        <table v-if="tableTitles.length" class="table table-striped mb-0 align-middle">
            <tbody>
                <tr v-if="showLogos">
                    <th class="fw-bold text-dark min-wpx-150 box-sizing-unset px-2 px-lg-3">
                        {{ $translate('lender', 'Långivare') }}
                    </th>
                    <td 
                        v-for="(lender, index) in lenders" 
                        :key="index"
                        class="min-wpx-150 box-sizing-unset px-2 px-lg-3"
                    >
                        <prismic-image 
                            v-if="lender"
                            :img="lender.data.logo"
                            w="100"
                        />
                    </td>
                </tr>
                <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
                    <th class="fw-bold text-dark min-wpx-150 box-sizing-unset px-2 px-lg-3">
                        {{ tableTitles[rowIndex] }}
                    </th>
                    <td 
                        v-for="(cell, cellIndex) in row" 
                        :key="cellIndex"
                        class="text-dark min-wpx-150 box-sizing-unset px-2 px-lg-3"
                    >
                        <div 
                            class="rich-text" 
                            v-html="cell" 
                        />
                    </td>
                </tr>
                <tr v-if="showOutLinks">
                    <th class="fw-bold text-dark min-wpx-150 box-sizing-unset px-2 px-lg-3">
                        {{ $translate('application_label', 'Ansök') }}
                    </th>
                    <td 
                        v-for="(lender, index) in lenders" 
                        :key="index"
                        class="min-wpx-150 box-sizing-unset px-2 px-lg-3"
                    >
                        <out-link 
                            v-if="lender"
                            :document="lender" 
                            class="btn btn-sm btn-primary fw-bold" 
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import CustomizableTable from '@/mixins/CustomizableTable.js';
export default {
    mixins: [
        CustomizableTable
    ],
    computed: {
        rows() {
            const rows = [];

            for (let i = 0; this.tableTitles.length > i; i++) {
                const rowValues = this.tableData
                    .map(row => row[i]);
                rows.push(rowValues);
            }
            return rows;
        }
    }
};
</script>