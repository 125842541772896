<template>
    <div :class="showAsFixedAmount ? 'mb-4' : 'mb-2'">
        <div class="d-flex justify-content-between align-items-center" :class="{'rounded bg-light border border-2 border-soft-primary px-3 px-sm-5 py-2 py-sm-3': showAsFixedAmount}">
            <label class="fs-5 fw-bold text-dark">{{ label }}</label>
            <span 
                class="w-auto max-wpx-120 w-md-25 max-wpx-md-140 h-100 fs-5 d-flex justify-content-end align-items-center text-nowrap rounded p-2 lh-sm" 
                :class="{'form-label border': !showAsFixedAmount, 'text-dark fw-bold': showAsFixedAmount}" 
                @click="$refs.input.focus()"
            >
                <input 
                    ref="input"
                    v-model="inputValue"
                    type="text"
                    :disabled="showAsFixedAmount"
                    class="formatted-input max-wpx-80 text-end form-label fs-5 pe-1"
                    @change="checkForMinimumValue"
                    @keypress="preventNonNumeric($event)"
                >
                {{ labelSuffix }}
            </span>
        </div>
        <input 
            v-if="!showAsFixedAmount"
            :id="sliderKey" 
            v-model.number="sliderValue" 
            type="range" 
            class="form-range form-input-lg" 
            :name="sliderKey" 
            :min="minValue" 
            :max="maxValue" 
            :step="increment"
        >
        <div v-if="!showAsFixedAmount" class="d-flex justify-content-between" style="margin-top: -10px">
            <label class="form-label text-secondary">{{ (minLabel) }}</label>
            <label class="form-label text-secondary">{{ (maxLabel) }}</label>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        label: {
            type: String,
            required: true
        },
        labelSuffix: {
            type: String,
            required: false,
            default: ''
        },
        initialValue: {
            type: Number,
            required: true,
            default: null
        },
        minValue: {
            type: Number,
            required: true,
        },
        minLabel: {
            type: String,
            required: true,
        },
        maxValue: {
            type: Number,
            required: true,
        },
        maxLabel: {
            type: String,
            required: true,
        },
        increment: {
            type: Number,
            required: false,
            default: 1
        },
        sliderKey: {
            type: String,
            required: true
        },
        isFixedAmount: {
            type: Boolean,
            required: false
        },
    },
    data() {
        // if initialValue is outside of min-max range, set it to the closest value
        const valueOnLoad = Math.min(
            this.maxValue,
            Math.max(this.minValue, this.initialValue)
        );
        return {
            sliderValue: valueOnLoad,
            showAsFixedAmount: this.sliderKey === 'amount' && this.isFixedAmount && !!valueOnLoad
        };
    },
    computed: {
        inputValue: {
            get() {
                return this.formatInputForDisplay(this.sliderValue);
            },
            set(value) {
                if (value) {
                    this.sliderValue = this.parseInput(value);
                }
                else {
                    this.sliderValue = this.minValue;
                }
            }
        }
    },
    watch: {
        sliderValue() {
            if (this.sliderValue > this.maxValue) {
                this.sliderValue = this.maxValue;
            }
            // if there are 2 decimals, don't allow adding a 3rd
            const stringValue = `${this.sliderValue}`;
            if (stringValue.includes('.')) {
                const [integer, decimals] = stringValue.split('.');
                if (decimals.length > 2) {
                    this.sliderValue = parseFloat(integer + '.' + decimals.slice(0, 2));
                }
            }
            this.$emit('changed', this.sliderValue);
        },
    },
    methods: {
        parseInput(string) {
            // replaces commas with decimal points for parseFloat
            const regex = /[^0-9,]/g;
            string = string
                .replace(regex, '')
                .replace(',', '.');
            // forces minValue if inputstring is empty
            if (string === '') {
                string = this.minValue;
            }
            // if it is floating value, parseFloat, else parseInt
            if (string.includes('.')) {
                return parseFloat(string);
            }
            return parseInt(string);            
        },
        formatInputForDisplay(number) {
            let decimals = 0;
            const stringNumber = `${number}`;
            // sets number of decimals for formatting
            if (stringNumber.includes('.')) {
                const decimalsInNumber = stringNumber.split('.')[1];
                if (decimalsInNumber.length === 0) {
                    return number;
                }
                decimals = decimalsInNumber.length > 2 ? 2 : decimalsInNumber.length;
            }
            // returns string formatted with thousands separator & + sign if max value
            if (number === this.maxValue) {
                return '+ ' + this.$format.number(number);
            }
            return this.$format.number(number, decimals);
        },
        checkForMinimumValue() {
            let parsedInput = this.parseInput(this.inputValue);

            if (parsedInput < this.minValue) {
                parsedInput = this.minValue;
                this.sliderValue = parsedInput;
            }
        },
        preventNonNumeric ($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

            const allowDecimals = this.sliderKey === 'interestRate';
            const validDecimal = (keyCode === 44) && allowDecimals;
            // prevents all non-numeric characters except comma
            if ((keyCode < 48 || keyCode > 57) && !validDecimal) {
                $event.preventDefault();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
    .formatted-input {
        all: unset;
    }
</style>