<template>
    <div class="container content-space-1">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <div class="custom-html text-dark" v-html="html" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            html: this.slice.primary.custom_html[0]?.text
                .replaceAll('<table', '<div class="card table-responsive px-3 my-4"><table class="table card-body"')
                .replaceAll('</table>', '</table></div>')
        };
    },
};
</script>

<style lang="scss" scoped>
.custom-html::v-deep {

    th {
        font-weight: bold;
    }
    td,
    th {
        min-width: 110px;
    }
}
</style>