<template>
    <div>
        <breadcrumbs v-if="$route.path !== '/'" />
        <div class="container content-space-1">
            <div class="w-lg-75">
                <h1 class="display-4 mb-5 text-primary">
                    {{ $prismic.asText(slice.primary.h1) }}
                </h1>
                <p v-if="p.length > 0" class="lead fs-4">{{ p }}</p>
                <table-of-contents
                    v-if="slice.primary.enable_table_of_contents"
                    class="lead fs-4"
                    :slices="slices" 
                />
            </div>
        </div>
    </div>
</template>
<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import TableOfContents from '@/components/TableOfContentsBody.vue';
export default {
    components: {
        Breadcrumbs,
        TableOfContents
    },
    props: {
        slice: {
            type: Object,
            required: true
        },
        slices: {
            type: Array,
            required: true
        }
    },
    computed: {
        p() {
            return this.$prismic.asText(this.slice.primary.p);
        }
    },
};
</script>