<template>
    <div class="container">
        <div class="row justify-content-center">
            <div 
                class="col col-lg-9 text-dark content-wrapper rich-text mb-4" 
                v-html="$prismic.asHtml(slice.primary.content)" 
            />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
};
</script>