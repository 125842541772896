<template>
    <nuxt-link 
        :to="$prismic.linkResolver(lender)"
        class="d-flex shadow p-md-4 p-3 bg-white justify-content-between align-items-center gap-2 rounded card-transition"
    >
        <prismic-image 
            :img="lender.data.logo"
            :alt="lender.data.title"
            w="100"
            class="object-fit-contain wpx-80 wpx-md-unset"
        />
        <span class="text-dark text-center lh-sm">
            {{ review.rater_name }}
        </span>
        <div class="d-flex">
            <i
                v-for="(rating, index) in maxRating"
                :key="index"    
                class="bi bi-star-fill fs-md-3 fs-5"
                :class="review.rating_score < rating ? 'text-secondary' : 'text-warning'"
            />
        </div>
    </nuxt-link>
</template>

<script>
export default {
    props: {
        review: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            maxRating: 5
        };
    },
    computed: {
        lender() {
            return this.$store.state.lenders[this.review.lenderId];
        }
    }
};
</script>