<template>
    <div v-if="!!trustScore" :class="{' bg-light': !!trustScore}" class="d-flex justify-content-center align-items-center gap-1 rounded-pill fs-6 mx-3 mb-2 px-1 py-1">
        <img class="mb-1" src="@/assets/svg/brands/trustpilot.svg">
        <span class="fw-bold pe-1">{{ trustScore }}</span>
        <span class="pe-1">
            {{ 
                `(${$translate('reviews_count', '{count} omdömen')
                    .replace('{count}', trustScoreCount)})`
            }}
        </span>
        <i v-tooltip="$translate('trustpilot_tooltip', 'På Trustpilot')" class="bi bi-info-circle-fill ms-1 text-info " />
    </div>
    <span v-else-if="!disableEmptySpacing" :class="{ 'd-none': !trustScore}" class="d-md-flex mb-2 py-1 fs-6">
            &nbsp; <!-- this maintains alignment across image and table rows -->
    </span>
</template>

<script>
export default {
    props: {
        trustScore: {
            type: Number,
            required: true,
            default: 0
        },
        trustScoreCount: {
            type: Number,
            required: true,
            default: 0
        },
        disableEmptySpacing: {
            type: Boolean,
            default: false
        }
    }
};
</script>