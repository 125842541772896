<template>
    <faq :title="$prismic.asText(slice.primary.h3)" :questions-and-answers="slice.items" />
</template>
<script>
import Faq from '@/components/Faq.vue';
export default {
    components: {
        Faq
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    }
};
</script>