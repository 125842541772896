<template>
    <div class="container content-space-1">
        <centered-section-header 
            :h2="$prismic.asText(slice.primary.h2)" 
            :intro-header="slice.primary.intro_header" 
            :title-color="iconTextClass"
        />
        <div class="row">
            <div v-for="(item, index) in slice.items" :key="index" class="col-md-6 col-lg-4 mb-5 ">
                <nuxt-link class="card card-transition h-100 text-center" :to="$prismic.linkResolver(item.link)">
                    <div class="card-body">
                        <i :class="[item.icon, iconTextClass]" class="display-5" />
                        <h3 class="card-title mt-4">{{ $prismic.asText(item.title) }}</h3>
                        <p class="card-text mb-3"><i>{{ $prismic.asText(item.muted_sub_header) }} </i></p>
                        <p 
                            v-if="$validateText(item.text)"
                            class="card-text rich-text mb-4" 
                            v-html="$prismic.asHtml(item.text)" 
                        />
                    </div>
                    <div class="card-footer pt-0">
                        <span class="card-link">{{ item.button_text }}<i class="bi-chevron-right small ms-1" /></span>
                    </div>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>
<script>
import CenteredSectionHeader from '@/components/CenteredSectionHeader.vue';
export default {
    components: {
        CenteredSectionHeader
    },
    props: {
        slice: {
            type: Object,
            required: true
        }        
    },
    computed: {
        iconTextClass() {
            return `text-${this.slice.primary.style}`;
        },
    },
};
</script>