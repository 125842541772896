<template>
    <StepContainer :title="$translate('loan_wizard_info_about_you', 'Uppgifter om dig')">
        <div class="mb-5">
            <label for="email" class="form-label">
                {{ $translate('loan_wizard_email', 'Email') }}
            </label>
            <input 
                id="email" 
                v-model="email" 
                type="email" 
                class="form-control" 
                placeholder="john@doe.com"
            >
            <Error v-if="errors.email" :error-message="$translate('loan_wizard_enter_valid_email', 'Ange en korrekt email.')" />
        </div>

        <div class="mb-5">
            <label for="phoneNumber" class="form-label">
                {{ $translate('loan_wizard_phone_number', 'Telefonnummer') }}
            </label>
            <input 
                id="phoneNumber" 
                v-model="phoneNumber" 
                type="number" 
                class="form-control" 
                :placeholder="$translate('loan_wizard_phone_number_placeholder', '07..')"
            >
            <Error v-if="errors.phoneNumber" :error-message="$translate('loan_wizard_enter_valid_phone_number', 'Ange ditt telefonnummer i rätt format.')" />
        </div>
        <div class="mb-5">
            <label class="d-flex gap-2 align-items-center cursor-pointer">
                <input v-model="acceptsPrivacyPolicy" type="checkbox">
                <div class="small text-start fst-italic" v-html="privacyPolicyText" />
            </label>
            <Error v-if="errors.acceptsPrivacyPolicy" :error-message="$translate('loan_wizard_accept_agreement', 'Du måste godkänna villkoren')" />
        </div>

        <div class="text-center">
            <a class="btn btn-sm btn-primary fw-bold d-block" role="button" :disabled="disableSubmit" 
               @click="disableSubmit ? null 
                   : submitValues()"
            >
                {{ disableSubmit ? $translate('loan_wizard_sending', 'Skickar...') 
                    : $translate('loan_wizard_see_my_loan_suggestions', 'Se mina lånförslag') }}
            </a>
            <Error v-if="submitError" :error-message="$translate('loan_wizard_something_went_wrong', 'Något gick fel.')" />
            <Error v-if="submitError" :error-message="submitError" />
        </div>
    </StepContainer>
</template>
<script>
import StepContainer from '@/components/LoanWizard/StepContainer.vue';
import Error from '@/components/LoanWizard/Error.vue';
export default {
    components: {
        StepContainer,
        Error
    },
    props: {
        updatedEmail: {
            type: String,
            required: false,
            default: ''
        },
        updatedPhoneNumber: {
            type: String,
            required: false,
            default: ''
        },
        disableSubmit: {
            type: Boolean,
            required: true,
        },
        submitError: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            email: this.updatedEmail,
            phoneNumber: this.updatedPhoneNumber,
            acceptsPrivacyPolicy: false,
            errors: {
                email: false,
                phoneNumber: false,
                acceptsPrivacyPolicy: false
            },
        };        
    },
    computed: {
        privacyPolicyText() {
            return this.$prismic.asHtml(this.$translate('loan_wizard_agreement'))
                .replace(/<p>/g, '')
                .replace(/<\/p>/g, '');
        }
    },
    methods: {
        submitValues() {
            this.$emit('disable');
            this.$emit('clearSubmitError');

            const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            this.errors.email = !emailRegex.test(this.email);
            this.errors.acceptsPrivacyPolicy = !this.acceptsPrivacyPolicy;

            const phoneNumber = `${this.phoneNumber}`;

            if (this.$isNorway()) {

                // norway numbers
                // 40108318 - 8 digits 
                // +4740108318 - 11 digits 
                // 004740108318 - 12 digits
                // 4740108318 - 10 digits
                
                if (phoneNumber.startsWith('+')) {
                    this.errors.phoneNumber = phoneNumber.length !== 11;
                }
                else if (phoneNumber.startsWith('0047')) {
                    this.errors.phoneNumber = phoneNumber.length !== 12;
                }
                else if (phoneNumber.startsWith('47')) {
                    this.errors.phoneNumber = phoneNumber.length !== 10 && phoneNumber.length !== 8;
                }
                else {
                    this.errors.phoneNumber = phoneNumber.length !== 8;
                }
            }
            else if (this.$isDenmark()) {
                // Danish numbers
                // 004512345678 - 12 digits
                // +4512345678 -11 digits
                // 4512345678 - 10 digits
                // 12345678 - 8 digits

                if (phoneNumber.startsWith('+')) {
                    this.errors.phoneNumber = phoneNumber.length !== 11;
                }
                else if (phoneNumber.startsWith('0045')) {
                    this.errors.phoneNumber = phoneNumber.length !== 12;
                }
                else if (phoneNumber.startsWith('45')) {
                    this.errors.phoneNumber = phoneNumber.length !== 10;
                }
                else {
                    this.errors.phoneNumber = phoneNumber.length !== 8;
                }
            }
            else {

                // Swedish numbers
                // 0734174756
                // +46734174756
                // 46734174756
                // 0046734174756

                if (phoneNumber.startsWith('07')) {
                    this.errors.phoneNumber = phoneNumber.length !== 10;
                }
                else if (phoneNumber.startsWith('+')) {
                    this.errors.phoneNumber = phoneNumber.length !== 12;
                }
                else {
                    this.errors.phoneNumber = phoneNumber.length !== 11;
                }
            }


            if (Object.values(this.errors).some(error => error)) {
                this.$emit('enable');
                return;
            } 
            this.$emit('submitValues', {
                email: this.email,
                phoneNumber: this.phoneNumber,
            });
        }
       
    }
};
</script>