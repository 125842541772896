<template>
    <a :id="$formatId(slice.id)" />
</template>
<script>
export default {
    props: {
        slice: {
            type: Object, 
            required: true,
        },
    }
};
</script>