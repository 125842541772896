<template>
    <div class="accordion-item" itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
        <div class="accordion-header" itemprop="name">
            <a :class="{ collapsed: collapsed }" class="accordion-button" role="button" @click="toggle">
                {{ question }}
            </a>
        </div>
        <div :class="{ show: show }" class="accordion-show collapse" itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
            <div class="accordion-body" itemprop="text">
                <div class="rich-text" v-html="formatedAnswer" />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        question: {
            type: String,
            required: true
        },
        answer: {
            // NOTE: needed because of change from key-text to rich-text
            type: [String, Array],
            required: true
        }
    },
    data() {
        return {
            show: false,
            collapsed: true
        };
    },
    computed: {
        // Remove string check when all faqs are updated to rich-text
        formatedAnswer() {
            if (typeof(this.answer) === 'string') {
                return this.answer;
            }
            return this.$prismic.asHtml(this.answer);
        }
    },
    methods: {
        toggle() {
            this.show = ! this.show;
            this.collapsed = ! this.collapsed;
        }
    }
};
</script>