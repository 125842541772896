<template>
    <div class="d-flex flex-grow-1 justify-content-end">
        <span class="text-white d-flex gap-2 align-items-center cursor-pointer user-select-none" @click="toggleLenderCompare()">
            <span v-if="!isAdded" class="fs-6">{{ $translate('compare_lenders_add_to_compare', 'Jämför') }}</span>
            <span v-else class="fs-6">{{ $translate('compare_lenders_remove_from_compare', 'Ta bort') }}</span>
            <span 
                class="d-inline-block rounded-1 d-flex justify-content-center align-items-center" 
                :class="isAdded ? 'bg-primary' : 'bg-white'"
                style="height: 16px; width: 16px;" 
            >
                <i v-if="isAdded" class="bi bi-check fs-3 lh-0" />
            </span>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        lenderId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            lenderIds: []
        };
    },
    computed: {
        isAdded() {
            return this.lenderIds.includes(this.lenderId);
        }
    },
    mounted() {
        this.lenderIds = JSON.parse(sessionStorage.getItem('lenders') || '[]');
        window.addEventListener('compare-lenders-changed', () => {
            this.lenderIds = JSON.parse(sessionStorage.getItem('lenders') || '[]');
        });
    },
    methods: {
        toggleLenderCompare() {
            let updatedLenderIds;
            if (this.isAdded) {
                updatedLenderIds = this.lenderIds.filter(id => id !== this.lenderId);
            }
            else {
                updatedLenderIds = [this.lenderId, ...this.lenderIds];
            }
            sessionStorage.setItem('lenders', JSON.stringify(updatedLenderIds));
            window.dispatchEvent(new CustomEvent('compare-lenders-changed'));
        }
    },
};
</script>