<template>
    <div>
        <sticky :document="document" />
        <banner :document="document" :model="model" />
        <corporate-lender-preamble :document="document" :model="model" />
        <lender-content :document="document" :model="model" />
        
        <faq :title="$translate('card_common_questions_about', 'Vanliga frågor om {title}').replace('{title}', document.data.title)" :questions-and-answers="document.data.faq" />
        <site-wide-links footer-id="body" />
    </div>
</template>
<script>
import Sticky from '@/components/StickyCTA.vue';
import Banner from '@/components/pages/lender/Banner.vue';
import CorporateLenderPreamble from '@/components/pages/lender/CorporateLenderPreamble.vue';
import LenderContent from '@/components/pages/lender/LenderContent.vue';
import Faq from '@/components/Faq.vue';
export default {
    components: {
        Banner,
        CorporateLenderPreamble,
        LenderContent,
        Faq,
        Sticky
    },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        model() {
            return new this.$models.CorporateLoan(this.document);
        }
    }
};
</script>
