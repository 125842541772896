<template>
    <StepContainer :title="$translate('loan_wizard_loan_type', 'Vad vill du ha för lån?')">
        <div class="mb-5">
            <label for="amount" class="form-label">
                {{ $translate('loan_wizard_choose_loan_amount', 'Hur mycket vill du låna?') }}
            </label>
            <input 
                id="amount" 
                v-model="amount" 
                type="number" 
                class="form-control" 
                placeholder="50000"
            >
            <Error v-if="errors.amount" :error-message="$translate('loan_wizard_loan_amount_info', 'Beloppet måste vara mellan 1 000 och 600 000 kr.')" />
            <span class="small">{{ $translate('loan_wizard_enter_loan_amount', 'Ange ett belopp mellan 1 000 och 600 000 kr') }}</span>
        </div>
        
        <div class="mb-5">
            <label for="paymentremark" class="form-label">
                {{ $translate('loan_wizard_loan_question', 'Vad behöver du lånet till?') }}
            </label>
            <select id="paymentremark" v-model="loanUse" class="form-select">
                <option value="null" disabled selected>{{ $translate('loan_wizard_choose_an_alternative', 'Välj ett alternativ') }}</option>
                <option value="debt_consolidation">{{ $translate('loan_wizard_debt_consolidation', 'Samla lån') }}</option>
                <option value="shopping">{{ $translate('loan_wizard_shopping', 'Shopping') }}</option>
                <option value="car_purchase">{{ $translate('loan_wizard_car_purchase', 'Bilköp') }}</option>
                <option value="renovation">{{ $translate('loan_wizard_renovation', 'Renovering') }}</option>
                <option value="travel">{{ $translate('loan_wizard_travel', 'Resa') }}</option>
                <option value="other">{{ $translate('loan_wizard_other', 'Annat') }}</option>
            </select>
            <Error v-if="errors.loanUse" :error-message="$translate('loan_wizard_choose_an_alternative', 'Välj ett alternativ')" />
        </div>

        <a 
            class="btn btn-sm btn-primary fw-bold d-block" 
            role="button" 
            @click="submitValues()"
        >
            {{ $translate('loan_wizard_next_step', 'Nästa steg') }}
        </a>
    </StepContainer>
</template>
<script>
import StepContainer from '@/components/LoanWizard/StepContainer.vue';
import Error from '@/components/LoanWizard/Error.vue';
export default {
    components: {
        StepContainer,
        Error
    },
    props: {
        updatedAmount: {
            type: Number,
            required: false,
            default: null
        },
        updatedLoanUse: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            amount: this.updatedAmount,
            loanUse: this.updatedLoanUse,
            errors: {
                amount: false,
                loanUse: false,
            }
        };
    },
    methods: {
        submitValues() {
            this.errors.amount = this.amount < 1000 || this.amount > 600000;
            this.errors.loanUse = this.loanUse === null;

            if (!this.errors.amount && !this.errors.loanUse) {
                this.$emit('submitValues', {
                    amount: Number(this.amount),
                    loanUse: this.loanUse,
                });
            } 
        },
    }
};
</script>