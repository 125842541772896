<template>
    <div v-if="document.data.redirect_enabled" class="bg-primary py-2 py-lg-3 fixed-bottom shadow-lg">
        <div class="row">
            <div class="col text-center d-flex justify-content-center align-items-center flex-column">
                <out-link :document="document" class="btn btn-light fw-bold" :text="$translate('cta_sticky', 'Ansök nu')" placement="sticky" />
                <span v-if="$isNorway()" class="text-white fs-6">Annonselenke</span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        document: {
            type: Object,
            required: true
        }
    }  
};
</script>