<template>
    <td class="align-middle">
        <div class="min-wpx-200 mx-3">
            <i 
                v-if="! categoryData.iconDisabled"
                class="bi pe-2" 
                :class="categoryData.getDataValue(model) ? 
                    'bi-check-circle-fill text-success' : 
                    'bi-x-circle-fill text-danger'" 
            />
            <span>
                {{ categoryData.getDataString(model) }}
            </span>
        </div>
    </td>
</template>

<script>
export default {
    props: {
        model: {
            type: Object,
            required: true
        },
        categoryData: {
            type: Object,
            required: true
        },
    },
};
</script>