<template>
    <div class="bg-soft-secondary margin-space-3">
        <div class="container content-space-2">
            <div class="mb-5">
                <div 
                    v-if="$validateText(slice.primary.header)"
                    class="rich-text"
                    v-html="$prismic.asHtml(slice.primary.header)"
                />
                <div 
                    v-if="$validateText(slice.primary.teaser)"
                    class="rich-text mt-3"
                    v-html="$prismic.asHtml(slice.primary.teaser)"
                />
            </div>
            <div class="d-grid grid-template-columns-md-2 grid-template-columns-1 gap-3">
                <div class="d-flex justify-content-center align-items-center">
                    <prismic-image 
                        :img="slice.primary.image"
                        w="350"
                        class="max-width-100"
                    />
                </div>
                <div class="d-flex flex-column gap-3">
                    <div class="d-flex justify-content-between align-items-center fw-bold text-dark px-md-4 px-2">
                        <span>
                            {{ $translate('latest_review_lender_title', 'Långivare') }}
                        </span>
                        <span class="me-md-4">
                            {{ $translate('latest_review_by_title', 'Omdöme av') }}
                        </span>
                        <span>
                            {{ $translate('latest_review_rating_title', 'Betyg') }}
                        </span>
                    </div>
                    <latest-review
                        v-for="(review, index) in latestReviews"
                        :key="index"
                        :review="review"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LatestReview from '@/components/LatestReview.vue';
export default {
    components: {
        LatestReview
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        latestReviews() {
            return this.$store.state.latestLenderUserRatings;
        }
    }
};
</script>