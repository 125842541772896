<template>
    <div class="border-1">
        <button
            class="btn btn-md btn-primary"
            @click="show = true"
        >
            {{ $translate('compare_lenders_add_lenders_button_cta', 'Lägg till lån') }}
        </button>

        <transition name="fade">
            <div
                v-if="show"
                class="zi-3 position-fixed top-0 start-0 vw-100 vh-100"
            >
                <div class="p-4 max-width-100 wpx-500 bg-white border rounded-1 position-absolute start-50 top-25 translate-middle-x zi-1">
                    <div class="position-relative">
                        <input
                            ref="input"
                            v-model="query"
                            class="w-100 p-3 rounded-1 border fs-6"
                            type="text"
                            name="search"
                            :placeholder="$translate('compare_lenders_search_placeholder', 'Sök...')"
                            @keydown.esc="clearQuery()"
                        >
                        <i 
                            v-if="query.length" 
                            class="bi bi-x position-absolute top-50 translate-middle-y end-0 pe-2 cursor-pointer text-danger-hover fs-2" 
                            role="button" 
                            @click="clearQuery()" 
                        />
                    </div>
                    <ul
                        class="max-hpx-300 overflow-auto contain-overscroll list-unstyled mt-2 mb-0"
                    >
                        <li
                            v-for="(lender, index) in lenderList"
                            :key="index"
                            class="d-flex align-items-center p-1 cursor-pointer min-hpx-40 text-primary-hover"
                            :class="{'border-top': index !== 0}"
                            @click="toggle(lender.id)"
                        >
                            <prismic-image
                                :img="lender.data.logo"
                                w="70"
                                class="me-2"
                            />
                            <span class="fs-6 me-auto">{{ lender.data.title }}</span>
                            <i 
                                v-if="addedLenderIds.includes(lender.id)" 
                                class="bi bi-check-lg text-success pe-2" 
                            />
                        </li>
                    </ul>
                    <div v-if="lenderList.length === 0" class="p-2 fs-6"> 
                        {{ $translate('compare_lenders_search_no_results', 'Inga resultat hittades') }}
                    </div>
                </div>

                <div
                    v-if="show"
                    class="position-fixed top-0 start-0 w-100 h-100" style="background-color: rgba(0, 0, 0, 0.5)"
                    @click="query = '', show = false"
                />
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: {
        addedLenderIds: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    data() {
        return {
            query: '',
            show: false
        };
    },
    computed: {
        lenderList() {
            return Object.values(this.$store.state.lenders)
                .filter(lender => lender.data.title.toLowerCase().includes(this.query.toLowerCase()));
        }
    },
    watch: {
        show() {
            if (this.show) {
                this.$nextTick(() => {
                    this.$refs.input.focus();
                });
            }
        }
    },
    methods: {
        toggle(id) {
            this.$emit('toggle', id);
        },
        clearQuery() {
            this.query = '';
            this.$refs.input.focus();
        }
    }
};
</script>

<style lang="scss" scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: all .15s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>