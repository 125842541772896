<template>
    <div v-if="settings.enable_commercial_disclaimer" class="mb-4">
        <div v-show="!showDisclaimer" role="button" @click="showDisclaimer = true">
            <div class="d-flex">
                <i class="bi bi-info-circle-fill ms-1 text-info me-1" /> 
                {{ settings.commercial_disclaimer_title }}
            </div>
        </div>
        <div v-show="showDisclaimer">
            <div class="row">
                <div class="col">
                    <div class="bg-light rounded d-flex ps-2">
                        <i id="commercial-disclaimer-icon" class="bi bi-info-circle-fill mt-2 mt-md-auto mb-md-auto text-info fs-1 me-md-3 d-flex" /> 
                        <div class="rich-text p-3" v-html="$prismic.asHtml(settings.commercial_disclaimer_text)" />  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    
export default {
    data() {
        return {
            showDisclaimer: false,
        };
    },
    computed: {
        settings() {
            return this.$store.state.settings;
        }
    },
};
</script>