<template>
    <div class="shadow-sm rounded-1 text-dark">
        <div 
            class="d-flex justify-content-between py-2 px-3 gap-3 fs-4 shadow-sm-hover" 
            :class="{'text-primary border-bottom': show}" 
            role="button"
            @click="show = !show"
        >
            <span>{{ title }}</span>
            <i class="bi bi-chevron-down transition-5 fs-3 d-flex align-items-center text-primary" :class="{'rotate-180': show}" />
        </div>
        <div v-show="show" class="p-3">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            show: false
        };
    }
};
</script>