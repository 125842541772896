<template>
    <div class="card-header d-block">
        <ul class="step step-sm step-centered justify-content-around">
            <li 
                v-for="stepIndex in totalSteps" 
                :key="stepIndex"
                class="step-item"
            >
                <div class="step-content-wrapper">
                    <span :class="stepIndex === currentStep ? 'text-white bg-primary' : ''" 
                          class="step-icon step-icon-soft-primary"
                          :role="(stepIndex < currentStep) ? 'button' : null"
                          @click=" (stepIndex < currentStep) ? toPreviousStep(stepIndex) : null" 
                    >
                        {{ stepIndex }}
                    </span>
                </div>
            </li>
        </ul>
    </div>
</template>

<script> 
export default {
    props: {
        currentStep: {
            type: Number,
            required: true
        },
        totalSteps: {
            type: Number,
            required: true
        }
    },
    methods: {
        toPreviousStep(stepIndex) {
            this.$emit('updateStep', stepIndex);
        }
    }

};
</script>
<style lang="scss" scoped>
    @media screen and (max-width: 575px) {

        .step-item{
         flex: 0;
         margin-bottom: 0;
        }
        .step-icon::after{
            display: none;
        }
        .card-header{
            padding-bottom: 0;
        }
    }
</style>