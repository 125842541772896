<template>
    <div class="container content-space-t-1 d-flex justify-content-center">
        <div class="w-lg-75">
            <div class="info-box bg-secondary text-light p-5 rounded">
                <h2 class="text-white">{{ $translate('card_requirement', '{title} krav').replace('{title}', document.data.title) }}</h2>
                <ul>
                    <li v-for="(req, index) in reqs" :key="`req${index}`">{{ req }}</li>
                </ul>
                <out-link :document="document" class="btn btn-light text-dark fw-bold" placement="requirements" />
            </div>

            <div class="rich-text mb-4" v-html="$prismic.asHtml(document.data.content)" />

            <opening-hours :model="model" />
            <company-facts :model="model" />
        </div>
    </div>
</template>
<script>
import OpeningHours from '@/components/models/OpeningHours.vue';
import CompanyFacts from '@/components/models/CompanyFacts.vue';
export default {
    components: {
        OpeningHours,
        CompanyFacts
    },
    props: {
        document: {
            type: Object,
            required: true
        },
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        reqs() {
            // TODO Translate, fix for several languages

            let reqs = [];

            reqs.push(`Minst ${this.model.minAge} år`);

            if (! this.model.acceptsNoIncome) {
                if (this.model.minimumYearlyIncome > 0) {
                    reqs.push(`Månadsinkomst över ${this.$format.currency(this.model.minimumYearlyIncome / 12)}`);
                }
                else {
                    reqs.push('Heltidsanställning');
                }
            }

            if (! this.model.acceptsPaymentRemarks) {
                reqs.push('Inga betalningsanmärkningar');
            }
            else {
                reqs.push('Betalningsanmärkningar accepteras');
            }

            return reqs;
        }
    }
};
</script>