<template>
    <div class="info-box bg-light p-5 rounded">
        <h2 class="text-dark">{{ $translate('calculation_example', 'Räkneexempel') }}</h2>
        <table class="table">
            <tbody>
                <tr 
                    v-for="(data, index) in interestExampleData" 
                    :key="index"
                    :class="data.class"
                >
                    <th class="fw-bold">{{ data.title }} </th>
                    <td class="text-end">{{ data.value }}</td>
                </tr>
            </tbody>
        </table>

        <p>{{ $translate('calculation_example_info', 'Ovanstående är endast räkneexempel och skall ej ses som ett erbjudande från {title} eller Kreditium. Villkor hos respektive långivare gäller.').replace('{title}', document.data.title) }}</p>
    </div>
</template>
<script>
import LoanData from '@/mixins/LoanCalculationData';
export default {
    mixins: [
        LoanData
    ],
    props: {
        model: {
            type: Object,
            required: true
        },
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        amount() {
            return this.model.minAmount < 10000 ? this.model.minAmount : 10000;
        },
        duration() {
            return 12;
        },
        interestRate() {
            if (this.$isSpain()) {
                this.model.minEffectiveInterestPossible;
            }
            return this.model.minInterestPossible;
        },
        interestRateString() {
            if (this.$isSpain()) {
                this.model.minEffectiveInterestPossibleString;
            }
            return this.model.minInterestPossibleString;
        },
        setupFee() {
            return this.model.setupFee;
        },
        invoiceFee() {
            return 0;
        },
        interestExampleData() {
            return [
                {
                    title: this.$translate('total_loan_amount', 'Totalt lånebelopp'),
                    value: this.$format.currency(this.amount)
                },
                {
                    title: this.$translate('loan_duration', 'Löptid'),
                    value: this.duration + ' ' + this.$translate('months', 'månader')
                },
                {
                    title: this.$isSpain() ? this.$translate('preamble_effective_interest', 'Effektiv ränta') : this.$translate('interest', 'Ränta'),
                    value: this.interestRateString,
                    class: 'border-dark'
                },
                {
                    title: this.$translate('cost_of_interest', 'Räntekostnad'),
                    value: this.$format.currency(this.interestCost)
                },
                {
                    title: this.$translate('arrangement_fee', 'Uppläggningsavgift'),
                    value: this.$format.currency(this.setupFee)
                },
                {
                    title: this.$translate('interest_deduction', 'Ränteavdrag'),
                    value: this.$format.currency(this.interestDeduction),
                    disabled: !this.hasInterestDeduction
                },
                {
                    title: this.$translate('loan_cost', 'Lånekostnad'),
                    value: this.$format.currency(this.totalCost),
                    class: 'border-dark'
                },
                {
                    title: this.$translate('to_pay_back', 'Att betala tillbaka'),
                    value: this.$format.currency(this.totalPayback)
                },
                {
                    title: this.$translate('cost_and_amortization_per_month', 'Kostnad och amortering i månaden'),
                    value: this.$format.currency(this.totalMonthlyPayment)
                }
            ].filter(data => !data.disabled);
        }
    }
};
</script>