<template>
    <ul v-if="pros.length" class="list-unstyled d-flex flex-column gap-2 m-0">
        <li 
            v-for="(pro, proIndex) in pros" 
            :key="proIndex" 
            class="d-flex align-items-baseline gap-2"
        >
            <i class="bi bi-check-circle-fill text-primary" />
            <span class="d-block">
                {{ pro }}
            </span>
        </li>
    </ul>
</template>
<script>
export default {
    props: {
        pros: {
            type: Array,
            required: true
        }
    }
};
</script>