<template>
    <div v-if="settings.enable_newsletter_form" :class="backgroundColor">
        <div class="container content-space-1">
            <div class="w-md-75 w-lg-50 mx-auto text-center">
                <div v-if="loading" class="spinner-border" role="status" />
                <div v-else-if="success" class="my-5 d-flex">
                    <i class="text-success bi bi-check display-3" />
                    <span class="fw-bold h4 align-self-end">
                        {{ successMessage }} 
                    </span>     
                </div>
                <div v-else class="row justify-content-between">
                    <div class="mb-3">
                        <h3 class="text-cap small">{{ $translate('newsletter_sign_up_to_our_newsletter', 'Registrera dig för vårt nyhetsbrev') }}</h3>
                        <p class="h3">{{ $translate('get_tips_offer_and_much_more', 'Få tips, erbjudanden och mycket mer direkt på mailen.') }}</p>
                    </div>
                    <div class="input-card rounded-pill mb-3">
                        <div class="form-floating flex-grow-1">
                            <input 
                                id="floatingInput" 
                                v-model="email" 
                                type="text" 
                                class="form-control pt-5" 
                                placeholder="John Doe"
                            >
                            <label for="floatingInput muted" style="text-align: left !important;">{{ $translate('newsletter_email_label', 'Email') }}</label>
                        </div>
                        <button 
                            class="fw-bold btn btn-primary btn-sm rounded-pill" 
                            @click.prevent="submit"
                        >
                            {{ $translate('newsletter_sign_up_button_text', 'Registrera dig') }}
                        </button>
                    </div>
                    <div v-if="error" class="mb-3 text-danger fw-bold">
                        <span class="bi bi-exclamation-triangle-fill me-2" />
                        {{ errorMessage }}
                    </div>
                    <p class="small m-0">{{ $translate('newsletter_muted_footer_text', 'Genom att registrera dig accepterar du vår integritetspolicy.') }}</p>
                </div>
            </div>
        </div>    
    </div>
</template>
<script>
export default {
    props: {
        backgroundColor: {
            type: String,
            required: false,
            default: ''
        },
    },
    data() {
        return {
            enable: true,
            error: false,
            success: false,
            loading: false,

            email: '',
            errorMessage: this.$translate('newsletter_error_message', 'Ogiltlig email'),
            successMessage: this.$translate('newsletter_success_message', 'Tack för att du prenumerera på vårt nyhetsbrev!')
        };
    },
    computed: {
        settings() {
            return this.$store.state.settings;
        }
    },
    methods: {
        submit() {
            const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            if (! this.enable) {
                return;
            }
            if (! this.email.match(validEmailRegex)) {
                this.error = true;
                this.errorMessage;
                return;
            }
            this.enable = false;
            this.loading = true;
            this.$axios
                .post('https://api.broker.compary.com/users', {
                    email: this.email,
                    guide_id: this.settings.newsletter_form_broker_id,
                    tracking: this.$tracking.getTrackingObject()
                })
                .catch((error) => {
                    console.log(error);
                })
                .then(() => {
                    this.enable = true;
                    this.email = ''; 
                    this.successMessage;
                    this.loading = false;
                    this.success = true;
                    this.$event.newsletterSubscribed();
                });
        },
    },
};
</script>
<style scoped>
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
</style>
