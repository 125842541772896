<template>
    <div class="container py-3 content-space-b-1 border-bottom">
        <div v-if="showAlternatives" class="row">
            <h3>{{ $translate('preamble_other_alternatives', 'Andra alternativ') }}</h3>
            <corporate-lender-card 
                v-for="(lender, index) in alternatives" 
                :key="lender.id" 
                :lender-id="lender.id" 
                :position="index + 1" 
            />
        </div>
        <div v-else class="row d-flex align-items-center">
            <div class="col-12 col-lg-6">
                <p class="lead">{{ $prismic.asText(document.data.preamble) }}</p>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <table-row :title="$translate('loan_amount_lowest', 'Lånebelopp, lägsta')" :value="model.minAmountString" />
                        <table-row :title="$translate('loan_amount_highest', 'Lånebelopp, högsta')" :value="model.maxAmountString" />
                        <table-row :title="$translate('loan_duration', 'Löptid')" :value="model.getDurationString($translate('month_short', 'mån'), $translate('year_short', 'år'))" />
                        <table-row :title="$translate('company_age_requirement', 'Krav, ålder')" :value="model.companyRequirementsString.minAge" />
                        <table-row :title="$translate('company_revenue_requirement', 'Krav, omsättning')" :value="model.companyRequirementsString.minRevenue" />
                        <table-row :title="$translate('company_personal_bail', 'Personlig borgen')" :value="model.requiresPersonalBail ? $translate('yes', 'Ja') : $translate('no', 'Nej')" />
                        <table-row :title="$translate('company_requires_safety', 'Kräver säkerhet')" :value="model.requiresCollateral ? $translate('yes', 'Ja') : $translate('no', 'Nej')" />
                        <table-row v-if="model.isBroker" :title="$translate('connected_banks', 'Anslutna banker')" :value="model.connectedBanks" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CorporateLenderCard from '@/components/cards/CorporateLenderCard.vue';
import TableRow from '@/components/cards/TableRow.vue';
export default {
    components: {
        CorporateLenderCard,
        TableRow
    },
    props: {
        document: {
            type: Object,
            required: true
        },
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        showAlternatives() {
            return (! this.document.data.redirect_enabled) || this.model.isArchived;
        },
        alternatives() {
            return Object.values(this.$store.state.corporateLenders)
                .filter(lender => lender.data.redirect_enabled)
                .slice(0, 3);
        }
    }
};
</script>