export default {
    methods: {

        filterRedirects(lender, model) {

            if (this.slice.primary.active_with_redirect === 'only_with_lists_enabled') {
                if (! lender.data.lists_enabled) {
                    return false;
                }
                if (model.isArchived) {
                    return false;
                }
            }
            else if (this.slice.primary.active_with_redirect === 'only_with_redirect') {
                if (! lender.data.redirect_enabled) {
                    return false;
                }
                if (model.isArchived) {
                    return false;
                }
            }

            return true;
        },

        filterAsnef(model) {
            if (this.slice.primary.asnef === 'only_with_asnef') {
                if (! model.acceptsASNEF) {
                    return false;
                }
            }
            else if (this.slice.primary.asnef === 'exclude_with_asnef') {
                if (model.acceptsASNEF) {
                    return false;
                }
            }

            return true;
        },
        filterSalary(model) {
            if (this.slice.primary.salary === 'only_accepts_no_salary') {
                if (! model.acceptsNoSalary) {
                    return false;
                }
            }
            else if (this.slice.primary.salary === 'exclude_accepts_no_salary') {
                if (model.acceptsNoSalary) {
                    return false;
                }
            }

            return true;
        },
        filterPaymentRemarks(model) {
            if (this.slice.primary.payment_remarks === 'only_payment_remarks') {
                if (! model.acceptsPaymentRemarks) {
                    return false;
                }
            }
            else if (this.slice.primary.payment_remarks === 'exclude_payment_remarks') {
                if (model.acceptsPaymentRemarks) {
                    return false;
                }
            }
            
            return true;
        },
        filterBrokers(model) {
            if (this.slice.primary.broker === 'only_brokers') {
                if (! model.isBroker) {
                    return false;
                }
            }
            else if (this.slice.primary.broker === 'exclude_brokers') {
                if (model.isBroker) {
                    return false;
                }
            }

            return true;
        },
        filterCreditCheck(model) {
            if (this.slice.primary.credit_check === 'exclude_uc') {
                if (model.takesUC) {
                    return false;
                }
            }
            if (this.slice.primary.credit_check === 'only_uc') {
                if (! model.takesUC) {
                    return false;
                }
            }
            if (this.slice.primary.credit_check === 'only_creditsafe') {
                if (model.creditCheck !== 'creditsafe') {
                    return false;
                }
            }
            if (this.slice.primary.credit_check === 'only_bisnode') {
                if (model.creditCheck !== 'bisnode') {
                    return false;
                }
            }
            if (this.slice.primary.credit_check === 'only_safenode') {
                if (model.creditCheck !== 'safenode') {
                    return false;
                }
            }

            return true;
        },
        filterDirectPayments(model) {
            if (this.slice.primary.direct_payments === 'exclude_direct_payments') {
                if (model.hasDirectPayments) {
                    return false;
                }
            }

            if (this.slice.primary.direct_payments === 'only_direct_payments') {
                if (! model.hasDirectPayments) {
                    return false;
                }
            }

            if (this.slice.primary.direct_payments === 'only_direct_payments_24_hours') {
                if (! model.hasDirectPayments24Hours) {
                    return false;
                }
            }

            if (this.slice.primary.direct_payments === 'only_direct_payments_to_nordea') {
                if (! model.hasDirectPaymentToNordea) {
                    return false;
                }
            }

            if (this.slice.primary.direct_payments === 'only_direct_payments_to_seb') {
                if (! model.hasDirectPaymentToSEB) {
                    return false;
                }
            }

            if (this.slice.primary.direct_payments === 'only_direct_payments_to_swedbank') {
                if (! model.hasDirectPaymentToSwedbank) {
                    return false;
                }
            }

            if (this.slice.primary.direct_payments === 'only_direct_payments_to_handelsbanken') {
                if (! model.hasDirectPaymentToHandelsbanken) {
                    return false;
                }
            }

            if (this.slice.primary.direct_payments === 'only_direct_payments_to_danske_bank') {
                if (! model.hasDirectPaymentToDanskeBank) {
                    return false;
                }
            }

            return true;
        },
        filterAge(model) {
            if (this.slice.primary.age === 'only_lenders_that_accept_18') {
                if (model.minAge > 18) {
                    return false;
                }
            }
            if (this.slice.primary.age === 'only_lenders_that_accept_20') {
                if (model.minAge > 20) {
                    return false;
                }
            }

            return true;
        },
        filterHighCostCredit(model) {
            if (this.slice.primary.high_cost_credit === 'exclude_high_cost_credits') {
                if (model.isHighCostCredit) {
                    return false;
                }
            }
            if (this.slice.primary.high_cost_credit === 'only_high_cost_credits') {
                if (! model.isHighCostCredit) {
                    return false;
                }
            }

            return true;
        },
        filter(lender) {
            const model = new this.$models.Loan(lender);

            if (! this.filterRedirects(lender, model)) {
                return false;
            }

            if (this.slice.primary.amount) {
                if (this.slice.primary.amount > model.maxAmount ||
                    this.slice.primary.amount < model.minAmount) {
                    return false;
                }
            }

            if (this.slice.primary.duration) {
                if (this.slice.primary.duration > model.maxDuration ||
                    this.slice.primary.duration < model.minDuration) {
                    return false;
                } 
            }

            if (! this.filterPaymentRemarks(model)) {
                return false;
            }

            if (! this.filterBrokers(model)) {
                return false;
            }

            if (! this.filterCreditCheck(model)) {
                return false;
            }

            if (! this.filterDirectPayments(model)) {
                return false;
            }

            if (! this.filterAge(model)) {
                return false;
            }

            if (! this.filterHighCostCredit(model)) {
                return false;
            }

            if (! this.filterAsnef(model)) {
                return false;
            }

            if (! this.filterSalary(model)) {
                return false;
            }

            return true;
            
        },
    }
};