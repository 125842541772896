<template>
    <div :id="$formatId(slice.id)" class="container mt-5 content-space-b-1">
        <h2 v-if="h2.length > 0" class="mb-5">{{ h2 }}</h2>
        <div class="row">
            <credit-card 
                v-for="(card, index) in topList" 
                :key="card.id" 
                :card-id="card.id" 
                :position="index +1"
            />
        </div>
        <div v-show="showAll" class="row">
            <credit-card 
                v-for="(card, index) in notShownList" 
                :key="card.id" 
                :card-id="card.id" 
                :position="topList.length + index + 1"
            />
        </div>
        <CommercialDisclaimer />
        <div 
            v-show="!showAll && notShownList.length > 0" 
            class="col-lg-12 text-sm text-dark"
        >
            <div class="text-center mt-4 mb-4">
                <a class="btn btn-md btn-secondary fw-bold" @click="showAll = true;">
                    {{ $translate('show_all_credit_cards', 'Visa alla kreditkort') }} <i class="bi bi-arrow-down-circle-fill ms-2" />
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import CreditCard from '@/components/cards/CreditCard.vue';
import CommercialDisclaimer from '@/components/CommercialDisclaimer.vue';
export default {
    components: {
        CreditCard,
        CommercialDisclaimer
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            cardsToShow: 9,
            showAll: false
        };
    },
    computed: {
        h2() {
            return this.$prismic.asText(this.slice.primary.h2)
                .replace('%count%', this.cards.length);
        },
        cards() {
            return Object.values(this.$store.state.cards);
        },
        topList() {
            return this.cards.slice(0, this.cardsToShow);
        },
        notShownList() {
            return this.cards.slice(this.cardsToShow);
        }
    }
};
</script>