<template>
    <ol class="d-flex flex-column justify-content-center gap-4 p-0 m-0">
        <li 
            v-for="(item, index) in items" 
            :key="index" 
            class="d-flex"
        >
            <div>
                <span class="avatar avatar-xs avatar-circle bg-soft-secondary fw-bold text-dark text-center">
                    {{ index + 1 }}
                </span>
            </div>
            <div class="d-inline text-dark ms-2">
                <nuxt-link 
                    :to="$prismic.linkResolver(item.document)" 
                    class="text-primary fw-bold text-primary-hover"
                >
                    {{ item.document.data.title }}:
                </nuxt-link>
                <div 
                    v-if="$validateText(item.toplist_text)" 
                    class="d-inline rich-text rich-text-inline"
                    v-html="topListText(item)" 
                />
            </div>
        </li>
    </ol>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        }
    },
    methods: {
        topListText(item) {
            if (item.document.type === 'credit_card') {
                return this.$prismic.asHtml(item.toplist_text)
                    .replace(/{interest}/ig, item.model.interestString)
                    .replace(/{effectiveInterest}/ig, item.model.effectiveInterestString)
                    .replace(/{yearlyCost}/ig, item.model.yearlyCostString)
                    .replace(/{maxCredit}/ig, item.model.getMaxCreditString())
                    .replace(/{interestFreeDays}/ig, item.model.interestFreeDays);
            }
            return this.$prismic.asHtml(item.toplist_text)
                .replace('{amount}', item.model.amountString)
                .replace('{maxAmount}', item.model.maxAmountString)
                .replace('{minAmount}', item.model.minAmountString)
                .replace('{interest}', item.model.interestString)
                .replace('{effectiveInterest}', item.model.effectiveInterestString)
                .replace('{creditCheck}', this.$getCreditCheckString(item.model.creditCheck))
                .replace('{duration}', this.$isSpain() ? 
                    this.$getSpanishLoanDuration(item.model, this.$translate('month_short', 'mån'),
                        this.$translate('year_short', 'år')) :
                    item.model.getDurationString(this.$translate('month_short', 'mån'),
                        this.$translate('year_short', 'år')));
            
        }
    }
};
</script>
<style lang="scss" scoped>
    .rich-text-inline::v-deep {
        p {
            display: inline;
        }
    }
</style>