<template>
    <lender v-if="document.type === 'lender'" :document="document" />
    <corporate-lender v-else-if="document.type === 'lender_corporate'" :document="document" />
    <credit-card v-else-if="document.type === 'credit_card'" :document="document" />
    <page v-else-if="document.type === 'page'" :document="document" />
    <author v-else-if="document.type === 'author'" :document="document" />
    <post v-else-if="document.type === 'post'" :document="document" />
</template>
<script>
import getDocument from '@/plugins/get-document';
import Page from '@/components/pages/Page.vue';
import Author from '@/components/pages/Author.vue';
import CreditCard from '@/components/pages/card/CreditCard.vue';
import Lender from '@/components/pages/lender/Lender.vue';
import CorporateLender from '@/components/pages/lender/CorporateLender.vue';
import Post from '@/components/posts/Post.vue';

export default {
    components: {
        Lender,
        Page,
        Author,
        CreditCard,
        CorporateLender,
        Post
    },
    layout: 'site',
    async asyncData({$prismic, route, error}) {
        try {
            return await getDocument($prismic, route.path, error);
        } 
        catch (e) {
            error({statusCode: 404, message: 'Not found'});
        }
    },
    head() {
        return {
            title: this.document.data.seo_title,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.document.data.seo_description
                }
            ]
                .concat(this.document.data.is_landing_page === true ? [{ name: 'robots', content: 'noindex' }] : []),
            link: [
                {
                    rel: 'canonical',
                    href: this.$prismic.linkResolver(this.document, true)
                },
            ]
                .concat(this.hreflangs)
        };
    }
};
</script>
