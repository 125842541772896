<template>
    <div class="container content-space-1">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <h1 class="display-4 text-primary mb-5">
                    {{ document.data.title }}
                </h1>
                <div>
                    <prismic-image 
                        v-if="image"
                        :img="image"
                        class="w-100 rounded"
                    />
                    <fallback-image 
                        v-else 
                        class="w-100 rounded" 
                        :alt="document.data.title"
                    />
                </div>
                <div v-if="author" class="mt-4 d-flex align-items-center">
                    <prismic-image 
                        :img="author.avatar" 
                        w="60" 
                        h="60" 
                        class="me-2" 
                    />
                    <div class="d-flex flex-column">
                        <span class="text-dark fw-bold"> 
                            {{ $prismic.asText(author.name) }} 
                        </span>
                        <span class="text-muted">
                            {{ 
                                $translate('published', 'Publicerad: {date}')
                                    .replace('{date}', $format.date(document.first_publication_date)) 
                            }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FallbackImage from '@/components/FallbackImage.vue';
export default {
    components: {
        FallbackImage
    },
    props: {
        document: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            author: this.document.data.author?.data
        };
    },
    computed: {
        image() {
            return this.document.data.image?.url ? this.document.data.image : null;
        }
    }
};
</script>