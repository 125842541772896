<template>
    <div>
        <sticky :document="document" />
        <banner :document="document" :model="model" />
        <preamble :document="document" :model="model" />
        <credit-card-content :document="document" :model="model" />
        <site-wide-links footer-id="credit_card" />
    </div>
</template>
<script>
import Sticky from '@/components/StickyCTA.vue';
import Banner from '@/components/pages/card/Banner.vue';
import Preamble from '@/components/pages/card/Preamble.vue';
import CreditCardContent from '@/components/pages/card/CreditCardContent.vue';
export default {
    components: {
        Banner,
        Preamble,
        CreditCardContent,
        Sticky
    },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
    computed: {
        model() {
            return new this.$models.CreditCard(this.document);
        }
    }
};
</script>
