<template>
    <div class="container py-3 content-space-b-1 border-bottom">
        <div v-if="showAlternatives" class="row">
            <h3>{{ $translate('preamble_other_alternatives', 'Andra alternativ') }}</h3>
            <lender-card 
                v-for="(lender, index) in alternatives" 
                :key="lender.id" 
                :lender-id="lender.id"
                :position="index + 1"
            />
        </div>
        <div v-else class="row d-flex align-items-center">
            <div class="col-12 col-lg-6">
                <p class="lead">{{ $prismic.asText(document.data.preamble) }}</p>
            </div>
            <div class="col-12 col-lg-6">
                <div class="card">
                    <div class="card-body">
                        <template v-if="$isSweden()">
                            <table-row 
                                :title="$translate('loan_amount', 'Belopp')" 
                                :value="model.amountString" 
                                :tooltip="! model.hasExtraLoanAmountToExistingCustomers 
                                    ? null 
                                    : model.getMaxAmountToExistingCustomersString(
                                        $translate('max_amount_to_existing_customers', 'Upp till {amount} för existerande kunder')
                                    )"
                            />
                            <table-row 
                                :title="$translate('loan_duration', 'Löptid')" 
                                :value="model.getDurationString($translate('month_short', 'mån'), $translate('year_short', 'år'))" 
                            />
                            <table-row 
                                :title="$translate('interest', 'Ränta')" 
                                :value="model.interestString" 
                            />
                            <table-row 
                                :title="$translate('preamble_effective_interest', 'Effektiv ränta')" 
                                :value="model.effectiveInterestString"
                                :tooltip="! model.hasFirstTimeOffer 
                                    ? null 
                                    : model.getFirstTimeOfferString(
                                        $translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                                        $translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                                    )" 
                            />
                            <table-row 
                                v-if="model.maxAmount <= 50000" 
                                :title="$translate('direct_pay_out', 'Direktutbetalning')" 
                                :value="model.hasDirectPayments24Hours ? $translate('around_the_clock', 'Dygnet runt') : 
                                    model.hasDirectPayments ? $translate('direct', 'Direkt') :
                                    $translate('no', 'Nej')"
                            />
                            <table-row 
                                :title="$translate('without_uc', 'Utan UC')" 
                                :value="model.takesUC ? $translate('no', 'Nej') : $translate('yes', 'Ja')" 
                                :tooltip="$translate('uses', 'Använder') + ' ' + $getCreditCheckString(model.creditCheck)" 
                            />
                            <table-row 
                                :title="$translate('payment_remarks', 'Betalningsanmärkning')" 
                                :value="model.acceptsPaymentRemarks ? $translate('accepted', 'Accepteras') : $translate('not_accepted', 'Accepteras inte')" 
                                :tooltip="model.acceptsPaymentRemarks && model.paymentRemarksMaxCount ? `Max ${model.paymentRemarksMaxCount} st` : null"
                            />
                            <table-row 
                                :title="$translate('min_age', 'Åldersgräns')" 
                                :value="model.minAge + ' ' + $translate('years', 'år')" 
                            />
                            <table-row 
                                v-if="model.isBroker" 
                                :title="$translate('connected_banks', 'Anslutna banker')" 
                                :value="model.connectedBanks" 
                            />
                        </template>
                        <template v-else-if="$isNorway()">
                            <table-row 
                                :title="$translate('loan_amount', 'Belopp')" 
                                :value="model.amountString"
                                :tooltip="! model.hasExtraLoanAmountToExistingCustomers 
                                    ? null 
                                    : model.getMaxAmountToExistingCustomersString(
                                        $translate('max_amount_to_existing_customers', 'Upp till {amount} för existerande kunder')
                                    )"
                            />
                            <table-row 
                                :title="$translate('loan_duration', 'Löptid')" 
                                :value="model.getDurationString($translate('month_short', 'mån'), $translate('year_short', 'år'))" 
                            />
                            <table-row 
                                :title="$translate('interest', 'Ränta')" 
                                :value="model.interestString" 
                            />
                            <table-row
                                :title="$translate('preamble_effective_interest', 'Effektiv ränta')" 
                                :value="model.effectiveInterestString"
                                :tooltip="! model.hasFirstTimeOffer 
                                    ? null 
                                    : model.getFirstTimeOfferString(
                                        $translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                                        $translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                                    )"
                            />
                            <table-row 
                                title="Bank-ID" 
                                :value="$formatBoolean(model.hasBankId)" 
                            />
                            <table-row 
                                :title="$translate('payment_remarks', 'Betalningsanmärkning')" 
                                :value="model.acceptsPaymentRemarks ? $translate('accepted', 'Accepteras') : $translate('not_accepted', 'Accepteras inte')" 
                            />
                            <table-row 
                                :title="$translate('min_age', 'Åldersgräns')" 
                                :value="model.minAge + ' ' + $translate('years', 'år')" 
                            />
                        </template>
                        <template v-else-if="$isSpain()">
                            <table-row 
                                :title="$translate('loan_amount', 'Belopp')" 
                                :value="model.amountString"
                                :tooltip="! model.hasExtraLoanAmountToExistingCustomers 
                                    ? null 
                                    : model.getMaxAmountToExistingCustomersString($translate('max_amount_to_existing_customers', 'Upp till {amount} för existerande kunder'))"
                            />
                            <table-row 
                                :title="$translate('loan_duration', 'Löptid')" 
                                :value="$getSpanishLoanDuration(model, $translate('month_short', 'mån'), $translate('year_short', 'år'))"
                            />
                            <table-row
                                v-if="model.isBroker"
                                :title="$translate('preamble_effective_interest', 'Effektiv ränta')" 
                                :value="model.effectiveInterestString"
                                :tooltip="$translate('spain_interest_tooltip_on_broker')"
                            />
                            <table-row 
                                v-else
                                :title="$translate('preamble_effective_interest', 'Effektiv ränta')" 
                                :value="model.effectiveInterestString"
                                :tooltip="! model.hasFirstTimeOffer 
                                    ? null 
                                    : model.getFirstTimeOfferString(
                                        $translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                                        $translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                                    )" 
                            />
                            <table-row 
                                :title="$translate('no_salary', 'Utan inkomst från lön')" 
                                :value="$formatBoolean(model.acceptsNoSalary)"
                            />
                            <table-row 
                                :title="$translate('with_asnef', 'With ASNEF')" 
                                :value="$formatBoolean(model.acceptsASNEF)"
                            />
                            <table-row 
                                :title="$translate('is_broker', 'Är låneförmedlare?')" 
                                :value="$formatBoolean(model.isBroker)"
                            />
                            <table-row 
                                :title="$translate('min_age', 'Åldersgräns')" 
                                :value="model.minAge + ' ' + $translate('years', 'år')"
                            />
                        </template>
                        <template v-else-if="$isEstonia()">
                            <table-row 
                                :title="$translate('loan_amount', 'Belopp')" 
                                :value="model.amountString" 
                                :tooltip="! model.hasExtraLoanAmountToExistingCustomers 
                                    ? null 
                                    : model.getMaxAmountToExistingCustomersString($translate('max_amount_to_existing_customers', 'Upp till {amount} för existerande kunder'))"
                            />
                            <table-row 
                                :title="$translate('loan_duration', 'Löptid')" 
                                :value="$getSpanishLoanDuration(model, $translate('month_short', 'mån'), $translate('year_short', 'år'))" 
                            />
                            <table-row 
                                :title="$translate('interest', 'Ränta')" 
                                :value="model.interestString" 
                            />
                            <table-row 
                                :title="$translate('preamble_effective_interest', 'Effektiv ränta')" 
                                :value="model.effectiveInterestString" 
                                :tooltip="! model.hasFirstTimeOffer 
                                    ? null 
                                    : model.getFirstTimeOfferString(
                                        $translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                                        $translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                                    )"
                            />
                            <table-row 
                                :title="$translate('arrangement_fee', 'Uppläggningsavgift')" 
                                :value="model.getEstonianSetupFeeString()" 
                            />
                            <table-row 
                                :title="$translate('min_age', 'Åldersgräns')" 
                                :value="model.minAge + ' ' + $translate('years', 'år')" 
                            />
                        </template>
                        <template v-if="$isDenmark()">
                            <table-row 
                                :title="$translate('loan_amount', 'Belopp')" 
                                :value="model.amountString" 
                                :tooltip="! model.hasExtraLoanAmountToExistingCustomers 
                                    ? null 
                                    : model.getMaxAmountToExistingCustomersString(
                                        $translate('max_amount_to_existing_customers', 'Upp till {amount} för existerande kunder')
                                    )"
                            />
                            <table-row 
                                :title="$translate('loan_duration', 'Löptid')" 
                                :value="model.getDurationString($translate('month_short', 'mån'), $translate('year_short', 'år'))" 
                            />
                            <table-row 
                                :title="$translate('interest', 'Ränta')" 
                                :value="model.interestString" 
                            />
                            <table-row 
                                :title="$translate('preamble_effective_interest', 'Effektiv ränta')" 
                                :value="model.effectiveInterestString"
                                :tooltip="! model.hasFirstTimeOffer 
                                    ? null 
                                    : model.getFirstTimeOfferString(
                                        $translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                                        $translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                                    )" 
                            />
                            <table-row 
                                v-if="model.maxAmount <= 50000" 
                                :title="$translate('direct_pay_out', 'Direktutbetalning')" 
                                :value="model.hasDirectPayments24Hours ? $translate('around_the_clock', 'Dygnet runt') : 
                                    model.hasDirectPayments ? $translate('direct', 'Direkt') :
                                    $translate('no', 'Nej')"
                            />
                            <table-row 
                                :title="$translate('min_age', 'Åldersgräns')" 
                                :value="model.minAge + ' ' + $translate('years', 'år')" 
                            />
                            <table-row 
                                v-if="model.isBroker" 
                                :title="$translate('connected_banks', 'Anslutna banker')" 
                                :value="model.connectedBanks" 
                            />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LenderCard from '@/components/cards/LenderCard.vue';
import TableRow from '@/components/cards/TableRow.vue';
export default {
    components: {
        LenderCard,
        TableRow
    },
    props: {
        document: {
            type: Object,
            required: true
        },
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        showAlternatives() {
            return (! this.document.data.redirect_enabled) || this.model.isArchived;
        },
        alternatives() {
            return Object.values(this.$store.state.lenders)
                .filter(lender => lender.data.redirect_enabled)
                .slice(0, 3);
        }
    }
};
</script>