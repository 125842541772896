<template>
    <div class="container content-space-1">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <h1 class="display-4 text-primary mb-5">
                    {{ $prismic.asText(slice.primary.h1) }}
                </h1>
                <div 
                    v-if="$validateText(slice.primary.teaser)"
                    class="rich-text text-dark mb-3"
                    v-html="$prismic.asHtml(slice.primary.teaser)"
                />
                <prismic-image 
                    v-if="slice.primary.banner_image.url"
                    :img="slice.primary.banner_image"
                    class="w-100 rounded"
                />
                <div v-if="author" class="mt-4 d-flex align-items-center">
                    <prismic-image 
                        :img="author.data.avatar" 
                        w="60" 
                        h="60" 
                        class="me-2" 
                    />
                    <div class="d-flex flex-column pt-2">
                        <nuxt-link :to="$prismic.linkResolver(author)" class="h5 mb-1"> 
                            {{ $prismic.asText(author.data.name) }} 
                        </nuxt-link>
                        <span class="text-muted">
                            {{ 
                                $translate('published', 'Publicerad: {date}')
                                    .replace('{date}', $format.date(document.first_publication_date)) 
                            }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        },
        document: {
            type: Object,
            required: true
        } 
    },
    computed: {
        author() {
            return this.document.data.author?.data ? this.document.data.author : null;
        }
    }
};
</script>