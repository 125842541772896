<script>
import Slice from './Slice.vue';
import InsertLenders from '@/mixins/InsertLenders.js'; 
import LenderFilters from '@/mixins/LenderFilters.js';
import LenderList from '@/components/templates/LenderList.vue';

export default {
    extends: LenderList,
    mixins: [
        Slice, 
        InsertLenders,
        LenderFilters,
    ],
    computed: {
        count() {
            return this.slice.primary.count;
        },
        h2() {
            return this.$prismic.asText(this.slice.primary.h2);
        },
        lenders() {
            return Object
                .values(this.$store.state.lenders)
                .filter(this.filter);
        },
        ordered() {
            return this.$getOrderedList(this.lenders, this.$store.state.lenders, this.$models.Loan);
        },
        list() {
            return this.insertLenders(this.ordered);
        },
    },
};
</script>