<template>
    <table class="table table-nowrap">
        <tbody>
            <tr>
                <th />
                <th v-for="lender in lenders" :key="`logo${lender.id}`"><prismic-image w="100" :img="lender.data.logo" /></th>
            </tr>
            <tr>
                <th class="fw-bold">{{ $translate('loan_amount', 'Belopp') }}</th>
                <td v-for="lender in lenders" :key="`amount${lender.id}`">{{ lender.model.amountString }}</td>
            </tr>
            <tr v-if="!$isSpain()">
                <th class="fw-bold">{{ $translate('loan_duration', 'Löptid') }}</th>
                <td v-for="lender in lenders" :key="`duration${lender.id}`">{{ lender.model.getDurationString($translate('month_short', 'mån'), $translate('year_short', 'år')) }}</td>
            </tr>
            <tr v-else>
                <th class="fw-bold">{{ $translate('loan_duration', 'Löptid') }}</th>
                <td v-for="lender in lenders" :key="`duration${lender.id}`">{{ $getSpanishLoanDuration(lender.model, $translate('month_short', 'mån'), $translate('year_short', 'år')) }}</td>
            </tr>
            <tr>
                <th class="fw-bold">{{ $translate('preamble_effective_interest', 'Effektiv ränta') }}</th>
                <td v-for="lender in lenders" :key="`interest${lender.id}`">{{ lender.model.effectiveInterestString }}</td>
            </tr>

            <template v-if="tableType === 'small_loan'">
                <tr v-if="$isSweden()">
                    <th class="fw-bold">{{ $translate('direct_pay_out', 'Direktutbetalning') }}</th>
                    <td v-for="lender in lenders" :key="`direct${lender.id}`">{{ $formatBoolean(lender.model.hasDirectPayments) }}</td>
                </tr>
                <tr v-if="$isSweden()">
                    <th class="fw-bold">{{ $translate('without_uc', 'Utan UC') }}</th>
                    <td v-for="lender in lenders" :key="`withoutuc${lender.id}`">{{ $formatBoolean(! lender.model.takesUC) }}</td>
                </tr>
                <tr v-if="$isSweden() || $isNorway()">
                    <th class="fw-bold">{{ $translate('payment_remarks', 'Betalningsanmärkning') }}</th>
                    <td v-for="lender in lenders" :key="`paymentremarks${lender.id}`">{{ $formatBoolean(lender.model.acceptsPaymentRemarks) }}</td>
                </tr>
                <tr v-if="$isSpain()">
                    <th class="fw-bold">{{ $translate('no_salary', 'Utan inkomst från lön') }}</th>
                    <td v-for="lender in lenders" :key="`nosalary${lender.id}`">{{ $formatBoolean(lender.model.acceptsNoSalary) }}</td>
                </tr>
                <tr v-if="$isSpain()">
                    <th class="fw-bold">{{ $translate('with_asnef', 'With ASNEF') }}</th>
                    <td v-for="lender in lenders" :key="`withasnef${lender.id}`">{{ $formatBoolean(lender.model.acceptsASNEF) }}</td>
                </tr>
                <tr v-if="$isEstonia()">
                    <th class="fw-bold">{{ $translate('arrangement_fee', 'Uppläggningsavgift') }}</th>
                    <td v-for="lender in lenders" :key="`contractfee${lender.id}`">{{ lender.model.getEstonianSetupFeeString() }}</td>
                </tr>
            </template>
            <template v-else-if="tableType === 'large_loan'">
                <tr v-if="$isSweden()">
                    <th class="fw-bold">{{ $translate('arrangement_fee', 'Uppläggningsavgift') }}</th>
                    <td v-for="lender in lenders" :key="`setup${lender.id}`">{{ lender.model.setupFeeString }}</td>
                </tr>
            </template>
            <template v-else-if="tableType === 'broker'">
                <tr>
                    <th class="fw-bold">{{ $translate('connected_banks', 'Anslutna banker') }}</th>
                    <td v-for="lender in lenders" :key="`connbanks${lender.id}`">{{ lender.model.connectedBanks }}</td>
                </tr>
            </template>
            <tr>
                <th class="fw-bold">{{ $translate('cta_to_application', 'Till ansökan') }}</th>
                <td v-for="lender in lenders" :key="`application${lender.id}`">
                    <out-link class="btn btn-sm btn-primary" :document="lender" placement="lender-comparisions-table" />
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script>
export default {
    props: {
        ids: {
            type: Array,
            required: true
        },
        tableType: {
            type: String,
            required: false,
            default: 'small_loan'
        }
    },
    computed: {
        lenders() {
            return this.ids
                .filter(id => id)
                .map(id => {
                    const lender = Object.assign({}, this.$store.state.lenders[id]);
                    lender.model = new this.$models.Loan(lender);
                    return lender;
                });
        }
    }
};
</script>