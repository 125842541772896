<template>
    <div class="container content-space-1">
        <div :class="{'row justify-content-center': slice.primary.table_width === 'content'}">
            <div :class="{'col col-lg-9': slice.primary.table_width === 'content'}">
                <div 
                    v-if="$validateText(slice.primary.header)"     
                    v-html="$prismic.asHtml(slice.primary.header)" 
                />
                <div 
                    v-if="$validateText(slice.primary.teaser)"
                    class="rich-text" 
                    v-html="$prismic.asHtml(slice.primary.teaser)"
                />
                <header-top-row 
                    v-if="slice.primary.headers_placement === 'top-row'"
                    :table-titles="tableTitles"
                    :table-data="tableData"
                />
                <header-left-column 
                    v-if="slice.primary.headers_placement === 'left-column'"
                    :table-titles="tableTitles"
                    :table-data="tableData"
                />
            </div>
        </div>
    </div>
</template>
<script>
import HeaderTopRow from '@/components/HeaderTopRow.vue';
import HeaderLeftColumn from '@/components/HeaderLeftColumn.vue';
export default {
    components: {
        HeaderTopRow,
        HeaderLeftColumn
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        tableTitles() {
            return Object.entries(this.slice.primary)
                .map(([key, value]) => key.startsWith('title_') && value)
                .filter(title => this.$validateText(title));
        },
        tableData() {
            return this.slice.items.map(item => {
                return Object.entries(item)
                    .filter(([key]) => key.startsWith('data_'))
                    .splice(0, this.tableTitles.length)
                    .map(([, value]) => this.$removeParagraphs(this.$prismic.asHtml(value)));
            });
        }
    },
};
</script>