<template>
    <div class="card-body text-start w-md-65 mx-md-auto">
        <h2 class="text-primary text-center">{{ title }}</h2>
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        }
    }
};
</script>