<template>
    <div class="d-flex flex-wrap gap-3 margin-space-b-md-4 margin-space-b-3 p-3 bg-soft-secondary rounded-bottom">
        <button 
            class="btn btn-outline-primary text-primary text-white-hover fw-bold letter-capitalize"
            :class="{'btn-primary text-white': !activeCategory}"
            @click="categoryClicked('')"
        >
            {{ $translate('guide_filter_show_all', 'Alla') }}
        </button>
        <div 
            v-for="guideCategory in guideCategories" 
            :key="guideCategory.id"    
            class="d-flex"
        >
            <button 
                class="btn btn-outline-primary text-primary text-white-hover fw-bold letter-capitalize" 
                :class="{'btn-primary text-white': activeCategory === guideCategory.data.category}" 
                @click="categoryClicked(guideCategory.data.category)"
            >
                {{ guideCategory.data.category }}
            </button>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        guideCategories: {
            type: Array,
            required: true
        },
        activeCategory: {
            type: String,
            required: false,
            default: ''
        }
    },
    methods: {
        categoryClicked(category) {
            this.$emit('categoryClicked', category);
        }
    }
};
</script>