<script>
import Slice from './Slice.vue';
import LenderList from '@/components/templates/LenderList.vue';
import InsertLenders from '@/mixins/InsertLenders.js'; 

export default {
    extends: LenderList,
    mixins: [
        Slice,
        InsertLenders,
    ],
    computed: {
        h2() {
            return this.$prismic.asText(this.slice.primary.h2);
        },
        list() {
            return this.insertLenders(
                Object.values(this.$store.state.lenders)
            );
        },
    },
};
</script>