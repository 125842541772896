<template>
    <div class="container mt-5 content-space-b-1">
        <div class="row g-5">
            <div v-for="(blogPost, index) in blogs" :key="index" class="col-12 col-md-6 col-lg-4">
                <nuxt-link
                    :to="$prismic.linkResolver(blogPost)"
                    class="d-flex flex-column card-transition text-dark text-primary-hover shadow shadow-lg-hover rounded h-100" 
                >
                    <prismic-image 
                        v-if="hasThumbnailDimensions(blogPost)"
                        :img="blogPost.data.image.thumbnail"
                        :w="String(blogPost.data.image.thumbnail.dimensions.width)"
                        class="rounded-top w-100"
                    />
                    <fallback-image 
                        v-else 
                        class="rounded-top w-100"
                        :alt="blogPost.data.title"
                        loading="lazy"
                    />
                    <div class="px-3 py-2 flex-grow-1 d-flex flex-column justify-content-between">
                        <div>
                            <span class="fs-3 fw-bold">{{ blogPost.data.title }}</span>
                        </div>
                        <div 
                            v-if="blogPost.data.author.data"
                            class="py-2 d-flex align-items-center"
                        >
                            <div class="me-2">
                                <prismic-image
                                    :img="blogPost.data.author.data.avatar"
                                    :alt="blogPost.data.author.data.avatar.alt"
                                    w="50" 
                                    h="50" 
                                />
                            </div>
                            <div class="d-flex flex-column small">
                                <span 
                                    class="text-dark fw-bold" 
                                >
                                    {{ $prismic.asText(blogPost.data.author.data.name) }}
                                </span>
                                <span class="text-muted">
                                    {{ $format.date(blogPost.first_publication_date) }}
                                </span>
                            </div>
                        </div>
                    </div>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>

<script>
import { queryAll } from '@swegaming-ab/nuxtjs-helpers';
import { replace as replaceHelper } from '@swegaming-ab/nuxtjs-helpers';
import FallbackImage from '@/components/FallbackImage.vue';

export default {
    components: {
        FallbackImage
    },
    data() {
        return {
            blogs: []
        };
    },
    async fetch () {
        this.blogs = replaceHelper(
            await queryAll(
                this.$prismic.api, 
                this.$prismic.predicates.at('document.type', 'post'),
                {
                    orderings: '[document.first_publication_date desc]',  
                    lang: process.env.PRISMIC_LOCALE, fetchLinks: ['author.avatar, author.name'] 
                }
            ), 
            process.env.PRISMIC_LOCALE
        );
    },
    methods: {
        hasThumbnailDimensions(post) {
            return post.data.image.thumbnail?.dimensions?.width;
        }
    }
};
</script>
