<template>
    <div class="container mb-5">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <ul class="list-unstyled text-dark d-flex flex-column gap-4 mb-0">
                    <li
                        v-for="(item, index) in items"
                        :key="index"
                        class="border rounded p-4"
                    >
                        <content-box-content 
                            :image="item.image"
                            :core-link="item.coreLink"
                            :left-column-header="item.leftColumnHeader"
                            :left-column-value="item.leftColumnValue"
                            :right-column-header="item.rightColumnHeader"
                            :right-column-value="item.rightColumnValue"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ContentBoxContent from '../ContentBoxItem.vue';
export default {
    components: {
        ContentBoxContent
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    methods: {
        isValidItem(item) {
            const requiredProps = [
                'image1',
                'core_link',
                'left_column_header',
                'left_column_value',
                'right_column_header',
                'right_column_value'
            ];
            return requiredProps.every(prop => item[prop]);
        }
    },
    computed: {
        items() {
            return this.slice.items
                .filter(item => this.isValidItem(item))
                .map(item => {
                    return {
                        image: item.image1,
                        coreLink: item.core_link,
                        leftColumnHeader: item.left_column_header,
                        leftColumnValue: item.left_column_value,
                        rightColumnHeader: item.right_column_header,
                        rightColumnValue: item.right_column_value
                    }
                });
        }
    },
};
</script>