<template>
    <div>
        <div role="button" class="w-100 py-2 d-flex text-dark gap-1" @click.prevent="toggleShowTable">
            {{ $translate('table_of_contents', 'Sidans innehåll') }}
            <i class="bi bi-chevron-down d-flex align-items-center transition-5" :class="{'rotate-180': show}" />
        </div>
        <div v-show="show" class="rounded bg-light shadow px-4 py-3 mb-2">
            <table-of-contents-list :slices="slices" />
        </div> 
    </div>
</template>

<script>
import TableOfContentsList from '@/components/TableOfContentsList.vue';
export default {
    components: {
        TableOfContentsList
    },
    props: {
        slices: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            show: false
        };
    },
    methods: {
        toggleShowTable() {
            this.show = !this.show;
        },
    },
};
</script>