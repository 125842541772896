<template>
    <div>
        <div class="d-flex flex-wrap shadow rounded">
            <div class="d-flex flex-grow-1 py-2 px-4">
                <AmountSlider 
                    :max-value="filter.slider.loanAmount.options.maxAmount"
                    :increment="filter.slider.loanAmount.options.increment"
                    @updateSliderValue="(amount) => filter.slider.loanAmount.value = amount" 
                />
            </div>
            <div 
                class="position-relative btn border-0 border-start-md border-top border-top-md-0 rounded-top-0 rounded-top-md rounded-start-md-0 
                    btn-outline-primary px-4 d-flex align-items-center fs-4 w-100 w-md-auto mt-2 mt-md-0" 
                :class="{'btn-primary text-white': showFilterOptions}" 
                @click="showFilterOptions = !showFilterOptions"
            > 
                <span class="pe-2"> {{ filter.checkBoxes.title }} </span>
                <i class="bi bi-chevron-down" :class="{'rotate-180': showFilterOptions}" />
                <div class="d-flex text-white position-absolute end-0 end-md-unset me-2 me-md-0 top-md-0 start-md-100 translate-middle-md">
                    <div v-show="activeFiltersLength">
                        <div class="bg-primary rounded-circle d-flex">
                            <span class="badge rounded-circle  bg-soft-dark shadow top-0"> {{ activeFiltersLength }} </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div 
            v-show="showFilterOptions" 
            class="rounded-bottom bg-light p-4"
        >
            <label v-for="(category, index) in filter.checkBoxes.categories" :key="index" class="w-100 d-flex gap-3 align-items-center cursor-pointer">
                <input v-model="category.active" type="checkbox" class="hpx-20 wpx-20 aspect-ratio-1">
                <span class="fs-4 text-dark"> {{ category.title }} </span>
            </label> 
        </div>
        <span class="w-100 text-end py-3 d-block text-dark">
            {{ $translate('filter_lenders_showing', 'Visar {filteredCount} av {totalCount} lån')
                .replace('{filteredCount}', listLength)
                .replace('{totalCount}', unfilteredListLength)
            }}
        </span>

        <div v-show="listLength === 0">
            <div class="d-flex bg-warning text-dark px-4 py-2 rounded align-items-center gap-3 mb-3 fs-4">
                <i class="bi bi-exclamation-triangle-fill fs-2" /> 
                <span>{{ $translate('filter_no_results', 'Vi hittade inga lån med din sökning.') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import AmountSlider from '@/components/filter/AmountSlider.vue';

export default {
    components: {
        AmountSlider
    },
    props: {
        filterData: {
            type: Object,
            required: true
        },
        listLength: {
            type: Number,
            required: true
        },
        activeFiltersLength: {
            type: Number,
            required: true
        },
        unfilteredListLength: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            filter: this.filterData,
            showFilterOptions: false
        };
    },
};
</script>