<template>
    <div class="card mb-5">
        <div class="card-header border-bottom">
            <h3 class="card-title">{{ $translate('company_facts', 'Bolagsfakta') }}</h3>
        </div>
        <div class="card-body">
            <table-row :title="$translate('company_name', 'Företagsnamn')" :value="model.company.name" />
            <table-row :title="$translate('organization_number', 'Org. nummer')" :value="model.company.registrationNumber" />
            <table-row :title="$translate('address', 'Adress')" :value="`${model.company.addres}<br>${model.company.postalNumber}, ${model.company.county}`" />
            <table-row :title="$translate('phone', 'Telefon')" :value="model.company.phone" />
            <table-row :title="$translate('email_address', 'E-post')" :value="model.company.email" />
        </div>
        <div v-if="hasLinks" class="card-header border-bottom">
            <h3 class="card-title">{{ $translate('links', 'Länkar') }}</h3>
        </div>
        <div v-if="hasLinks" class="card-body">
            <div v-if="model.website" class="row text-dark py-1 border-bottom">
                <div class="col font-weight-bold text-start">{{ $translate('home_page', 'Hemsida') }}</div>
                <div class="col text-end fw-bold">{{ model.website }}</div>
            </div>
            <div v-for="(iterator, index) in links" :key="`link${index}`" class="row text-dark py-1 border-bottom">
                <div class="col font-weight-bold text-start">{{ iterator.title }}</div>
                <div class="col text-end fw-bold"><a :href="iterator.link" target="_blank">{{ $translate('link', 'Länk') }} <i class="bi bi-box-arrow-up-right ms-1" /></a></div>
            </div>
        </div>
    </div>
</template>
<script>
import TableRow from '@/components/cards/TableRow.vue';
export default {
    components: {
        TableRow
    },
    props: {
        model: {
            type: Object,
            required: true
        }
    },
    computed: {
        hasLinks() {
            if (! this.model.website) {
                return false;
            }

            return this.links.length > 0;
        },
        links() {

            const helper = [{
                title: 'Allabolag',
                model: 'allabolag'
            }, {
                title: 'Hitta.se',
                model: 'hitta'
            }, {
                title: 'Finansinspektionen',
                model: 'finansinspektionen'
            }, {
                title: 'LinkedIn',
                model: 'linkedin'
            }];
            
            let arr = [];

            helper.forEach(h => {
                if (this.model.company.links[h.model]) {
                    arr.push({
                        title: h.title,
                        link: this.model.company.links[h.model]
                    });
                }
            });
            return arr;
        }
    }
};
</script>