export default {
    data() {
        return {
            hasInterestDeduction: this.$isSweden() || this.$isNorway()
        };
    },
    computed: {
        interestCostSweden() {
            const monthlyInterestRate = (this.interestRate/100) / 12;
            let currentCost = 0;
            for (let i = this.duration; i > 0; i--) {
                currentCost += this.amount * (i/this.duration) * monthlyInterestRate;
            }
            return currentCost;
        },
        interestCostAnnuity() {
            const monthlyInterestRate = (this.interestRate/100) / 12;
            const monthlyPayment = (monthlyInterestRate * this.amount) / (1 - (1 + monthlyInterestRate) ** -this.duration);
            const totalInterestCost = (monthlyPayment * this.duration) - this.amount;

            return totalInterestCost || 0;
        },
        interestCost() {
            if (this.$isSweden()) {
                return this.interestCostSweden;
            }
            return this.interestCostAnnuity;
        },
        interestDeductionPercentage() {
            if (this.$isSweden() || this.$isDenmark()) {
                return 0.3;
            }
            else if (this.$isNorway()) {
                return 0.22;
            }
            return 0;
        },
        interestDeduction() {
            return this.interestCost * -this.interestDeductionPercentage;
        },
        totalCost() {
            return this.interestCost + this.interestDeduction + this.setupFee + this.invoiceFee * this.duration;
        },
        totalPayback() {
            return this.amount + this.totalCost;
        },
        totalMonthlyPayment() {
            return this.totalPayback / this.duration;
        }
    }
};