<template>
    <div
        class="bg-light container position-relative rounded-2" 
        :style="`background-image: url(${slice.primary.image.url})`"
        style="
            background-repeat: no-repeat;
            background-size: cover;
            "
    >
        <div class="container content-space-1 content-space-lg-3 mt-3 mt-md-9 mb-3 mb-md-9">
            <div class="row justify-content-md-end">
                <div class="col-md-6 col-lg-5">
                    <div class="card card-lg position-relative zi-98 ">
                        <div class="card-body">
                            <div class="mb-5">
                                <h2 class="card-title h2">{{ $prismic.asText(slice.primary.h2) }}</h2>
                            </div>
                            <div v-for="(item, index) in slice.items" :key="index" class="d-flex">
                                <span class="me-3" :class="iconClass">
                                    <i :class="[iconClass, item.body_card_icon]" class="fs-2" />
                                    <p>{{ item.icon_color }}</p>
                                </span>
                                <div>
                                    <h3 class="mb-1 h5">{{ $prismic.asText(item.body_sub_title) }}</h3>
                                    <span class="d-block small mb-3">{{ item.body_card_text }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        iconClass() {
            return `text-${this.slice.primary.icon_color}`;
        },        
    },
};
</script>