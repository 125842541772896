<template>
    <img :src="fallbackImage" :alt="alt" :width="width" :loading="loading">
</template>

<script>
export default {
    props: {
        alt: {
            type: String,
            required: false,
            default: 'Kreditium'
        },
        width: {
            type: String,
            required: false,
            default: ''
        },
        loading: {
            type: String,
            required: false,
            default: 'eager'
        }
    },
    computed: {
        fallbackImage() {
            if (this.$isSweden()) {
                return '/logo/se/fallback-logo-white.png';
            }
            else if (this.$isNorway()) {
                return '/logo/no/fallback-logo-white.png';
            }
            else if (this.$isSpain()) {
                return '/logo/es/fallback-logo-white.png';
            }
            else if (this.$isEstonia()) {
                return '/logo/ee/fallback-logo-white.png';
            }
            return '/logo/se/fallback-logo-white.png';
        }
    }
};
</script>