<template>
    <div class="container mb-5">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <ul class="list-unstyled text-dark d-flex flex-column gap-4 mb-0">
                    <li
                        v-for="(card, index) in cards"
                        :key="index"
                        class="border rounded p-4"
                    >
                        <content-box-content 
                            :document="card.document" 
                            :image="{ img: card.document.data.card_image, alt: card.document.data.title, width: '100' }"
                            :left-column-header="$translate('interest', 'Ränta')"
                            :left-column-value="card.model.interestString"
                            :right-column-header="$translate('max_credit', 'Maxkredit')"
                            :right-column-value="card.model.getMaxCreditString()"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import ContentBoxContent from '../ContentBoxItem.vue';
export default {
    components: {
        ContentBoxContent
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        cards() {
            return this.slice.items
                .filter(item => item?.card?.id)
                .map(item => {
                    const document = this.$store.state.cards[item.card.id];
                    const model = new this.$models.CreditCard(document);
                    return { document: document, model: model};
                });
        }   
    },
};
</script>