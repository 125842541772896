<template>
    <div class="container mb-2">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <ul class="list-unstyled text-dark">
                    <li 
                        v-for="(step, index) in slice.items" 
                        :key="index" 
                        class="my-1 d-flex"
                    >
                        <i class="bi me-2" :class="[getIcon(step), getIconColor(step)]" />
                        <div 
                            class="rich-text" 
                            v-html="$removeParagraphs($prismic.asHtml(step.item_content))" 
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    methods: {
        getIconColor(step) {
            let stepIconColor = this.slice.primary.style;
            if (step.replace_style) {
                stepIconColor = step.replace_style;
            }
            return {
                'primary': 'text-primary',
                'secondary': 'text-secondary',
                'dark': 'text-dark',
                'light': 'text-light',
                'success': 'text-success',
                'danger': 'text-danger',
                'warning': 'text-warning',
                'info': 'text-info'
            }[stepIconColor] || 'text-primary';
        },
        getIcon(step) {
            let iconStyle = this.slice.primary.style;
            if (step.replace_style) {
                iconStyle = step.replace_style;
            }
            return {
                'danger': 'bi-dash-circle-fill',
                'warning': 'bi-dash-circle-fill',
            }[iconStyle] || 'bi-check-circle-fill';
        }
    }
};
</script>