<template>
    <div class="container content-space-1">
        <centered-section-header 
            :h2="$prismic.asText(slice.primary.h2)" 
            :intro-header="slice.primary.intro_header" 
            :title-color="iconColor"
        />
        <div class="row justify-content-left justify-content-lg-center gx-3">
            <div v-for="author in $store.state.authors" :key="author.id" class="col-sm-6 col-lg-4 mb-3">
                <nuxt-link class="card h-100 card-transition" :to="$prismic.linkResolver(author)">
                    <div class="card-body">
                        <div class="text-center avatar avatar-lg avatar-circle mb-4">
                            <prismic-image 
                                class="bg-img-start" 
                                :img="author.data.avatar" 
                                style="height: 5rem;"
                            />
                        </div>
                        <span :class="iconColor" class="card-subtitle">{{ author.data.work_title }}</span>
                        <h4 class="card-title mb-3">{{ $prismic.asText(author.data.name) }}</h4>
                        <div class="card-text rich-text" v-html="$prismic.asHtml(author.data.bio)" />
                    </div>
                    <div class="card-footer pt-0">
                        <a v-if="author.data.email" :href="'mailto:' + author.data.email">
                            <i :class="[iconColor, iconBackgroundColor]" class="rounded-circle p-3 bi bi-envelope fs-3" />
                        </a>
                    </div>
                </nuxt-link>
            </div>
        </div>
    </div>
</template>
<script>
import CenteredSectionHeader from '@/components/CenteredSectionHeader.vue';
export default {
    components: {
        CenteredSectionHeader
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        iconBackgroundColor() {
            return `bg-soft-${this.slice.primary.icon_color}`;
        },
        iconColor() {
            return `text-${this.slice.primary.icon_color}`;
        }
    }
};
</script>

                    