<template>
    <span class="error-container bg-light text-dark d-block mt-1 mb-2 text-start d-flex fw-bold"> 
        <i class="bi bi-exclamation-triangle-fill text-danger me-2" /> 
        {{ errorMessage }}
    </span>
</template>

<script>
export default {
    props: {
        errorMessage: {
            type: String,
            required: true
        }
    }
};
</script>
<style scoped>
    .error-container{
        font-size: 0.75em;
        padding-left: 0.5em;
    }
</style>