<template>
    <div class="container mb-2">
        <div class="row justify-content-center">
            <div class="col col-lg-9">
                <ul class="list-unstyled text-dark">
                    <li v-for="lender in list" :key="`contentlenderlist${filter}${lender.id}`" class="my-1">
                        <nuxt-link :to="$prismic.linkResolver(lender)" class="text-dark">
                            <span class="fw-bold text-primary">
                                <i class="bi me-2 bi-check-circle-fill text-primary" />
                                {{ lender.data.title }}</span>{{ getString(lender) }}
                        </nuxt-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import GetTemplatedString from '@/mixins/GetTemplatedString.js';
export default {
    mixins: [
        GetTemplatedString
    ],
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        filter() {
            return this.slice.primary.filter;
        },
        templateString() {
            return this.slice.primary.template_string;
        },
        lenders() {

            if (! this.filter) {
                return [];
            }

            if (this.filter === 'set_manually') {
                return this.slice.items
                    .map(iterator => this.$store.state.lenders[iterator.lender.id])
                    .filter(lender => lender);
            }

            return Object
                .values(this.$store.state.lenders)
                .filter(lender => lender.data.redirect_enabled)
                .filter(lender => {
                    lender = Object.assign({}, lender);
                    const model = new this.$models.Loan(lender);
                    lender.model = model;
                    return this.filterFunction(model);
                });
        },
        ordered() {
            return this.$getOrderedList(this.lenders, this.$store.state.lenders, this.$models.Loan);   
        },
        list() {
            if (this.slice.primary.sorting === 'manual' && this.filter === 'set_manually') {
                return this.lenders.slice(0, this.slice.primary.count || undefined);
            }
            return this.ordered.slice(0, this.slice.primary.count || undefined);
        },
        filterFunction() {

            const defaultFunction = () => true;

            return {
                'only_uc': (model) => model.takesUC,
                'exclude_uc': (model) => ! model.takesUC,
                'only_creditsafe': (model) => model.creditCheck === 'creditsafe',
                'only_bisnode': (model) => model.creditCheck === 'bisnode',
                'only_safenode': (model) => model.creditCheck === 'safenode',

                'only_direct_payments': (model) => model.hasDirectPayments,
                'only_direct_payments_24_hours': (model) => model.hasDirectPayments24Hours,
                'only_direct_payments_to_nordea': (model) => model.hasDirectPaymentsToNordea,
                'only_direct_payments_to_seb': (model) => model.hasDirectPaymentToSEB,
                'only_direct_payments_to_swedbank': (model) => model.hasDirectPaymentToSwedbank,
                'only_direct_payments_to_handelsbanken': (model) => model.hasDirectPaymentToHandelsbanken,
                'only_direct_payments_to_danske_bank': (model) => model.hasDirectPaymentToDanskeBank,
                
                'only_payment_remarks': (model) => model.acceptsPaymentRemarks,
                'exclude_payment_remarks': (model) => ! model.acceptsPaymentRemarks,

                'only_lenders_that_accept_18': (model) => model.minAge <= 18,
                'only_lenders_that_accept_20': (model) => model.minAge <= 20,
                'only_lenders_that_accept_21': (model) => model.minAge <= 21,

                'only_brokers': (model) => model.isBroker,
                'exclude_brokers': (model) => ! model.isBroker,

                'accepts_no_salary': (model) => model.acceptsNoSalary,
                'accepts_asnef': (model) => model.acceptsASNEF,

            }[this.filter] || defaultFunction;
        },
    },
    methods: {
        getString(lender) {

            if (! this.templateString) {
                return '';
            }

            return this.getTemplatedString(
                ': ' + this.templateString,
                lender
            );
        }
    }
};
</script>