<template>
    <div>
        <banner :document="document" />

        <slices 
            :slices="document.data.body"
            :document="document"
        />

        <authority-box 
            class="content-space-1"
            :author="document.data.author" 
            :last-publication-date="document.last_publication_date"
            :sources="document.data.sources"
        />
        <site-wide-links 
            :footer-id="document.data.footer_id" 
        />
    </div>
</template>

<script>
import Banner from '@/components/posts/PostBanner.vue';
import Slices from '@/components/slices/Slices.vue';
import AuthorityBox from '@/components/AuthorityBox.vue';

export default {
    components: { 
        AuthorityBox,
        Slices,
        Banner
    },
    props: {
        document: {
            type: Object,
            required: true
        }
    },
};
</script>