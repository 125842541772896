<template>
    <table class="table table-borderless table-sm">
        <thead>
            <tr>
                <th />
                <th>{{ $translate('calculator_loan_amount', 'Lånebelopp') }}</th>
                <th>{{ $translate('interest', 'Ränta') }}</th>
                <th class="d-none d-md-block">{{ $translate('calculator_monthly_cost', 'Månadskostnad') }}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(loan, index) in loans" :key="index">
                <td class="text-nowrap align-middle">{{ $translate('calculator_loan', 'Lån') }} {{ index + 1 }}</td>
                <td><input id="amount" v-model.number="loan.amount" type="number" class="w-100 form-control text-end" @input="loan.amount = checkAmount(loan.amount)"></td>
                <td><input id="interest" v-model.number="loan.interest" type="number" class="w-100 form-control text-end" @input="loan.interest = checkInterest(loan.interest)"></td>
                <td class="text-end align-middle d-none d-md-block"><span class="form-control fw-bold">{{ $format.currency((loan.amount * (loan.interest / 100)) / 12) }}</span></td>
            </tr>
            <tr class="fw-bold">
                <td>
                    <button 
                        id="addRowButton" 
                        type="button" 
                        class="d-flex justify-content-center align-items-center btn btn-primary rounded-circle" 
                        style="width:35px; height: 35px;" 
                        @click="addRow()"
                    >
                        +
                    </button>
                </td>
                <td class="text-end">{{ $format.currency(amountSum) }}</td>
                <td />
                <td class="text-end d-none d-md-block">{{ $format.currency(previousMonthlyCostSum) }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    data() {
        return {
            loans: [
                { amount: 0, interest: 0},
            ],
        };
    },
    computed: {
        amountSum() {
            let sum = 0;
            for (let loan of this.loans) {
                if (loan.amount !== '') {
                    sum += loan.amount;
                }
            }
            return sum;
        },
        previousMonthlyCostSum() {
            let sum = 0;
            for (let loan of this.loans) {
                sum += (loan.amount * (loan.interest / 100)) / 12;
            }
            return sum;
        },
        values() {
            return {
                amountSum: this.amountSum,
                previousMonthlyCostSum: this.previousMonthlyCostSum
            };
        }
    },
    watch: {
        values() {
            this.$emit('changed', this.values);
        }
    },
    methods: {
        addRow() {
            this.loans.push({amount: 0, interest: 0});
            document.getElementById('addRowButton').blur();
        },
        checkAmount(amount) {
            if (amount !== '') {
                if (amount < 0) {
                    return 0;
                }
                else if (amount > 600000) {
                    return 600000;
                } 
            }
            return amount;
        },
        checkInterest(interest) {
            if (interest !== '') {
                if (interest < 0) {
                    return 0;
                }
                else if (interest > 40) {
                    return 40;
                }
            }
            return interest;
        }
    },
};
</script>