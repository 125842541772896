<template>
    <div class="position-relative">
        <i class="bi bi-search position-absolute start-0 top-50 translate-middle ms-4" />
        <input 
            v-model="searchQuery"
            class="w-100 border-2 border-0 border-bottom rounded-0 rounded-top border-soft-primary border-secondary-focus bg-soft-secondary py-3 px-7 outline-none"
            :placeholder="$translate('guide_search_placeholder', 'Sök ...')"
            @keydown.esc="searchQuery = ''"
        >
        <i 
            v-if="searchQuery.length" 
            class="bi bi-x position-absolute end-0 top-50 fs-2 translate-middle px-1 cursor-pointer"
            @click="searchQuery = ''"    
        />
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchQuery: this.$route.query?.searchGuides || ''
        };
    },
    watch: {
        searchQuery() {
            this.$emit('searchQuery', this.searchQuery);
        }
    }
};
</script>
