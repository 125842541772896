<template>
    <div class="container">
        <div v-for="(group, index) in sitemapData" :key="index">
            <h2 class="mt-5">{{ group.header }}</h2>
            <nuxt-link
                v-for="document in orderByTitle(group.documents)"
                :key="document.id"
                :to="$prismic.linkResolver(document)"
                class="d-block mb-1"
            >
                {{ document.data.title || $translate('sitemap_not_set', 'Not set') }}
            </nuxt-link>
        </div>
    </div>
</template>
<script>
import { orderBy } from 'lodash';
import { queryAll } from '@swegaming-ab/nuxtjs-helpers';
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            pages: []
        };
    },
    async fetch() {
        this.pages = Object.values(await queryAll(
            this.$prismic.api,
            this.$prismic.predicates.at('document.type', 'page'),
            { lang: process.env.PRISMIC_LOCALE }
        )).filter(page => ! page.data.is_landing_page);
    },
    computed: {
        sitemapData() {
            return [
                {
                    header: this.$translate('sitemap_pages', 'Sidor'),
                    documents: this.pages
                },
                {
                    header: this.$translate('sitemap_credit_card', 'Kreditkort'),
                    documents: this.$store.state.cards
                },
                {
                    header: this.$translate('sitemap_private_loan', 'Privatlån'),
                    documents: this.$store.state.lenders
                },
                {
                    header: this.$translate('sitemap_corporate_loan', 'Företagslån'),
                    documents: this.$store.state.corporateLenders
                }
            ];
        }
    },
    methods: {
        orderByTitle(documents) {
            return orderBy(documents, [document => document.data.title], ['asc']);
        },
    }
};
</script>