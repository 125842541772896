<template>
    <div v-if="questionsAndAnswers.length" class="container content-space-2 content-space-lg-3">
        <div class="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
            <h3>{{ title }}</h3>
        </div>

        <div class="w-lg-65 mx-lg-auto">
            <div class="accordion accordion-flush accordion-lg" itemscope="" itemtype="https://schema.org/FAQPage">
                <question-and-answer
                    v-for="(iterator, index) in questionsAndAnswers" 
                    :key="`faq${index}`"
                    :question="iterator.question" 
                    :answer="iterator.answer"
                />
            </div>
        </div>
    </div>
</template>
<script>
import QuestionAndAnswer from '@/components/QuestionAndAnswer.vue';
export default {
    components: {
        QuestionAndAnswer
    },
    props: {
        title: {
            type: String,
            required: true
        },
        questionsAndAnswers: {
            type: Array,
            required: true
        },
    }  
};
</script>