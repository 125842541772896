<template>
    <div class="container content-space-1">
        <div class="row justify-content-center">
            <div 
                class="d-flex justify-content-center" 
                :class="contentWidth"
            >
                <prismic-image 
                    class="max-width-100"
                    :img="slice.primary.image"
                    :w="imageWidth"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        imageWidth() {
            if (this.slice.primary.image_width === 'container') {
                return '1116';
            }
            else if (this.slice.primary.image_width === 'content') {
                return '831';
            }
            return 'auto';
        },
        contentWidth() {
            return this.slice.primary.image_width === 'content' ? 'col col-lg-9' : '';
        }
    }
};
</script>