<template>
    <div class="card col">
        <div class="card-body">
            <form>
                <slider-input 
                    v-for="input in inputs"
                    :key="input.sliderKey"
                    :label="input.label"
                    :label-suffix="input.labelSuffix"
                    :initial-value="input.initialValue"
                    :min-value="input.minValue"
                    :min-label="input.minLabel"
                    :max-value="input.maxValue"
                    :max-label="input.maxLabel"
                    :increment="input.increment"
                    :slider-key="input.sliderKey"
                    :is-fixed-amount="isFixedAmount"
                    @changed="input.updateFunction"
                />
                <div v-if="$isSweden()" class="row">
                    <div class="col-md-6 col-12 mb-3">
                        <label class="form-label fw-bold">{{ $translate('calculator_arrangement_fee', 'Uppläggningsavgift (Max 1000 kr)') }}</label>
                        <input 
                            id="amount" 
                            v-model.number="newArrangementFee" 
                            type="number" 
                            class="form-control form-control-lg" 
                            name="arrangementFee" 
                            placeholder="0"
                            v-on="checkArrangementFee()"
                        >
                    </div>
                    <div class="col-md-6 col-12 mb-3">
                        <label class="form-label fw-bold">{{ $translate('calculator_invoice_fee', 'Aviavgift (Max 50 kr / månad)') }}</label>
                        <input 
                            id="amount" 
                            v-model.number="newInvoiceFee" 
                            type="number" 
                            class="form-control form-control-lg" 
                            name="invoiceFee" 
                            placeholder="0"
                            v-on="checkInvoiceFee()"
                        >
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<script>
import SliderInput from './SliderInput.vue';

export default {
    components: {
        SliderInput,
    },
    props: {
        isFixedAmount: {
            type: Boolean,
            required: false
        },
        amount: {
            type: Number,
            required: true
        },
        duration: {
            type: Number,
            required: true
        },
        interestRate: {
            type: Number,
            required: true
        },
        arrangementFee: {
            type: Number,
            required: true
        },
        invoiceFee: {
            type: Number,
            required: true
        },
        minAmount: {
            type: Number,
            required: true,
        },
        maxAmount: {
            type: Number,
            required: true,
        },
        minDuration: {
            type: Number,
            required: true,
        },
        maxDuration: {
            type: Number,
            required: true,
        },
        minInterest: {
            type: Number,
            required: true,
        },
        maxInterest: {
            type: Number,
            required: true,
        },
        
    },
    data() {
        return {
            newAmount: this.amount,
            newDuration: this.duration,
            newInterestRate: this.interestRate,
            newArrangementFee: this.arrangementFee === 0 ? null : this.arrangementFee,
            newInvoiceFee: this.invoiceFee === 0 ? null : this.invoiceFee,

            interestKeyName: this.$isSpain() ? 'preamble_effective_interest' : 'interest', 
            interestTitle: this.$isSpain() ? 'Effektiv ränta' : 'Ränta',
        };
    },
    computed: {
        values() {
            return {
                amount: this.newAmount,
                duration: this.newDuration,
                interestRate: this.newInterestRate,
                arrangementFee: Number(this.newArrangementFee),
                invoiceFee: Number(this.newInvoiceFee)
            };
        },
        calculateIncrement() {
            const range = (this.maxAmount - this.minAmount);

            if (range > 100000){
                return 1000;
            }
            if (range > 60000){
                return 100;
            }
            return 50;
        },
        inputs() {
            return [
                {
                    label: this.$translate('calculator_loan_amount', 'Lånebelopp'),
                    labelSuffix: this.$getCurrencySymbol(),
                    initialValue: this.amount,
                    minValue: this.minAmount,
                    minLabel: this.$format.currency(this.minAmount) || '',
                    maxValue: this.maxAmount,
                    maxLabel: this.$format.currency(this.maxAmount) || '',
                    increment: this.calculateIncrement,
                    sliderKey: 'amount',
                    updateFunction: (amount) => this.newAmount = amount,
                },
                {
                    label: this.$translate('loan_duration', 'Löptid'),
                    labelSuffix: this.newDuration === 1 ? this.$translate('month', 'månad') : this.$translate('months', 'månader'),
                    initialValue: this.duration,
                    minValue: this.minDuration,
                    minLabel: `${this.minDuration} ${this.minDuration === 1 ? this.$translate('month', 'månad') : this.$translate('months', 'månader')}`,
                    maxValue: this.maxDuration,
                    maxLabel: this.maxDuration === 180 ? this.$translate('calculator_loan_max_duration', '180 månader (15 år)') : `${this.maxDuration} ${this.$translate('months', 'månader')}`,
                    increment: 1,
                    sliderKey: 'duration',
                    updateFunction: (duration) => this.newDuration = duration,
                },
                {
                    label: this.$translate(this.interestKeyName, this.interestTitle),
                    labelSuffix: '%',
                    initialValue: this.interestRate,
                    minValue: this.minInterest,
                    minLabel: `${this.minInterest}%`,
                    maxValue: this.maxInterest,                
                    maxLabel: `${this.maxInterest}%`,
                    increment: 1,
                    sliderKey: 'interestRate',
                    updateFunction: (interestRate) => this.newInterestRate = interestRate,
                },
            ]; 
        }
    },
    watch: {
        values() {
            this.$emit('changed', this.values);
        }
    },
    methods: {
        checkArrangementFee() {
            if (this.newArrangementFee > 1000) {
                this.newArrangementFee = 1000;
            }
        },
        checkInvoiceFee() {
            if (this.newInvoiceFee > 50) {
                this.newInvoiceFee = 50;
            }
        },
    }
};
</script>