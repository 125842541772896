<template>
    <div class="col-lg-4 bg-dark text-light card p-5 ms-lg-3 mt-5 mt-lg-0">
        <table class="table table-dark text-light">
            <tbody>
                <tr 
                    v-for="(data, index) in overviewData" 
                    :key="index" 
                    :class="data.class"
                >
                    <td class="fw-bold text-light">{{ data.title }}</td>
                    <td class="text-end text-light">{{ data.value }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import LoanData from '@/mixins/LoanCalculationData';
export default {
    mixins: [
        LoanData
    ],
    props: {
        amount: {
            type: Number,
            required: true
        },
        duration: {
            type: Number,
            required: true
        },
        interestRate: {
            type: Number,
            required: true
        },
        setupFee: {
            type: Number,
            required: true
        },
        invoiceFee: {
            type: Number,
            required: true
        }
    },
    computed: {
        overviewData() {
            return [
                {
                    title: this.$translate('calculator_results_interest_cost', 'Räntekostnad'),
                    value: this.$format.currency(this.interestCost)
                },
                {
                    title: this.$translate('calculator_results_interest_deduction', 'Ränteavdrag'),
                    value: this.$format.currency(this.interestDeduction),
                    disabled: !this.hasInterestDeduction
                },
                {
                    title: this.$translate('arrangement_fee', 'Uppläggningsavgift'),
                    value: this.$format.currency(this.setupFee),
                    disabled: !this.$isSweden()
                },
                {
                    title: this.$translate('calculator_results_invoice_fee', 'Aviavgift'),
                    value: this.$format.currency(this.invoiceFee * this.duration),
                    disabled: !this.$isSweden(),
                    class: 'border-white'
                },
                {
                    title: this.$translate('calculator_results_total_cost', 'Total kostnad'),
                    value: this.$format.currency(this.totalCost)
                },
                {
                    title: this.$translate('calculator_results_total_payback', 'Total återbetalning'),
                    value: this.$format.currency(this.totalPayback)
                },
                {
                    title: this.$translate('calculator_results_total_monthly_payment', 'Månadsbetalning'),
                    value: this.$format.currency(this.totalMonthlyPayment)
                },
            ].filter(data => !data.disabled);
        }
    }
};
</script>