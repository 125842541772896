export default {
    methods: {
        getLendersToInsert() {

            if (! (this.slice.primary.insert_list && this.slice.primary.insert_list.id)) {
                return [];
            }

            try {

                let lendersToInsert = this.$store.state.insertLists[this.slice.primary.insert_list.id]
                    .data.lenders_to_insert;

                return lendersToInsert
                    .filter(l => l.lender.id)

                    // only insert lenders that have redirect enabled or alternative
                    .filter(l => {
                        const lenderData = this.$store.state.lenders[l.lender.id].data;
                        return lenderData.redirect_enabled === true || lenderData.alternative.id;
                    })
                    .filter(l => {
                        return (! l.until) || Date.now() <= new Date(l.until);
                    })
                    .sort((a, b) => {
                        return parseInt(a.at_position) - parseInt(b.at_position);
                    });
            }
            catch (error) {
                return [];
            }
        },
        insertLenders(list) {
            const lendersToInsert = this.getLendersToInsert();

            if (lendersToInsert.length > 0) {

                // remove all lenders to insert
                lendersToInsert.forEach(lenderToInsert => {
                    list = list.filter(l => l.id !== lenderToInsert.lender.id);
                });

                // add lenders to insert 
                lendersToInsert.forEach(lenderToInsert => {
                    list.splice(parseInt(lenderToInsert.at_position) - 1, 0, lenderToInsert.lender);
                });
            }

            return list;
        }
    }
};