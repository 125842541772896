<template>
    <div class="mb-3">
        <div class="d-flex justify-content-between">
            <label class="form-label fs-5">{{ $translate(interestKeyName, interestTitle) }}</label>
            <label class="form-label fs-5">{{ newInterestRate + "%" }}</label>
        </div>
        <input id="amount" v-model.number="newInterestRate" type="range" class="form-range form-input-lg" name="amount" min="0" :max="maxInterestRate" step="1">
        <div class="d-flex justify-content-between">
            <label class="form-label text-secondary">0%</label>
            <label class="form-label text-secondary">{{ maxInterestRate }}%</label>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        interestKeyName: {
            type: String,
            required: false,
            default: 'interest'
        },
        interestTitle: {
            type: String,
            required: false,
            default: 'Ränta'
        },
        interestRate: {
            type: Number,
            required: true
        },
        maxInterestRate: {
            type: Number,
            required: false,
            default: 50
        }
    },
    data() {
        return {
            newInterestRate: this.interestRate
        };
    },
    watch: {
        newInterestRate: function() {
            this.$emit('changed', this.newInterestRate);
        }
    }
};
</script>