<template>
    <contact-form 
        :title="$prismic.asText(slice.primary.h2)" 
        :description="slice.primary.p"
        :background-color="backgroundColor"
    />
</template>
<script>
import ContactForm from '@/components/ContactForm.vue'; 
export default {
    components: {
        ContactForm
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        backgroundColor() {
            return `bg-soft-${this.slice.primary.background_color}`;
        }
    }
};
</script>