<template>
    <div class="container content-space-t-1">
        <centered-section-header 
            :h2="$prismic.asText(slice.primary.h2)" 
            :intro-header="slice.primary.intro_header" 
            :title-color="iconTextClass"
        />
        <div class="row">
            <div v-for="(item, index) in slice.items" :key="index" class="col-md-6 mb-7 mb-md-9 d-flex">
                <div>
                    <div 
                        :class="iconBackgroundClass" 
                        class="d-flex align-items-center justify-content-center rounded-circle"
                        style="height: 50px; width:50px;"
                    >
                        <i :class="[item.icon, iconTextClass]" class="fs-2" />
                    </div>
                </div>
                <div class="ms-4">
                    <h3>{{ $prismic.asText(item.h3) }}</h3>
                    <div class="pe-md-7 rich-text" v-html="$prismic.asHtml(item.text)" />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CenteredSectionHeader from '@/components/CenteredSectionHeader.vue';
export default {
    components: {
        CenteredSectionHeader
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        iconBackgroundClass() {
            return `bg-soft-${this.slice.primary.style}`;
        },
        iconTextClass() {
            return `text-${this.slice.primary.style}`;
        }
    }
};
</script>