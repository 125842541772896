<template>
    <div class="d-flex flex-column ">
        <result-best-match 
            v-for="(lender, index) in orderLenders.slice(0, 1)" 
            :key="index" 
            :lender-id="lender.id"
            :lender-index="index"
        />
        <div class="row">
            <h2 class="mb-5">{{ $translate('loan_wizard_result_top_three', 'Andra banker som matchar dina önskemål.') }}</h2>
            <lender-card 
                v-for="(lender, index) in orderLenders.slice(1, 4)" 
                :key="index" 
                :lender-id="lender.id"
                :lender-index="index"
            />
        </div>
    </div>
</template>
<script>
import ResultBestMatch from './ResultBestMatch.vue';
import LenderCard from '@/components/cards/LenderCard.vue';
export default {
    components: {
        ResultBestMatch,
        LenderCard
    },
    props: {
        hasPaymentRemarks: {
            type: Boolean,
            required: false,
            default: false
        },
        loanAmount: {
            type: Number,
            required: false,
            default: NaN
        }
    },
    computed: {
        getLendersWithPartnership() {
            return Object.entries(this.$store.state.lenders)
                .map(([id, value]) => {
                    return {
                        id: id,
                        value: value
                    };
                })
                .filter(lender => lender.value.data.redirect_enabled === true);
        },
        sortLendersByResult() {
            return this.getLendersWithPartnership
                .filter(lenderData => {
                    const lender = Object.assign({}, this.$store.state.lenders[lenderData.id]);
                    lender.model = new this.$models.Loan(lender);
                    if (this.hasPaymentRemarks && !lender.model.acceptsPaymentRemarks) {
                        return false;
                    }
                    
                    if (this.loanAmount && (this.loanAmount < lender.model.amount_from && this.loanAmount > lender.model.amount_to)) {
                        return false;
                    }

                    return true;
                });
        },
        orderLenders() {
            return this.$getOrderedList(this.sortLendersByResult, this.$store.state.lenders, this.$models.Loan).slice(0, 4);
        }
    }
};
</script>