<template>
    <div class="container max-width-100 content-space-t-1 content-space-b-1" :class="{'content-space-t-2': isFirstSlice}">
        <div class="d-flex justify-content-between align-items-center gap-3 flex-wrap pb-4 border-bottom border-5 border-primary">
            <div class="rich-text mb-0 h1" v-html="$prismic.asHtml(slice.primary.header)" />
            <search-lenders :added-lender-ids="lenderIds" @toggle="toggleLender" />
        </div>
        <compare-table :lender-ids="lenderIds" @removeLender="removeLender" />
    </div>
</template>

<script>
import CompareTable from '@/components/CompareTable.vue';
import SearchLenders from '@/components/SearchLenders.vue';
export default {
    components: {
        CompareTable,
        SearchLenders
    },
    props: {
        slice: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    computed: {
        lenderIds() {
            const query = this.$route.query.lender;
            if (typeof query === 'string') {
                return [query];
            }
            return query || [];
        },
        isFirstSlice() {
            return this.index === 0;
        }
    },
    mounted() {
        sessionStorage.removeItem('lenders');
        window.dispatchEvent(new CustomEvent('compare-lenders-changed'));
    },
    methods: {
        toggleLender(id) {
            if (this.lenderIds.includes(id)) {
                this.removeLender(id);
            } 
            else {
                this.addLender(id);
            }
        },
        addLender(lenderIdToAdd) {
            const updatedQuery = [...this.lenderIds, lenderIdToAdd];
            this.$router.replace({
                query: {
                    lender: updatedQuery
                }
            });
        },
        removeLender(lenderIdToRemove) {
            const updatedQuery = this.lenderIds
                .filter(lenderId => lenderId !== lenderIdToRemove);
            this.$router.replace({
                query: {
                    lender: updatedQuery
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .h1 {
        h1, h2 {
            font-size: inherit !important;
        }
    }
</style>