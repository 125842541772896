<template>
    <div class="container content-space-1">
        <div :class="{'row justify-content-center': slice.primary.table_width === 'content'}">
            <div :class="{'col col-lg-9': slice.primary.table_width === 'content'}">
                <div 
                    v-if="$validateText(slice.primary.header)"     
                    v-html="$prismic.asHtml(slice.primary.header)" 
                />
                <div 
                    v-if="$validateText(slice.primary.teaser)"
                    class="rich-text" 
                    v-html="$prismic.asHtml(slice.primary.teaser)"
                />
                <header-top-row 
                    v-if="slice.primary.headers_placement === 'top-row'"
                    :table-titles="tableTitles"
                    :table-data="tableData"
                    :lenders="lenders"
                    :show-logos="slice.primary.show_logos"
                    :show-out-links="slice.primary.show_out_links"
                />
                <header-left-column 
                    v-else-if="slice.primary.headers_placement === 'left-column'"
                    :table-titles="tableTitles"
                    :table-data="tableData"
                    :lenders="lenders"
                    :show-logos="slice.primary.show_logos"
                    :show-out-links="slice.primary.show_out_links"
                />
            </div>
        </div>
    </div>
</template>
<script>
import HeaderTopRow from '@/components/HeaderTopRow.vue';
import HeaderLeftColumn from '@/components/HeaderLeftColumn.vue';
export default {
    components: {
        HeaderTopRow,
        HeaderLeftColumn
    },
    props: {
        slice: {
            type: Object,
            required: true
        }
    },
    computed: {
        tableTitles() {
            return Object.entries(this.slice.primary)
                .filter(([key, value]) => key.startsWith('title_') && this.$validateText(value))
                .map(([, value]) => value);
        },
        tableData() {
            return this.slice.items
                .map(item => {
                    const lender = this.$store.state.lenders[item.lender.id];

                    return Object.entries(item)
                        .filter(([key]) => key.startsWith('data_'))
                        .splice(0, this.tableTitles.length)
                        .map(([, value]) => this.replaceShortcodes(value, lender));
                });
        },
        lenders() {
            return this.slice.items
                .map(item => this.$store.state.lenders[item.lender.id]);
        }
    },
    methods: {
        replaceShortcodes(value, lender) {
            if (!lender) {
                return this.$removeParagraphs(this.$prismic.asHtml(value));
            }
            const model = new this.$models.Loan(lender);
            return this.$removeParagraphs(this.$prismic.asHtml(value))
                .replace('{amount}', model.amountString)
                .replace('{maxAmount}', model.maxAmountString)
                .replace('{minAmount}', model.minAmountString)
                .replace('{interest}', model.interestString)
                .replace('{effectiveInterest}', model.effectiveInterestString)
                .replace('{creditCheck}', this.$getCreditCheckString(model.creditCheck))
                .replace('{duration}', this.$isSpain() ? 
                    this.$getSpanishLoanDuration(model, this.$translate('month_short', 'mån'),
                        this.$translate('year_short', 'år')) :
                    model.getDurationString(this.$translate('month_short', 'mån'),
                        this.$translate('year_short', 'år')));
        }
    },
};
</script>