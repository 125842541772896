<template>
    <div class="col-12 col-md-6 col-lg-4 mb-5">
        <div class="card hover-translate-y-n10 hover-shadow-lg border">
            <card-header :lender-or-card="card" />
            <card-image :image="card.data.card_image" width="200" class="py-5" />

            <div class="card-body p-3 bg-light fs-6">
                <table-row 
                    v-for="(data, index) in cardData" 
                    :key="index" 
                    :title="data.title" 
                    :value="data.value" 
                />
            </div>
            <ul v-if="pros.length" class="d-flex flex-column gap-2 list-unstyled text-dark px-3 p-4 fs-6 m-0">
                <li 
                    v-for="(pro, index) in pros" 
                    :key="index"
                    class="d-flex align-items-baseline gap-2"
                >
                    <i class="text-primary bi bi-check-circle-fill" />
                    <span>
                        {{ pro }}
                    </span>
                </li>
            </ul>
            <card-links :lender-or-card="card" :position="position" />
        </div>
    </div>
</template>

<script>
import CardHeader from './CardHeader.vue';
import CardImage from './CardImage.vue';
import CardLinks from './CardLinks.vue';
import TableRow from './TableRow.vue';
export default {
    components: {
        CardHeader,
        CardImage,
        TableRow,
        CardLinks
    },
    props: {
        cardId: {
            type: String,
            required: true
        },
        position: {
            type: Number,
            required: false,
            default: null
        }
    },
    computed: {
        card() {
            return this.$store.state.cards[this.cardId];
        },
        model() {
            return new this.$models.CreditCard(this.card);
        },
        cashbackDescription() {
            if (!this.model.hasCashback) {
                return '<i class="bi bi-x-circle-fill text-danger"></i>';
            }
            if (this.model.hasUnlimitedCashback) {
                return this.$format.percentage(this.model.cashbackPercentage);
            }
            return `${this.$format.percentage(this.model.cashbackPercentage)} (max ${this.model.getCashbackLimitString(
                this.$translate('month_short', 'mån'), 
                this.$translate('year_short', 'år')
            )})`;
        },
        cardData() {
            return [
                {
                    title: this.$translate('yearly_cost', 'Årsavgift'),
                    value: this.model.yearlyCostString,
                },
                {
                    title: this.$translate('max_credit', 'Maxkredit'),
                    value: this.model.getMaxCreditString()
                },
                {
                    title: this.$translate('preamble_effective_interest', 'Effektiv ränta'),
                    value: this.model.effectiveInterestString
                },
                {
                    title: this.$translate('preamble_currency_exchange_fee', 'Valutapåslag'),
                    value: this.model.getCurrencyExchangeFeeString()
                },
                {
                    title: this.$translate('cashback', 'Cashback'),
                    value: this.cashbackDescription,
                },
                {
                    title: this.$translate('preamble_withdrawal_fee', 'Uttagsavgift'),
                    value: this.model.getWithdrawalFeeString()
                }
            ];
        },
        pros() {
            return this.card.data.pros
                .map(pro => pro.pro)
                .filter(pro => this.$validateText(pro));
        }
    }
};
</script>