<template>
    <div class="d-flex flex-wrap align-items-center gy-2 text-dark fs-4 flex-grow-1">
        <span class="d-flex col-auto flex-grow-1 flex-md-grow-0">
            {{ $translate('filter_loan_amount', 'Lånebelopp') }}
        </span>
        <div class="d-flex w-100 w-md-auto flex-grow-1 order-3 order-md-2 mt-2 mt-md-0 px-md-4">
            <input 
                id="amount" 
                v-model.number="sliderValue" 
                type="range" 
                class="form-range form-input-lg" 
                name="amount" 
                min="0" 
                :max="maxValue" 
                :step="increment"
                @change="emitValue"
            >
        </div>
        <div class="order-2 order-md-3 wpx-100 d-flex justify-content-end ms-auto">
            <span 
                class="d-flex justify-content-end fw-bold text-nowrap border-bottom border-2 border-primary lh-sm border-bottom-md-0" 
                @click="$refs.input.focus()"
            >
                <input 
                    ref="input"
                    v-model="inputValue"
                    :size="inputValue.length || 1"
                    type="text"
                    class="formatted-input text-end pe-1"
                    @change="handleInputChange"
                >
                {{ suffix }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        increment: {
            type: String,
            required: false,
            default: '1'
        },
        minValue: {
            type: Number,
            required: false,
            default: 0
        },
        maxValue: {
            type: Number,
            required: true,
        },
        startValue: {
            type: Number,
            required: false,
            default: null
        },
    },
    data() {
        const startValue = this.startValue || this.minValue;
        return {
            sliderValue: startValue,
            inputValue: this.$format.number(startValue),
            suffix: this.$format.currency(0).replace(/\d/g, '').trim()
        };
    },
    watch: {
        sliderValue() {
            this.inputValue = `${this.formatNumber(this.sliderValue)}`;
        },
    },
    methods: {
        handleInputChange() {
            this.updateSliderValue();
            this.emitValue();
        },
        parseNumber(string) {
            const regex = /[^0-9.,]/g;
            string = string
                .replace(regex, '')
                .replace(',', '.');
            
            if (string === '') {
                string = '0';
            }
            if (this.$isSpain()) {
                string = string.replace('.', '');
            }

            return parseInt(string);
        },
        updateSliderValue() {
            let parsedInput = this.parseNumber(this.inputValue);
            if (parsedInput > this.maxValue) {
                parsedInput = this.maxValue;
            }
            if (parsedInput === this.sliderValue) {
                this.inputValue = `${this.formatNumber(parsedInput)}`;
                return;
            }
            this.sliderValue = parsedInput;
        },
        formatNumber(number) {
            if (number === this.maxValue) {
                return '+ ' + this.$format.number(number, 0);
            }
            return this.$format.number(number, 0);
        },
        emitValue() {
            this.$emit('updateSliderValue', this.sliderValue);
        }
    },
};
</script>

<style lang="scss" scoped>
    .formatted-input {
        all: unset;
    }
</style>
