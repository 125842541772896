<template>
    <div class="container py-3 border-bottom">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb mb-0">
                <li class="breadcrumb-item"><nuxt-link to="/">{{ $translate('breadcrumbs_home', 'Hem') }}</nuxt-link></li>
                <li 
                    v-for="(breadcrumb, index) in breadcrumbs" 
                    :key="`breadcrumb${index}`" class="breadcrumb-item"
                >
                    <nuxt-link v-if="index !== breadcrumbs.length - 1" :to="$prismic.linkResolver(breadcrumb.id)">
                        {{ breadcrumb.title }}
                    </nuxt-link>
                    <span v-else>
                        {{ breadcrumb.title }}
                    </span>
                </li>
            </ol>
        </nav>
    </div>
</template>
<script>
export default {
    computed: {
        breadcrumbs() {
            
            const documents = require('@/documents.json');
            let path = this.$route.path;
            path += path.endsWith('/') ? '' : '/';
            
            const documentHelper = Object.values(documents)
                .find(d => d.slug === path && d.prismicLocale === process.env.PRISMIC_LOCALE);
                
            return documentHelper.breadcrumbs;
        }
    }
};
</script>
