<template>
    <div class="slices">
        <template v-for="(slice, index) in slices">
            <component
                :is="toPascalCase(slice.slice_type)"
                :key="index"
                :slice="slice"
                :slices="sendSlices(slice)"
                :document="sendDocument(slice)"
                :index="index"
            />
        </template>
    </div>
</template>

<script>
import { camelCase, startCase } from 'lodash';
import Vue from 'vue';

const ComponentContext = require.context('@/components/slices', true, /\.vue$/i, 'eager');
ComponentContext.keys().forEach((componentFilePath) => {
    const componentName = componentFilePath.split('/').pop().split('.')[0];
    Vue.component(componentName, () => ComponentContext(componentFilePath));
});

export default {
    props: {
        slices: {
            type: Array,
            required: true,
        },
        document: {
            type: Object,
            required: false,
            default: null
        }
    },
    methods: {
        toPascalCase(string){
            return startCase(camelCase(string)).replace(/ /g, '');
        },
        sendSlices(slice) {
            if (
                slice.slice_type === 'table_of_contents' ||
                slice.slice_type === 'banner_simple' ||
                slice.slice_type === 'banner_featured'
            ) {
                return this.slices;
            }
        },
        sendDocument(slice) {
            if (slice.slice_type === 'banner_image' || slice.slice_type === 'show_guides' || slice.slice_type === 'link_section') {
                return this.document;
            }
        }
    },
};
</script>
