<template>
    <div>
        <div 
            v-if="lenderIds && lenderIds.length" 
            class="table-responsive shadow rounded rounded-top-0 border border-1" 
            :class="{'mobile-scroll-shadow': lenderIds.length > 2}"
        >
            <table class="table table-striped mb-0 borders-seperate">
                <thead>
                    <tr>
                        <th class="position-sticky start-0 d-none d-sm-table-cell bg-white border-end border-bottom" />
                        <td 
                            v-for="(lender, index) in lenders" 
                            :key="lender.id" 
                            class="min-wpx-170 border-bottom" 
                            :class="{'border-end': index !== lenders.length -1}"
                        >
                            <nuxt-link :to="$prismic.linkResolver(lender)">
                                <prismic-image
                                    :img="lender.data.logo"
                                    w="100"
                                />
                            </nuxt-link>
                            <div class="d-flex align-items-center fw-bold mt-2">
                                <span class="lh-sm">{{ lender.data.title }}</span>
                                <i 
                                    class="bi bi-x fs-3 lh-0 text-danger-hover cursor-pointer" 
                                    role="button" 
                                    @click="$emit('removeLender', lender.id)" 
                                />
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(dataRow, key) in tableData" :key="key">
                        <th class="text-dark text-nowrap wpx-10 position-sticky start-0 d-none d-sm-table-cell bg-white border-end">
                            {{ dataRow.title }}
                        </th>
                        <td 
                            v-for="(rowData, rowDataIndex) in dataRow.data" 
                            :key="lenderIds[rowDataIndex]"
                            class="text-dark"
                            :class="{'border-end': rowDataIndex !== lenders.length -1}"
                        >
                            <div class="d-sm-none fs-7 fw-bold">{{ dataRow.title }}</div>
                            <span class="d-inline-block lh-md" :class="{'text-success fw-bold': rowData.highlighted}">
                                {{ rowData.value }}
                            </span>
                            <i 
                                v-if="rowData.tooltipEnabled" 
                                v-tooltip="rowData.tooltip" 
                                class="bi bi-info-circle-fill ms-1 text-info" 
                            />
                        </td>
                    </tr>
                    <tr>
                        <th class="position-sticky start-0 d-none d-sm-table-cell bg-white text-dark border-end">
                            {{ $translate('compare_lenders_table_header_apply', 'Ansök') }}
                        </th>
                        <td v-for="(lender, index) in lenders" :key="lender.id" :class="{'border-end': index !== lenders.length -1}">
                            <out-link :document="lender" class="btn btn-primary fw-bold" />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else class="mt-4">
            <div class="h3 mb-2">
                {{ $translate('compare_lenders_no_lenders_header', 'Inga lån valda') }}
            </div>
            <span>
                {{ 
                    $translate('compare_lenders_no_lenders_description', 'Klicka på "{buttonCta}" för att börja jämföra!') 
                        .replace('{buttonCta}', $translate('compare_lenders_add_lenders_button_cta', 'Lägg till lån'))
                }}
            </span>
        </div>
    </div>
</template>

<script>
import { pick } from 'lodash';
export default {
    props: {
        lenderIds: {
            type: Array,
            required: true
        }
    },
    computed: {
        lenders() {
            return this.lenderIds.map(id => {
                const lender = Object.assign({}, this.$store.state.lenders[id]);
                lender.model = new this.$models.Loan(lender, this.$store);
                return lender;
            });
        },
        bestInterest() {
            return Math.min(...this.lenders.map(lender => lender.model.minInterestPossible));
        },
        bestEffectiveInterest() {
            return Math.min(...this.lenders.map(lender => lender.model.minEffectiveInterestPossible));
        },
        bestMinAge() {
            return Math.min(...this.lenders.map(lender => lender.model.minAge));
        },
        bestSetupFee() {
            return Math.min(...this.lenders.map(lender => lender.model.setupFee));
        },
        bestAmountOfBanks() {
            return Math.max(...this.lenders.map(lender => lender.model.connectedBanks)) || false;
        },
        tableData() {
            let tableDataKeys = [];
            if (this.$isSweden()) {
                tableDataKeys = ['amount', 'interest', 'effectiveInterest', 'duration', 'minAge', 'setupFee', 'withoutUc', 'amountOfBanks', 'directPayments', 'paymentRemarks'];
            }
            else if (this.$isNorway()) {
                tableDataKeys = ['amount', 'interest', 'effectiveInterest', 'duration', 'minAge', 'setupFee', 'amountOfBanks', 'paymentRemarks'];
            }
            else if (this.$isDenmark()) {
                tableDataKeys = ['amount', 'interest', 'effectiveInterest', 'duration', 'minAge', 'setupFee', 'amountOfBanks', 'directPayments'];
            }
            else if (this.$isSpain()) {
                tableDataKeys = ['amount', 'spainEffectiveInterest', 'duration', 'minAge', 'withAsnef'];
            }
            return pick(this.allTableData, tableDataKeys);
        },
        allTableData() {
            return {
                amount: {
                    title: this.$translate('loan_amount', 'Belopp'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.amountString,
                            tooltipEnabled: lender.model.hasExtraLoanAmountToExistingCustomers,
                            tooltip: lender.model.getMaxAmountToExistingCustomersString(
                                this.$translate('max_amount_to_existing_customers', 'Upp till {amount} för existerande kunder')
                            )
                        };
                    })
                },
                interest: {
                    title: this.$translate('interest', 'Ränta'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.interestString,
                            highlighted: lender.model.minInterestPossible === this.bestInterest,
                            tooltipEnabled: lender.model.hasFirstTimeOffer,
                            tooltip: lender.model.getFirstTimeOfferString(
                                this.$translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                                this.$translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                            )
                        };
                    })
                },
                effectiveInterest: {
                    title: this.$translate('preamble_effective_interest', 'Effektiv ränta'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.effectiveInterestString,
                            highlighted: lender.model.minEffectiveInterestPossible === this.bestEffectiveInterest
                        };
                    })
                },
                spainEffectiveInterest: {
                    title: this.$translate('preamble_effective_interest', 'Effektiv ränta'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.effectiveInterestString,
                            highlighted: lender.model.minEffectiveInterestPossible === this.bestEffectiveInterest,
                            tooltipEnabled: lender.model.isBroker || lender.model.hasFirstTimeOffer,
                            tooltip: lender.model.isBroker 
                                ? this.$translate('spain_interest_tooltip_on_broker') 
                                : lender.model.getFirstTimeOfferString(
                                    this.$translate('first_time_offer_up_to_amount', 'Räntefritt upp till {amount} i {days} dagar för nya kunder'),
                                    this.$translate('new_customers_offer_from_amount', 'Räntefritt från {amount} i {days} dagar för nya kunder')
                                )
                        };
                    })
                },
                duration: {
                    title: this.$translate('loan_duration', 'Löptid'),
                    data: this.lenders.map(lender => {
                        return {
                            value: !this.$isSpain() 
                                ? lender.model.getDurationString(this.$translate('month_short', 'mån'), this.$translate('year_short', 'år'))
                                : this.$getSpanishLoanDuration(lender.model, this.$translate('month_short', 'mån'), this.$translate('year_short', 'år'))
                        };
                    })
                },
                minAge: {
                    title: this.$translate('min_age', 'Åldersgräns'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.minAge + ' ' + this.$translate('years', 'år'),
                            highlighted: lender.model.minAge === this.bestMinAge
                        };
                    })
                },
                setupFee: {
                    title: this.$translate('arrangement_fee', 'Uppläggningsavgift'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.setupFeeString,
                            highlighted: lender.model.setupFee === this.bestSetupFee
                        };
                    })
                },
                withoutUc: {
                    title: this.$translate('without_uc', 'Utan UC'),
                    data: this.lenders.map(lender => {
                        return {
                            value: this.$formatBoolean(! lender.model.takesUC),
                            tooltipEnabled: !lender.model.takesUC,
                            tooltip: this.$translate('uses', 'Använder') + ' ' + this.$getCreditCheckString(lender.model.creditCheck)
                        };
                    }),
                },
                amountOfBanks: {
                    title: this.$translate('connected_banks', 'Anslutna banker'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.isBroker 
                                ? lender.model.connectedBanks
                                : this.$translate('is_not_broker', 'Är inte låneförmedlare'),
                            highlighted: lender.model.connectedBanks === this.bestAmountOfBanks
                        };
                    })
                },
                directPayments: {
                    title: this.$translate('direct_pay_out', 'Direktutbetalning'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.hasDirectPayments24Hours
                                ? this.$translate('around_the_clock', 'Dygnet runt')
                                : lender.model.hasDirectPayments
                                    ? this.$translate('direct', 'Direkt')
                                    : this.$translate('no', 'Nej'),
                            tooltipEnabled: this.$getDirectPaymentToBanksString(lender.model),
                            tooltip: this.$translate('direct_pay_out_to_banks', 'Direktutbetalning till {banks}')
                                .replace('{banks}', this.$getDirectPaymentToBanksString(lender.model))
                        };
                    })
                },
                paymentRemarks: {
                    title: this.$translate('payment_remarks', 'Betalningsanmärkningar'),
                    data: this.lenders.map(lender => {
                        return {
                            value: lender.model.acceptsPaymentRemarks 
                                ? this.$translate('accepted', 'Accepteras') 
                                : this.$translate('not_accepted', 'Accepteras inte'),
                            highlighted: lender.model.acceptsPaymentRemarks,
                            tooltipEnabled: lender.model.acceptsPaymentRemarks && lender.model.paymentRemarksMaxCount,
                            tooltip: `Max ${lender.model.paymentRemarksMaxCount} st`

                        };
                    })
                },
                withAsnef: {
                    title: this.$translate('with_asnef', 'With ASNEF'),
                    data: this.lenders.map(lender => {
                        return {
                            value: this.$formatBoolean(lender.model.acceptsAsnef),
                        };
                    }),
                }
            };
        },
    }
};
</script>
