<template>
    <StepContainer :title="$translate('loan_wizard_economy_question', 'Hur ser din ekonomi ut?')">
        <div class="mb-5">
            <label for="monthlyIncome" class="form-label">
                {{ $translate('loan_wizard_monthly_income', 'Din månadsinkomst') }}
            </label>
            <input 
                id="monthlyIncome" 
                v-model="monthlyIncome" 
                type="number" 
                class="form-control"
            >
            <Error v-if="errors.monthlyIncome" :error-message="$translate('loan_wizard_enter_monthly_income', 'Ange din månadsinkomst.')" />
            <span class="small">{{ $translate('loan_wizard_enter_monthly_gross_income', 'Ange din månadsinkomst innan skatt') }}</span>
        </div>

        
        <div class="mb-5">
            <label for="hasPaymentRemarks" class="form-label">
                {{ $translate('loan_wizard_payment_remarks_question', 'Har du några betalningsanmärkningar?') }}
            </label>
            <select id="hasPaymentRemarks" v-model="hasPaymentRemarks" class="form-select">
                <option value="false">{{ $translate('no', 'Nej') }}</option>
                <option value="true">{{ $translate('yes', 'Ja') }}</option>
            </select>
            <Error v-if="errors.hasPaymentRemarks" :error-message="$translate('loan_wizard_choose_an_alternative', 'Välj ett alternativ')" />
        </div>

        <a 
            class="btn btn-sm btn-primary fw-bold d-block" 
            @click="submitValues()"
        >
            {{ $translate('loan_wizard_next_step', 'Nästa steg') }}
        </a>
    </StepContainer>
</template>
<script>
import StepContainer from '@/components/LoanWizard/StepContainer.vue';
import Error from '@/components/LoanWizard/Error.vue';
export default {
    components: {
        StepContainer,
        Error
    },
    props: {
        updatedMonthlyIncome: {
            type: Number,
            required: false,
            default: null
        },
        updatedHasPaymentRemarks: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            monthlyIncome: this.updatedMonthlyIncome,
            hasPaymentRemarks: this.updatedHasPaymentRemarks,
            errors: {
                monthlyIncome: false,
                hasPaymentRemarks: false
            }
        };

    },
    methods: {
        submitValues() {
            this.errors.monthlyIncome = this.monthlyIncome === null;
            this.errors.hasPaymentRemarks = this.hasPaymentRemarks === null;

            if (!this.errors.monthlyIncome && !this.errors.hasPaymentRemarks) {
                this.$emit('submitValues', {
                    monthlyIncome: Number(this.monthlyIncome),
                    hasPaymentRemarks: Boolean(this.hasPaymentRemarks),
                });
            }
        },
    }
};
</script>