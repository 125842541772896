import { pick } from 'lodash';

export default {
    computed: {
        lenderStats() {
            if (this.$isSweden()) {
                return this.swedenLendersStats;
            }
            if (this.$isNorway()) {
                return this.norwayLendersStats;
            }
            if (this.$isSpain()) {
                return this.spainLendersStats;
            }
            if (this.$isDenmark()) {
                return this.denmarkLendersStats;
            }
            return {};
        },
        calculationsData() {
            const data = {
                count: 0,
                // Averages
                sumMinInterestPossible: 0,
                sumMaxInterestPossible: 0,
                sumMinEffectiveInterestPossible: 0,
                sumMaxEffectiveInterestPossible: 0,
                sumSetupFee: 0,
                sumAdminFee: 0,
                // Counts
                countUC: 0,
                countAcceptsPaymentRemarks: 0,
                countUnder20: 0,
                countUnder21: 0,
                countDirectPayments: 0,
                countDirect24HoursPayments: 0,
                countAsnef: 0,
                // other
                minAmount: 500000,
                maxAmount: 0,
            };

            Object.values(this.list).forEach(lender => {

                const model = lender.model;

                data.count++;
                data.sumMinInterestPossible += model.minInterestPossible;
                data.sumMaxInterestPossible += model.maxInterestPossible;
                data.sumMinEffectiveInterestPossible += model.minEffectiveInterestPossible;
                data.sumMaxEffectiveInterestPossible += model.maxEffectiveInterestPossible;
                data.sumSetupFee += model.setupFee;
                data.sumAdminFee += model.administrationFee;

                if (model.takesUC) {
                    data.countUC++;
                }

                if (model.acceptsPaymentRemarks) {
                    data.countAcceptsPaymentRemarks++;
                }

                if (model.minAge < 20) {
                    data.countUnder20++;
                }

                if (model.minAge < 21) {
                    data.countUnder21++;
                }

                if (model.acceptsASNEF) {
                    data.countAsnef++;
                }

                if (model.hasDirectPayments) {
                    data.countDirectPayments++;
                }

                if (model.hasDirectPayments24Hours) {
                    data.countDirect24HoursPayments++;
                }

                if (model.maxAmount > data.maxAmount) {
                    data.maxAmount = model.maxAmount;
                }

                if (model.minAmount < data.minAmount) {
                    data.minAmount = model.minAmount;
                }
            });
            return data;
        },
        dataCategories() {
            const data = this.calculationsData;
            return {
                minAmount: {
                    title: this.$translate('structured_data_lowest_loan_amount', 'Lägsta lånebelopp'),
                    data: this.$format.currency(data.minAmount, 2)
                },
                maxAmount: {
                    title: this.$translate('structured_data_highest_loan_amount', 'Högsta lånebelopp'),
                    data: this.$format.currency(data.maxAmount, 2)
                },
                averageFromInterest: {
                    title: this.$translate('structured_data_average_min_interest', 'Genomsnittlig frånränta'),
                    data: this.$format.percentage(data.sumMinInterestPossible / data.count, 2)
                },
                averageToInterest: {
                    title: this.$translate('structured_data_average_max_interest', 'Genomsnittlig tillränta'),
                    data: this.$format.percentage(data.sumMaxInterestPossible / data.count, 2)
                },
                averageFromEffectiveInterest: {
                    title: this.$translate('structured_data_average_min_effective_interest', 'Genomsnittlig effektiv frånränta'),
                    data: this.$format.percentage(data.sumMinEffectiveInterestPossible / data.count, 2)
                },
                averageToEffectiveInterest: {
                    title: this.$translate('structured_data_average_max_effective_interest', 'Genomsnittlig effektiv tillränta'),
                    data: this.$format.percentage(data.sumMaxEffectiveInterestPossible / data.count, 2)
                },
                averageSetupFee: {
                    title: this.$translate('structured_data_average_setup_fee', 'Genomsnittlig uppläggningsavgift'),
                    data: this.$format.currency(data.sumSetupFee / data.count, 2)
                },
                averageAdminFee: {
                    title: this.$translate('structured_data_average_admin_fee', 'Genomsnittlig administrationsavgift'),
                    data: this.$format.currency(data.sumAdminFee / data.count, 2)
                },
                directPayments: {
                    title: this.$translate('direct_pay_out', 'Direktutbetalning'),
                    data: this.$translate('structured_data_direct_payout_data', '{amountOfLenders} långivare ({percent}) erbjuder direktutbetalning')
                        .replace('{amountOfLenders}', data.countDirectPayments)
                        .replace('{percent}', this.$format.percentage(100 * (data.countDirectPayments / data.count), 2))
                },
                directPayments24Hours: {
                    title: this.$translate('structured_data_direct_payout_24_7', 'Direktutbetalning dygnet runt'),
                    data: this.$translate('structured_data_direct_payout_24_7_data', '{amountOfLenders} långivare ({percent}) erbjuder direktutbetalning dygnet runt')
                        .replace('{amountOfLenders}', data.countDirect24HoursPayments)
                        .replace('{percent}', this.$format.percentage(100 * (data.countDirect24HoursPayments / data.count), 2))
                },
                withoutUC: {
                    title: this.$translate('without_uc', 'Utan UC'),
                    data: this.$translate('structured_data_without_uc_data', '{amountOfLenders} långivare ({percent}) använder inte kreditupplysningar från UC') 
                        .replace('{amountOfLenders}', data.count - data.countUC)
                        .replace('{percent}', this.$format.percentage(100 * (1 - data.countUC / data.count), 2))
                },
                paymentRemarks: {
                    title: this.$translate('payment_remarks', 'Betalningsanmärkningar'),
                    data: this.$translate('structured_data_payment_remarks_data', '{amountOfLenders} långivare ({percent}) accepterar betalningsanmärkningar')
                        .replace('{amountOfLenders}', data.countAcceptsPaymentRemarks)
                        .replace('{percent}', this.$format.percentage(100 * data.countAcceptsPaymentRemarks / data.count, 2))
                },
                youngBorrowers20: {
                    title: this.$translate('structured_data_young_borrowers', 'Unga lånetagare'),
                    data: this.$translate('structured_data_accepts_under_20_data', '{amountOfLenders} långivare ({percent}) accepterar låntagare under 20 år')
                        .replace('{amountOfLenders}', data.countUnder20)
                        .replace('{percent}', this.$format.percentage(100 * (data.countUnder20 / data.count), 2))
                },
                youngBorrowers21: {
                    title: this.$translate('structured_data_young_borrowers', 'Unga lånetagare'),
                    data: this.$translate('structured_data_accepts_under_21_data', '{amountOfLenders} långivare ({percent}) accepterar låntagare under 21 år')
                        .replace('{amountOfLenders}', data.countUnder21)
                        .replace('{percent}', this.$format.percentage(100 * (data.countUnder21 / data.count), 2))
                },
                asnef: {
                    title: this.$translate('with_asnef', 'With ASNEF'),
                    data: this.$translate('structured_data_with_asnef_data', '{amountOfLenders} lenders ({percent}) accepts ASNEF')
                        .replace('{amountOfLenders}', data.countAsnef)
                        .replace('{percent}', this.$format.percentage(100 * (data.countAsnef / data.count), 2))
                },
            };
        },
        swedenLendersStats() {
            return pick(this.dataCategories, [
                'minAmount',
                'maxAmount',
                'averageFromInterest',
                'averageToInterest',
                'averageSetupFee',
                'averageAdminFee',
                'directPayments',
                'directPayments24Hours',
                'withoutUC',
                'paymentRemarks',
                'youngBorrowers20'
            ]);
        },
        norwayLendersStats() {
            return pick(this.dataCategories, [
                'minAmount',
                'maxAmount',
                'averageFromInterest',
                'averageToInterest',
                'paymentRemarks',
                'youngBorrowers20'
            ]);
        },
        spainLendersStats() {
            return pick(this.dataCategories, [
                'minAmount',
                'maxAmount',
                'averageFromEffectiveInterest',
                'averageToEffectiveInterest',
                'asnef',
                'youngBorrowers21',
            ]);
        },
        denmarkLendersStats() {
            return pick(this.dataCategories, [
                'minAmount',
                'maxAmount',
                'averageFromInterest',
                'averageToInterest',
                'youngBorrowers20'
            ]);
        },
    },
};