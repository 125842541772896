<template>
    <div class="border-bottom border-top pt-7 mb-5">
        <div class="container">
            <centered-section-header 
                :h2="$prismic.asText(slice.primary.h2)" 
                :intro-header="slice.primary.intro_header" 
            />
            <div class="row justify-content-center text-center">
                <div 
                    v-for="(item, index) in slice.items" 
                    :key="index" 
                    class="col-6 col-sm-5 col-md-4 py-md-3 p-0 mb-5 mb-md-7"
                >
                    <a v-if="item.link.url" target="_blank" :href="item.link.url">
                        <prismic-image 
                            class="w-75 w-md-100" 
                            w="200" 
                            h="55" 
                            style="object-fit: contain;" 
                            :img="item.logo" 
                        />
                    </a>
                    <prismic-image 
                        v-else 
                        class="w-75 w-md-100" 
                        w="200"
                        h="55" 
                        style="object-fit: contain;" 
                        :img="item.logo" 
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CenteredSectionHeader from '@/components/CenteredSectionHeader.vue';
export default {
    components: {
        CenteredSectionHeader
    },
    props: {
        slice: {
            type: Object,
            required: true
        }        
    },
};
</script>