<template>
    <div class="container content-space-1 content-space-md-2 rounded" :class="backgroundColor">
        <div class="row justify-content-center">
            <div v-if="success" ref="formSent" class="border-5 p-4 mt-8 rounded border-start border-success bg-dark text-center">
                <i class="text-success bi bi-check-lg me-2 fs-3" />
                <span class="fw-bold text-white h4">
                    {{ successMessage }} 
                </span>     
            </div>
            <div v-else class="mx-auto" style="max-width: 40rem;">
                <div class="card card-body px-3 px-md-5 py-5">
                    <div class="text-center mb-3 mb-md-5">
                        <h2 class="my-3"> {{ title || $translate('contact_form_h2', 'Kontakta oss') }} </h2>
                        <p>{{ description || $translate('contact_form_p', 'Fyll i fälten nedan för att komma i kontakt med oss på Kreditium.') }}</p>
                    </div>
                    <div class="row gx-3">
                        <div class="col-sm-6">
                            <div class="mb-3">
                                <label class="form-label" for="name">{{ $translate('contact_form_label_name', 'Namn') }}</label>
                                <input 
                                    id="name" 
                                    v-model="name"
                                    type="text" 
                                    class="form-control" 
                                    :placeholder="$translate('contact_form_placeholder_name', 'John Doe')"
                                    required
                                >
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="mb-3">
                                <label class="form-label" for="email">{{ $translate('contact_form_label_email', 'Email') }}</label>
                                <input 
                                    id="email" 
                                    v-model="email"
                                    type="email" 
                                    class="form-control" 
                                    :placeholder="$translate('contact_form_placeholder_email', 'john.doe@gmail.com')"
                                    required
                                >
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label class="form-label" for="message">{{ $translate('contact_form_label_message', 'Meddelande') }}</label>
                        <textarea 
                            id="message" 
                            v-model="message"
                            class="form-control" 
                            :placeholder="$translate('contact_form_placeholder_message', 'Ditt meddelande ...')" 
                            rows="4" 
                            required
                        />
                    </div>
                    <div v-if="error" class="text-center mb-3 text-danger fw-bold">
                        <span class="bi bi-exclamation-triangle-fill me-2" />
                        {{ errorMessage }}
                    </div>
                    <button 
                        class="w-100 btn btn-primary btn-lg mt-3" 
                        @click.prevent="submit"
                    >
                        {{ $translate('contact_form_button_text', 'Skicka in') }} <i class="bi bi-send ms-2" />
                    </button>
                    <div class="text-center">
                        <p class="form-text">{{ $translate('contact_form_footer_text', 'Vi återkommer till dig så snart vi kan.') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: '',
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        backgroundColor: {
            type: String,
            required: false,
            default: 'bg-soft-primary'
        }
    },
    data() {
        return {
            enable: true,
            error: false,
            success: false,
            errorMessage: this.$translate('contact_form_error_message', 'Kolla så att alla fält är korrekt i fyllda!'),
            successMessage: this.$translate('contact_form_success_message', 'Tack för ditt meddelande!'),

            email: '',
            name: null,
            message: null
        };
    },
    methods: {
        submit() {
            const validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            if (! this.enable) {
                return;
            }

            if (
                ! this.email.match(validEmailRegex) ||
                this.name === null ||
                this.message === null
            ) {
                this.error = true;
                return;
            }
            
            this.enable = false;
            this.$axios
                .post('https://formspree.io/f/xgeqepbr', {
                    name: this.name,
                    email: this.email,
                    message: this.message
                })
                .catch((e) => {
                    this.error = true;
                    this.errorMessage = e.errorMessage;
                })
                .then(() => {
                    this.error = false,
                    this.enable = true;
                    this.success = true;

                    const el = this.$refs.formSent;
                    return el.scrollIntoView({block: 'center', behavior: 'smooth' });
                });
        }
    }
};
</script>