<template>
    <td class="text-dark px-10 align-middle">
        <div class="min-wpx-200 mx-3">
            <div class="position-relative bg-primary hpx-20 rounded d-flex lh-md" :style="barStyling(model)">
                <span class="position-absolute me-2 d-flex small justify-content-end text-nowrap end-100 top-0">
                    {{ categoryData.minStringFunction(model) }}
                </span>
                <span v-if="isInterval" class="position-absolute ms-2 d-flex small justify-content-start text-nowrap start-100 top-0">
                    {{ categoryData.maxStringFunction(model) }}
                </span>
            </div>
        </div>
    </td>
</template>

<script>
export default {
    props: {
        model: {
            type: Object,
            required: true
        },
        categoryData: {
            type: Object,
            required: true
        },
        maximumDataValue: {
            type: Number,
            required: true
        }
    },
    computed: {
        isInterval() {
            return this.model[this.categoryData.minDataKey] !== this.model[this.categoryData.maxDataKey];
        }
    },
    methods: {
        barStyling(model) {
            const categoryMinValue = model[this.categoryData.minDataKey] || 0;
            const categoryMaxValue = model[this.categoryData.maxDataKey];

            const width = (categoryMaxValue - categoryMinValue) / this.maximumDataValue * 100 + 1;
            const margin = (categoryMinValue - 0) / this.maximumDataValue * 100;

            return `width: ${width}%; margin-left: ${margin}%;`;
        },
    }
};
</script>